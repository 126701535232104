<template>
  <div class="d-flex position-relative">
    <v-card
      :class="'w-50 ' + (isSelectedTab(0) ? 'rating-tab-selected' : 'rating-tab')"
      :elevation="0"
      :link="!isSelectedTab(0)"
      @click="$emit('update:isWholePeriod', true)"
    >
      <ReviewStarRating title="全期間の評価" :stars="starsAll" :loading="loading" />
    </v-card>
    <v-card
      :class="'w-50 ml-1 ' + (isSelectedTab(1) ? 'rating-tab-selected' : 'rating-tab')"
      :elevation="0"
      :link="!isSelectedTab(1)"
      @click="$emit('update:isWholePeriod', false)"
    >
      <ReviewStarRating title="表示期間の評価" :stars="starsPeriod" :loading="loading" />
    </v-card>
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
  <div class="rating-body">
    <h3 class="text-h5 font-weight-bold ml-2 my-3">直近12ヶ月のクチコミ件数／割合推移</h3>
    <ReviewTrend :loading="loading" :data="ratingSummaryData" />
    <ReviewRankings
      ref="reviewRankings"
      :poi-group-id="poiGroupId"
      :selected-target="selectedTarget"
      :is-whole-period="isWholePeriod"
      :from="from"
      :to="to"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import dayjs from "dayjs";
import ReviewStarRating from "./review-star-rating.vue";
import ReviewTrend from "./review-trend.vue";
import ReviewRankings from "./review-rankings.vue";
import type { RatingSummaryDatum } from "@/components/shared/review-shared";
import { type SelectedTarget } from "@/components/shared/store-selector.vue";
import { api } from "@/helpers/api/review";

export default defineComponent({
  components: { ReviewStarRating, ReviewTrend, ReviewRankings },
  props: {
    isWholePeriod: { type: Boolean, default: true },
    poiGroupId: { type: Number, required: true },
    selectedTarget: { type: Object as PropType<SelectedTarget>, required: true },
    from: { type: String, required: true },
    to: { type: String, required: true },
  },

  emits: ["update:isWholePeriod"],
  data() {
    return {
      isUnmounted: false,
      loading: false,
      ratingSummaryData: [] as RatingSummaryDatum[],
      condition: "",
      starsAll: [0, 0, 0, 0, 0],
      starsPeriod: [0, 0, 0, 0, 0],
    };
  },
  watch: {
    isWholePeriod: {
      async handler() {
        // isWholePeriod が変更されたら評価ランキングを更新
        await this.updateReviewRankings();
      },
      flush: "post",
    },
  },
  unmounted() {
    this.isUnmounted = true;
  },
  methods: {
    async pageLoad() {
      // 店舗選択がない場合は何もしない
      if (this.selectedTarget.isNone) return;
      // 評価サマリーと評価ランキングを並列で更新
      await Promise.all([this.updateRatingSummary(), this.updateReviewRankings()]);
    },
    async updateRatingSummary(): Promise<void> {
      // 前回の取得条件と同じならAPIを実行しない
      const condition = this.selectedTarget.toString();
      if (condition === this.condition) {
        this.updateStars(); // 表示期間が変わっている可能性があるので星を更新
        return;
      }
      // APIを実行
      this.loading = true;
      try {
        const res = await api.getRatingSummary(this.poiGroupId, this.selectedTarget);
        if (this.isUnmounted) return; // 読み込み完了前にコンポーネントがアンマウントされた場合は以降の処理を行わない

        this.ratingSummaryData = res.ratingItems;
        this.condition = condition; // 取得条件を保存
        this.updateStars();
      } finally {
        this.loading = false;
      }
    },
    async updateReviewRankings() {
      await (this.$refs.reviewRankings as InstanceType<typeof ReviewRankings>)?.pageLoad();
    },

    /** 全期間と表示期間の星を更新する */
    updateStars() {
      // 全期間の評価を計算
      const stars = [0, 0, 0, 0, 0];
      for (const item of this.ratingSummaryData) {
        if (!Number.isInteger(item.year) || !Number.isInteger(item.month)) {
          continue;
        }
        stars[0] += item.star1;
        stars[1] += item.star2;
        stars[2] += item.star3;
        stars[3] += item.star4;
        stars[4] += item.star5;
      }
      this.starsAll = stars;
      // 表示期間の評価を計算
      const starsPeriod = [0, 0, 0, 0, 0];
      for (const item of this.ratingSummaryData) {
        const yyyymm = dayjs(item.year + "-" + item.month, "YYYY-M").format("YYYY-MM");
        if (this.from <= yyyymm && yyyymm <= this.to) {
          starsPeriod[0] += item.star1;
          starsPeriod[1] += item.star2;
          starsPeriod[2] += item.star3;
          starsPeriod[3] += item.star4;
          starsPeriod[4] += item.star5;
        }
      }
      this.starsPeriod = starsPeriod;
    },
    isSelectedTab(tab: number): boolean {
      return (tab === 0 && this.isWholePeriod) || (tab === 1 && !this.isWholePeriod);
    },
  },
});
</script>

<style lang="scss" scoped>
.rating-tab {
  border: solid rgba(0, 0, 0, 0.25);
  border-width: 1px;
  border-radius: 10px 10px 0 0;
  background-color: rgb(225, 225, 225);
}
.rating-tab-selected {
  border: solid rgba(0, 0, 0, 0.25);
  border-width: 1px 1px 0 1px;
  border-radius: 10px 10px 0 0;
  background-color: white;
}
.rating-body {
  border: solid rgba(0, 0, 0, 0.25);
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 10px 10px;
  background-color: white;
}
</style>
