<template>
  <v-container class="post-preview">
    <v-card :disabled="!step.enabled" color="transparent" elevation="0">
      <!-- 投稿種別選択 -->
      <v-row align="center" class="mx-1">
        <v-col cols="auto" style="padding-right: 0">
          <span>投稿種別</span>
        </v-col>
        <v-col class="post-type-col">
          <v-select
            v-model="post.postType"
            :items="currentPostTypes"
            item-title="name"
            item-value="id"
            hide-details
            density="compact"
            bg-color="white"
            color="primary"
            :readonly="readonly"
          />
        </v-col>
      </v-row>
      <!-- 概要タブ掲載期間 -->
      <div class="pinned-period">
        <v-row class="pinned-period-title">
          <v-col>
            Yahoo!プレイス 概要タブ掲載期間
            <ToolTipIcon :label="pinnedDescription" position="left" class="pinned-tooltip" />
          </v-col>
        </v-row>
        <v-row class="pinned-datetime">
          <v-col class="pinned-label">開始日時</v-col>
          <v-col>
            <date-picker
              v-model="post.pinnedStartDate"
              :min="threeMonthsAgo"
              :max="post.pinnedEndDate"
              :readonly="readonly"
            />
          </v-col>
          <v-col>
            <input
              v-model="post.pinnedStartTime"
              type="time"
              :disabled="readonly"
              class="period-time"
            />
          </v-col>
        </v-row>
        <v-row class="pinned-datetime">
          <v-col class="pinned-label">終了日時</v-col>
          <v-col>
            <date-picker
              v-model="post.pinnedEndDate"
              :min="post.pinnedStartDate"
              :max="threeMonthsLater"
              :readonly="readonly"
            />
          </v-col>
          <v-col>
            <input
              v-model="post.pinnedEndTime"
              type="time"
              :disabled="readonly"
              class="period-time"
            />
          </v-col>
        </v-row>
        <v-row v-if="dateTimeErrorMessage != ''" dense>
          <v-col>
            <p class="error-message">
              {{ dateTimeErrorMessage }}
            </p>
          </v-col>
        </v-row>
      </div>
      <!-- 投稿プレビュー(全投稿種別で項目は共通) -->
      <v-card class="preview-card py-4">
        <v-form ref="yahooForm" v-model="isValidateForm">
          <!-- 投稿種別表示(選択は上部) -->
          <v-row dense>
            <v-col>
              <v-chip label variant="outlined" size="x-small" color="#00AB3F">
                {{ getPostTypeDisplayName(post.postType) }}
              </v-chip>
            </v-col>
          </v-row>
          <!-- タイトル -->
          <v-row dense>
            <v-col>
              <v-textarea
                v-model="post.title"
                :disabled="!step.enabled"
                class="mt-n5"
                rows="1"
                auto-grow
                placeholder="タイトル"
                counter="50"
                :rules="[
                  rules.required,
                  rules.minLengthWithoutCounter(5),
                  rules.maxLengthWithoutCounter(50),
                  rules.prohibitedYahooChars,
                ]"
                variant="underlined"
                hide-details="auto"
                persistent-counter
                color="primary"
                :readonly="readonly"
              />
            </v-col>
          </v-row>
          <!-- プレビュー用に今日の日付を固定表示する -->
          <v-row class="registration-date" dense>
            <v-col>登録日: {{ registrationDate }}</v-col>
          </v-row>
          <!-- 画像 -->
          <v-row v-if="files.length > 0" class="image-row">
            <v-hover v-slot="{ isHovering, props }">
              <v-col v-bind="props" class="image-box">
                <span v-show="isHovering && !isMobile && !readonly" class="edit-span">
                  <v-btn class="edit-button" flat rounded @click="editImage(files[0].state)">
                    <v-icon color="white" left>fas fa-cog</v-icon>
                    <span class="ml-2">編集</span>
                  </v-btn>
                </span>
                <v-img :src="files[0].imageUrl" class="image" />
              </v-col>
            </v-hover>
          </v-row>
          <!-- 本文 -->
          <v-row>
            <v-col>
              <v-textarea
                v-model="post.description"
                :disabled="!step.enabled"
                :placeholder="getDescriptionPlaceholder(post.postType)"
                :rules="[
                  rules.required,
                  rules.minLengthWithoutCounter(10),
                  rules.maxLengthWithoutCounter(330),
                  rules.prohibitedYahooChars,
                ]"
                counter="330"
                rows="1"
                auto-grow
                variant="underlined"
                hide-details="auto"
                persistent-counter
                class="detail-text"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import DatePicker from "@/components/shared/date-picker/DatePicker.vue";
import { platformOfficialNames, sortFileSelectionItemList } from "@/models/v2-file-selection";
import { VuetifyValidator } from "@/helpers";
import { validateFile } from "../validator";
import { YahooPostTypes, YahooPostForm } from "./yahoo-request";
import type { YahooPostTypeDict } from "./yahoo-request";
import type { StepStatus } from "../post-stepper.vue";
import type { FileSelectionItem, FileSelectionState } from "@/models/v2-file-selection";
import type { EntitiesYahooFeedType } from "@/types/ls-api";
import type { VForm } from "vuetify/lib/components/index.mjs";

export default defineComponent({
  components: { DatePicker },
  props: {
    modelValue: { type: Object as () => YahooPostForm, required: true }, // v-model
    baseText: { type: String, required: true },
    targetName: { type: String, required: true },
    fileSelections: { type: Array as () => FileSelectionItem[], required: true },
    step: { type: Object as () => StepStatus, required: true },
    isEdit: { type: Boolean },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "edit-image", "update-step"],
  data() {
    return {
      post: new YahooPostForm(),
      files: [] as FileSelectionItem[],
      registrationDate: dayjs().format("YYYY/MM/DD"),
      // 概要タブ掲載期間は当日含む最大90日先まで(過去に制限はなさそうだが、あまり古いものは意味がないので縛っておく)
      threeMonthsAgo: dayjs().subtract(89, "days").format("YYYY-MM-DD"),
      threeMonthsLater: dayjs().add(89, "days").format("YYYY-MM-DD"),
      rules: VuetifyValidator as typeof VuetifyValidator,
      pinnedDescription:
        "施設詳細ページの概要タブに表示させる期間です。最新のお知らせ1件がYahoo!マップアプリにも表示されます。（最大90日先まで設定可能）",
      isValidateForm: false as boolean,
      yahooPostTypes: YahooPostTypes,
      // 選択可能な投稿種別（API仕様上は変更可能だが、他の仕様と足並みをそろえて編集時は投稿種別を変更不可にする）
      currentPostTypes: YahooPostTypes as YahooPostTypeDict[],
      fileErrorMessages: "", // 4-2. 選択済の画像・動画の編集に表示するエラーメッセージ
      previewErrorMessages: "", // 6. 投稿内容プレビューに表示するエラーメッセージ（短縮する）
      dateTimeErrorMessage: "",
      titleErrorMessage: "", // お知らせタイトルのエラーメッセージ
      descriptionErrorMessage: "", // お知らせ内容のエラーメッセージ
    };
  },
  watch: {
    // propを監視
    modelValue: {
      immediate: true,
      async handler(newval: YahooPostForm, oldval) {
        this.post.postType = newval.postType;
        this.post.title = newval.title;
        this.post.description = newval.description;
        this.post.pinnedStartDate = newval?.pinnedStartDate || "";
        this.post.pinnedStartTime = newval?.pinnedStartTime || "";
        this.post.pinnedEndDate = newval?.pinnedEndDate || "";
        this.post.pinnedEndTime = newval?.pinnedEndTime || "";
        // 強制的にバリデートする
        await this.$nextTick(); // VForm.validate() を呼ぶ前には $nextTick が必要
        await (this.$refs.yahooForm as VForm)?.validate();
        // 編集時は投稿種別を変更不可にする
        if (this.isEdit) {
          this.currentPostTypes = YahooPostTypes.filter((type) => type.id === this.post.postType);
        }
      },
    },
    baseText: {
      immediate: true,
      handler(newval: string, oldval) {
        // 共通テキストが変更されたら変更を上書き
        this.post.description = this.baseText;
      },
    },
    fileSelections: {
      immediate: true,
      deep: true,
      async handler(newval, oldval) {
        // 選択済みのファイルを順番通りにソートした上でfilesに格納する
        this.files = sortFileSelectionItemList(this.fileSelections);
        // 画像選択されたらプレビューを有効化する
        if (this.files.length > 0 && !this.$props.step.enabled) {
          this.$props.step.enabled = true;
        }
        await this.validateFiles();
      },
    },
    post: {
      deep: true,
      async handler() {
        this.validateDatetime();
        await this.$nextTick(); // VForm.validate() を呼ぶ前には $nextTick が必要
        await (this.$refs.yahooForm as VForm)?.validate();
        await this.validateFiles();
        this.$emit("update:modelValue", this.post);
      },
    },
    "post.pinnedStartDate": {
      // 日付が入力されたら、時間の入力がない場合は時間を初期化する(iOSのtimeのinput欄がが隠れてしまう問題の対策として)
      handler(newval, oldval) {
        this.post.pinnedStartDate = newval;
        console.log("pinnedStartDate", newval);
        if (newval != "" && this.post.pinnedStartTime === "") {
          this.post.pinnedStartTime = "00:00";
        }
      },
    },
    "post.pinnedEndDate": {
      handler(newval, oldval) {
        this.post.pinnedEndDate = newval;
        if (newval != "" && this.post.pinnedEndTime === "") {
          this.post.pinnedEndTime = "23:59";
        }
      },
    },
    isValidateForm: {
      handler(newval: boolean, oldval) {
        this.isCompleted(newval, oldval);
      },
    },
    step: {
      deep: true,
      immediate: true,
      async handler() {
        await this.validateFiles();
      },
    },
  },
  methods: {
    getPostTypeDisplayName(postType: EntitiesYahooFeedType): string {
      return this.yahooPostTypes.find((type) => type.id === postType)?.name || "";
    },
    getDescriptionPlaceholder(postType: EntitiesYahooFeedType): string {
      switch (postType) {
        case "NOTICE":
          return "お知らせの内容を入力してください";
        case "CAMPAIGN":
          return "キャンペーンの内容を入力してください";
        case "DAYOFF":
          return "営業状況を入力してください";
        case "ETC":
          return "お客様へのご案内を自由に入力してください";
        default:
          return "";
      }
    },
    isCompleted(newval?, oldval?): void {
      this.validateDatetime();
      this.$emit("update-step", {
        isComplete:
          this.isValidateForm && this.dateTimeErrorMessage == "" && this.fileErrorMessages == "",
        errorMessage: `${this.fileErrorMessages}`,
      });
    },
    async validateFiles(): Promise<void> {
      this.fileErrorMessages = "";
      this.previewErrorMessages = "";

      // 無効ならばエラーメッセージは出さない
      await this.$nextTick();
      if (!this.$props.step.enabled) {
        return;
      }

      if (this.fileErrorMessages !== "") {
        this.previewErrorMessages = this.fileErrorMessages;
        this.fileErrorMessages = `${platformOfficialNames.yahoo} ${this.fileErrorMessages}`;
        this.$emit("update-step", {
          isComplete: false,
          errorMessage: `${this.fileErrorMessages}`,
        });
        return;
      }
      // アスペクト比・画像サイズなどのバリデーション
      const errorMessages: string[] = [];
      for (const file of this.files) {
        await validateFile(file, this.files.length, "yahoo", errorMessages, false);
      }
      if (errorMessages.length === 0) {
        this.fileErrorMessages = "";
        this.previewErrorMessages = "";
        this.isCompleted();
        return;
      }
      this.fileErrorMessages = errorMessages.join("\n");
      this.$emit("update-step", { isComplete: false, errorMessage: this.fileErrorMessages });

      // 冗長なのでpreviewに表示するエラー文は短くする
      this.previewErrorMessages = errorMessages
        .map((m) => m.replace(platformOfficialNames.yahoo + " ", ""))
        .join("\n");
    },
    validateDatetime(): void {
      this.dateTimeErrorMessage = "";
      if (
        this.post.pinnedStartDate === "" &&
        this.post.pinnedStartTime === "" &&
        this.post.pinnedEndDate === "" &&
        this.post.pinnedEndTime === ""
      ) {
        // 必須項目ではないのでOK
        return;
      }
      // どれかが入力されていたら、全部入力されている必要がある
      if (
        this.post.pinnedStartDate === "" ||
        this.post.pinnedStartTime === "" ||
        this.post.pinnedEndDate === "" ||
        this.post.pinnedEndTime === ""
      ) {
        this.dateTimeErrorMessage = "開始日時と終了日時の両方を入力する必要があります";
        return;
      }
      // フォームでは日付は入力規則で縛ることができているが、日時セットで改めてバリデーションする
      const start = dayjs(`${this.post.pinnedStartDate} ${this.post.pinnedStartTime}`);
      const end = dayjs(`${this.post.pinnedEndDate} ${this.post.pinnedEndTime}`);
      if (start.isAfter(end)) {
        this.dateTimeErrorMessage = "終了日時は開始日時より後に設定してください";
        return;
      }
      // Yahooの画面的には当日含むパターン
      if (end.isAfter(dayjs().add(90, "days"))) {
        this.dateTimeErrorMessage = "終了日時は今日から90日以内に設定してください";
        return;
      }
    },
    editImage(order: FileSelectionState): void {
      // EditImageSelectedを作ってposts.vueのイベントを親コンポーネントを経由して発火させる
      this.$emit("edit-image", { platform: "yahoo", order: order });
    },
  },
});
</script>

<style lang="scss" scoped>
.post-preview {
  max-width: 340px;
  padding-left: unset;
  padding-right: unset;
}

.pinned-period {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 3px #00000029;

  .pinned-tooltip {
    margin-top: -0.1rem;
  }

  .pinned-label {
    margin-top: 0.25rem;
  }

  .pinned-datetime {
    font-size: 1rem;
  }

  .period-time {
    margin-top: 0.25rem;
    margin-left: -0.25rem;
    border-bottom: 1px solid #dbdbdb;
    padding-left: 0.25rem;
  }
}

.preview-card {
  padding: 0 16px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 3px #00000029;
  border-radius: 6px;
  opacity: 1;
}

.registration-date {
  padding-top: 0.5rem;
  font-size: 0.9rem;
}

.image-box {
  margin: 0 !important;
  position: relative;
}

.edit-span {
  position: absolute;
  top: 1.2rem;
  width: 95%;
  text-align: center;
  z-index: var(--z-index-loading);
}

.edit-button {
  background-color: rgba(0, 0, 0, 0.54) !important;
  color: white !important;
  border: none;
}

.error-message {
  color: red;
  font-size: 0.75rem;
  white-space: pre-wrap;
}
</style>
