<template>
  <v-dialog v-model="show" width="450" persistent>
    <v-card>
      <v-form ref="form" v-model="isValid">
        <v-card-text class="my-n4">
          <v-textarea
            ref="textarea"
            v-model="textValue"
            variant="underlined"
            color="primary"
            auto-grow
            rows="3"
            :counter="maxCount"
            :rules="validateRules"
          />
        </v-card-text>
        <v-card-actions class="mb-2 mx-2">
          <v-spacer />
          <v-btn
            text="キャンセル"
            color="primary"
            variant="outlined"
            @click.prevent="show = false"
          />
          <v-btn text="OK" color="primary" variant="flat" @click="submit" />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { VForm } from "vuetify/lib/components/index.mjs";

export default defineComponent({
  props: {
    isOpen: { type: Boolean, required: false },
    row: { type: Object, required: false, default: null },
    field: { type: String, required: false, default: "text" },
    maxCount: { type: Number, required: false, default: 0 },
    // note: helpersの`VuetifyValidator`を使用してください
    validateRules: { type: Array<any>, required: false, default: () => [] },
  },
  emits: ["close", "update:text"],
  data() {
    return {
      isValid: true,
      textValue: "",
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.isOpen;
      },
      set() {
        (this.$refs.form as VForm)?.resetValidation();
        this.$emit("close");
      },
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.$nextTick(() => {
            (this.$refs.textarea as HTMLElement).focus();
          });
          this.textValue = this.row[this.field] ?? "";
        }
      },
    },
  },
  methods: {
    submit() {
      if (this.isValid) {
        this.$emit("update:text", this.textValue);
        this.show = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped></style>
