<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="30 0 45 140">
    <g id="レイヤー_2" data-name="レイヤー 2">
      <g id="レイヤー_1-2" data-name="レイヤー 1">
        <polygon
          class="cls-3"
          points="69.35 9.52 39.67 26.67 39.67 60.97 69.35 78.12 69.35 78.12 99.02 60.97 99.02 26.67 69.35 9.52"
        />
        <polygon
          class="cls-4"
          points="69.35 78.12 39.67 60.97 10 78.12 10 112.42 39.67 129.57 39.67 129.57 69.35 112.42 69.35 78.12"
        />
        <polygon
          class="cls-5"
          points="128.69 78.12 99.02 60.97 69.35 78.12 69.35 112.42 99.02 129.57 99.02 129.57 128.69 112.42 128.69 78.12"
        />
        <polygon
          class="cls-6"
          points="69.35 43.82 39.67 60.97 39.67 95.27 69.35 112.42 69.35 112.42 99.02 95.27 99.02 60.97 69.35 43.82"
        />
        <path
          class="cls-2"
          d="M69.35,116,36.57,97.06V59.18L69.35,40.24l32.77,18.94V97.06ZM42.77,93.48l26.58,15.36L95.92,93.48V62.76L69.35,47.4,42.77,62.76Z"
        />
        <rect class="cls-2" x="68.15" y="9.52" width="2.4" height="68.6" />
        <polygon
          class="cls-2"
          points="10.6 113.45 9.4 111.38 69.35 76.73 129.29 111.38 128.09 113.45 69.35 79.5 10.6 113.45"
        />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.cls-1 {
  fill: #575757;
}

.cls-2 {
  fill: #f5f5f5;
}

.cls-3 {
  fill: #e95485;
}

.cls-4 {
  fill: #42a4e4;
}

.cls-5 {
  fill: #73c92d;
}

.cls-6 {
  fill: #ee9d26;
}
</style>
