<template>
  <v-dialog width="450" :model-value="isOpen" persistent>
    <v-card>
      <v-card-title class="headline">
        <div>カテゴリ選択</div>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedItems"
          :items="categories"
          item-title="name"
          item-value="qualifiedId"
          :multiple="multiple"
          color="primary"
          clearable
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="outlined" color="primary" text="キャンセル" @click="cancel" />
        <v-btn variant="flat" color="primary" text="OK" @click="submit" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { EntitiesAppleCategory } from "@/types/ls-api";

export default defineComponent({
  props: {
    isOpen: { type: Boolean, required: false },
    poiID: { type: Number, required: true },
    isPrimary: { type: Boolean, default: false },
    selectedCategories: { type: Array<EntitiesAppleCategory>, default: () => [] },
    categories: { type: Array<EntitiesAppleCategory>, default: () => [] },
  },
  emits: ["submit", "cancel"],
  data() {
    return {
      selectedItems: this.selectedCategories ?? [],
    };
  },
  computed: {
    multiple(): boolean {
      return !this.isPrimary;
    },
  },
  watch: {
    selectedCategories: {
      deep: true,
      handler(newVal: EntitiesAppleCategory[]) {
        this.selectedItems = newVal ?? [];
      },
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit", this.poiID, this.selectedItems, this.isPrimary);
    },
  },
});
</script>
<style lang="scss"></style>
