import { apiBase } from "@/const";
import { requiredAuth } from "@/helpers";
import type {
    ControllersMFAEnabledInput,
    ControllersMFAEnabledOutput,
    EntitiesUser,
    EntitiesUsersResponse,
} from "@/types/ls-api";
import type { AxiosResponse } from "axios";
export const api = { getUsers, createUser, updateUser, updateMfaEnabled };

async function getUsers(poiGroupId: number): Promise<EntitiesUser[]> {
    const url = `${apiBase}/companies/${poiGroupId}/users`;
    const res = await requiredAuth<EntitiesUsersResponse>("get", url);
    return res.data.users ?? [];
}

async function createUser(
    poiGroupId: number,
    user: EntitiesUser
): Promise<AxiosResponse<EntitiesUser>> {
    const data = { ...user, poiGroupID: poiGroupId };
    const url = `${apiBase}/companies/${poiGroupId}/users`;
    const res = await requiredAuth<EntitiesUser>("post", url, null, data);
    return res;
}

async function updateUser(
    poiGroupId: number,
    uuID: string,
    user: EntitiesUser
): Promise<AxiosResponse<EntitiesUser>> {
    const data = { ...user, poiGroupID: poiGroupId, uuID };
    const url = `${apiBase}/companies/${poiGroupId}/users/${uuID}`;
    const res = await requiredAuth<EntitiesUser>("put", url, null, data);
    return res;
}
async function updateMfaEnabled(
    poiGroupId: number,
    uuID: string,
    useMFA: boolean
): Promise<AxiosResponse<EntitiesUser>> {
    const data: ControllersMFAEnabledInput = { enabled: useMFA };
    const url = `${apiBase}/companies/${poiGroupId}/users/${uuID}/mfaEnabled`;
    const res = await requiredAuth<ControllersMFAEnabledOutput>("post", url, null, data);
    return res;
}
