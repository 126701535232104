<template>
  <v-sheet class="d-flex flex-column ma-1 bg-transparent">
    <!-- ヘッダ部分：投稿スイッチ -->
    <div class="d-flex align-center header-row" :style="isMobile ? '' : 'width: 340px;'">
      <div :style="makeStyle" class="pa-2">
        <v-icon
          :class="makeIconClass"
          :icon="icon"
          :size="icon == '$yahoo' || icon == `$hosting` ? 30 : 24"
        />
      </div>
      <div :style="enabled ? 'color: #404040;' : 'color: #AAAAAA;'" class="me-auto">
        {{ name }}
      </div>
      <div>
        <v-switch
          v-model="enabled"
          inset
          color="#4AD4BE"
          hide-details
          :disabled="disabled"
          :readonly="readonly"
          class="mr-1"
        />
      </div>
    </div>

    <!-- プレビュー本体コンポーネント用のslot -->
    <slot name="preview-body" />
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: { type: Boolean, required: true },
    name: { type: String, required: true },
    icon: { type: String, required: true },
    color: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  computed: {
    enabled: {
      get(): boolean {
        // console.log("enabled get", this.modelValue);
        return this.disabled ? false : this.modelValue;
      },
      set(value: boolean): void {
        // console.log("enabled changed", value);
        this.$emit("update:modelValue", value);
      },
    },
    makeStyle(): string {
      if (this.color == "") {
        return "";
      }
      return this.enabled ? `color: ${this.color};` : "color: #A7A7A7;";
    },
    makeIconClass(): string {
      if (this.color == "") {
        return this.enabled ? "" : "gray-icon";
      }
      return "";
    },
  },
  watch: {
    // オプション契約などによって投稿先が選べない場合は強制的に enabled を OFF にする
    disabled: {
      immediate: true,
      handler(value: boolean): void {
        // console.log("disabled changed", value);
        if (value) {
          this.enabled = false;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.post-preview {
  max-width: 340px !important; // 実物大
  padding: 0 12px;
  margin: 5px;
}

.header-row {
  padding: 0 0 0 0;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 3px #00000029;
  opacity: 1;
}

.switch {
  margin: 0;
}

.footer-row {
  width: 340px;
  padding: 0 0 0 0;
}

.gray-icon {
  -webkit-filter: grayscale(1);
  /* Webkit */
  filter: gray;
  /* IE6-9 */
  filter: grayscale(1);
  /* W3C */
}
</style>
