<template>
  <div v-show="fileUploaded" class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th></th>
          <th :class="isMobile ? '' : 'file-info-header'"></th>
          <th class="google-header">
            <v-icon icon="$google" size="20" />
            <p>{{ counts["google"] }} / {{ maxCounts.google }}</p>
          </th>
          <th class="yahoo-header">
            <v-icon icon="$yahoo" size="28" />
            <p>{{ counts["yahoo"] }} / {{ maxCounts.yahoo }}</p>
          </th>
          <th class="ig-header">
            <v-icon icon="$instagram" size="20" />
            <p>{{ counts["instagram"] }} / {{ maxCounts.instagram }}</p>
          </th>
          <th class="fb-header">
            <v-icon icon="$facebook" size="20" />
            <p>{{ counts["facebook"] }} / {{ maxCounts.facebook }}</p>
          </th>
          <th class="hosting-header">
            <v-icon icon="$hosting" size="20" />
            <p>{{ counts["hosting"] }} / {{ maxCounts.hosting }}</p>
          </th>
        </tr>
      </thead>
      <tbody v-if="isFilesLoaded">
        <tr class="all-check">
          <th colspan="2">全て選択</th>
          <th>
            <label>
              <input
                type="checkbox"
                class="all-checkbox"
                :disabled="disabled"
                @change="checkAll($event, 'google')"
              />
            </label>
          </th>
          <th>
            <label>
              <input
                type="checkbox"
                class="all-checkbox"
                :disabled="disabled"
                @change="checkAll($event, 'yahoo')"
              />
            </label>
          </th>
          <th>
            <label>
              <input
                type="checkbox"
                class="all-checkbox"
                :disabled="disabled"
                @change="checkAll($event, 'instagram')"
              />
            </label>
          </th>
          <th>
            <label>
              <input
                type="checkbox"
                class="all-checkbox"
                :disabled="disabled"
                @change="checkAll($event, 'facebook')"
              />
            </label>
          </th>
          <th>
            <label>
              <input
                type="checkbox"
                class="all-checkbox"
                :disabled="disabled"
                @change="checkAll($event, 'hosting')"
              />
            </label>
          </th>
        </tr>
        <tr v-for="(fs, key) of googleFileSelection" :key="key">
          <td>
            <video v-if="isVideo(fs)" :src="fs.videoUrl" :poster="fs.imageUrl" class="video" />
            <v-img
              v-else
              :src="fs.imageUrl"
              :alt="fs.file.name"
              :aspect-ratio="16 / 9"
              width="100px"
              cover
              position="center"
            />
          </td>
          <td>
            <v-hover v-slot="{ isHovering, props }">
              <div v-bind="props" :class="isMobile ? '' : 'file-info-body'">
                <!-- モバイル版はファイル名・ファイルサイズを表示しない -->
                <div v-if="!isMobile" class="file-info">
                  <div>
                    <p class="file-name" :title="fs.file.name">{{ fs.file.name }}</p>
                    <p class="file-size">{{ kbFilter(fs.file.size) }}</p>
                  </div>
                </div>
                <div style="width: 40px; vertical-align: middle !important">
                  <div v-show="!isHovering && isValidFileSelection(key)" class="ok-icon">
                    <span>OK</span>
                  </div>
                  <div v-show="!isHovering && !isValidFileSelection(key)" class="ng-icon">
                    <span>
                      <i class="fas fa-exclamation-circle"></i>
                    </span>
                  </div>
                  <div
                    v-show="isHovering"
                    class="trash-icon"
                    @click="disabled ? null : deleteFile(key)"
                  >
                    <v-btn size="small" icon="far fa-trash-alt" :disabled="disabled" />
                  </div>
                </div>
              </div>
            </v-hover>
          </td>
          <td class=".platform-cell border-column">
            <file-selection-checkbox
              :status="googleFileSelection[key].state"
              :reject-message="googleFileSelection[key].rejectMessage"
              :disabled="disabled"
              @click="updateStatus('google', key)"
            />
          </td>
          <td class=".platform-cell">
            <file-selection-checkbox
              :status="yahooFileSelection[key].state"
              :reject-message="yahooFileSelection[key].rejectMessage"
              :disabled="disabled"
              @click="updateStatus('yahoo', key)"
            />
          </td>
          <td class=".platform-cell">
            <file-selection-checkbox
              :status="instagramFileSelection[key].state"
              :reject-message="instagramFileSelection[key].rejectMessage"
              :disabled="disabled"
              @click="updateStatus('instagram', key)"
            />
          </td>
          <td class=".platform-cell">
            <file-selection-checkbox
              :status="facebookFileSelection[key].state"
              :reject-message="facebookFileSelection[key].rejectMessage"
              :disabled="disabled"
              @click="updateStatus('facebook', key)"
            />
          </td>
          <td class=".platform-cell">
            <file-selection-checkbox
              :status="hostingFileSelection[key].state"
              :reject-message="hostingFileSelection[key].rejectMessage"
              :disabled="disabled"
              @click="updateStatus('hosting', key)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FileSelectionCheckbox from "./file-selection-checkbox.vue";
import type {
  FileSelectionItem,
  FileSelectionState,
  PlatformName,
} from "@/models/v2-file-selection";
import { getExtension, isVideo, platformList } from "@/models/v2-file-selection";
import type { FileAmountsOfPlatforms } from "./validator";
import { checkMaxFiles, maxImageFiles } from "./validator";
import { getter } from "@/storepinia/idxdb";

export default defineComponent({
  components: { FileSelectionCheckbox },
  props: {
    googleFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    yahooFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    instagramFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    facebookFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    hostingFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    aspectNoCheck: { type: Boolean },
    isMobile: { type: Boolean, default: false },
    // readonlyで制御できる要素がないため、disabledのみ設定
    disabled: { type: Boolean, default: false },
  },
  emits: ["deleteImgFile"],
  data: () => ({
    company: getter().company,
    hover: false,
  }),
  computed: {
    // ファイルがアップロードされているか？
    fileUploaded(): boolean {
      return platformList.reduce((acc, pf) => acc + this.getSelections(pf).length, 0) !== 0;
    },
    // pfごとのファイル数
    counts(): FileAmountsOfPlatforms {
      return platformList.reduce(
        (acc, pf: PlatformName) => ({
          ...acc,
          [pf]: this.getSelections(pf)?.filter((fs) => typeof fs.state === "number")?.length ?? 0,
        }),
        {}
      );
    },
    // pfごとのファイル上限
    maxCounts(): FileAmountsOfPlatforms {
      return maxImageFiles(this.company.canUseGbpConsole);
    },
    // 5つのプラットフォームが同数のファイルを持っているか？
    isFilesLoaded(): boolean {
      return (
        this.googleFileSelection.length === this.yahooFileSelection.length &&
        this.googleFileSelection.length === this.instagramFileSelection.length &&
        this.googleFileSelection.length === this.facebookFileSelection.length &&
        this.googleFileSelection.length === this.hostingFileSelection.length
      );
    },
  },
  methods: {
    kbFilter(val: number) {
      // 国際単位系 (MacのFinderでのサイズ表記) に合わせ、KB = 1 * 1000 byte とする
      // 調査した結果、各社とも上限は厳密なものではなく KB = 1000B で計算して問題ないと判断
      return `${(val / 1000).toFixed(1)} KB`;
    },
    updateStatus(
      platform: PlatformName,
      idx: number,
      isAllCheck = false,
      isAllClear = false
    ): void {
      const selections = this.getSelections(platform);
      const current: FileSelectionState = selections[idx].state;
      if (current === "rejected") {
        return;
      }
      if (current === "disabled") {
        return;
      }
      // 選択解除中にクリックされた場合はそのPFの末尾に追加する
      if (current === "deselected") {
        if (isAllClear) {
          return;
        }
        // 上限超えてれば無視
        const count = selections.filter((s) => typeof s.state === "number").length;
        if (count >= maxImageFiles(this.company.canUseGbpConsole)[platform]) {
          return;
        }
        selections.splice(idx, 1, { ...selections[idx], state: count + 1 });
      }
      // 選択中にクリックされた場合は選択を解除し、番号を振り直す
      else if (typeof current === "number") {
        if (isAllCheck) {
          return;
        }
        selections[idx].state = "deselected";
      }
      this.renumberSelections(platform);
    },
    // 選択ファイルの削除
    deleteFile(idx: number): void {
      platformList.forEach((pf) => {
        // 削除対象ファイルをpost.vueのpostFilesから取り除く
        const deletionTarget: FileSelectionItem = this.getSelections(pf)[idx];
        if (deletionTarget) {
          this.$emit("deleteImgFile", deletionTarget.file.name, deletionTarget.s3FileName);
        }

        this.getSelections(pf).splice(idx, 1);
        this.renumberSelections(pf);
      });
    },

    isVideo(fs: FileSelectionItem): boolean {
      return isVideo(fs.file);
    },
    isValidFileSelection(index: number): boolean {
      return (
        this.googleFileSelection[index].state !== "rejected" ||
        this.yahooFileSelection[index].state !== "rejected" ||
        this.instagramFileSelection[index].state !== "rejected" ||
        this.facebookFileSelection[index].state !== "rejected" ||
        this.hostingFileSelection[index].state !== "rejected"
      );
    },
    // プラットフォームごとのファイル選択状態への操作
    renumberSelections(pf: PlatformName) {
      const canUseGbpConsole = this.company.canUseGbpConsole;
      const selections = this.getSelections(pf);
      // 番号振り直し
      let newSeq = 1;
      for (let oldSeq = 1; oldSeq <= maxImageFiles(canUseGbpConsole)[pf]; oldSeq++) {
        const idx = selections.findIndex((s) => s.state === oldSeq);
        if (idx === -1) {
          continue;
        }
        selections[idx].state = newSeq++;
      }
      // 上限判定
      for (let si = 0; si < selections.length; si++) {
        if (selections[si].state == "disabled" || selections[si].state == "deselected") {
          // 戻り値の関係でいったん非選択状態に戻してから上限判定を行う
          selections[si].state = "deselected";
          const imageState = checkMaxFiles(
            canUseGbpConsole,
            selections,
            getExtension(selections[si].file.name),
            pf,
            {
              state: "deselected",
            }
          );
          selections[si].state = imageState.state;
          selections[si].rejectMessage = imageState.message;
        }
      }
    },
    getSelections(platform: PlatformName) {
      switch (platform) {
        case "google":
          return this.googleFileSelection;
        case "yahoo":
          return this.yahooFileSelection;
        case "instagram":
          return this.instagramFileSelection;
        case "facebook":
          return this.facebookFileSelection;
        case "hosting":
          return this.hostingFileSelection;
      }
    },

    // プラットフォーム毎に全選択・全解除する
    checkAll(e: Event, platform: PlatformName) {
      const selections = this.getSelections(platform);
      const isChecked = (e.target as HTMLInputElement).checked;
      for (let i = 0; i < selections.length; i++) {
        this.updateStatus(platform, i, isChecked, !isChecked);
      }
    },
  },
});
</script>
<style lang="css" scoped>
@import url("https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@600&display=swap");
</style>
<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
$google-color: #34a853;
$yahoo-color: #ff0033;
$ig-color: #e1306c;
$fb-color: #2577f2;
$hosting-color: #ff6c00;
$ok-color: #40cdb0;
$ng-color: #e83b3f;
$text-color: #7a7a7a;

$border-color: #e0e0e0;

.message {
  size: 14px;
}

.table-wrapper {
  overflow: auto;
  max-height: 700px;

  table {
    table-layout: fixed;
    background-color: white;

    thead th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: var(--z-index-loading);
      background: white;
    }

    th {
      border-bottom: solid !important;
      border-bottom-color: $border-color !important;
      border-bottom-width: 2px !important;
    }

    .all-check {
      th {
        label {
          display: block;
          padding: 5px;
          cursor: pointer;
        }

        &:first-child {
          padding: 5px;
          font-weight: normal;
          text-align: right;
          font-size: 12px;
          vertical-align: middle;
        }

        text-align: center;
      }
    }

    td {
      word-wrap: break-word;
      vertical-align: middle;
      padding-top: 0;
      padding-bottom: 0;
    }

    p {
      border-style: none;
      padding-left: 0;
      margin-bottom: 2px;
    }

    tbody {
      .image {
        width: 100px;
      }

      .video {
        @extend .image;

        height: calc(100px / 16 * 9);
        background-color: #000;
      }

      td {
        border-bottom: solid;
        border-bottom-color: white;
        border-left-width: 2px;
      }
    }
  }
}

.all-checkbox {
  // lightモードであれば他と同様にチェックが白になる想定(ダークモード未対応)
  accent-color: #149477;
}

/* 投稿先選択左側の縦線を定義 */
.border-column {
  border-left: solid !important;
  border-left-color: $border-color !important;
  border-left-width: 2px !important;
}

.file-info-header {
  min-width: 150px;
}

.file-info-body {
  display: flex;
  justify-content: space-between;
}

.file-info {
  display: flex;
  justify-content: space-between;
  min-width: 140px;
  padding: 8px;

  .file-name {
    font-size: 14px;
    line-height: 16px;
    color: $text-color;
    height: 18px;
    padding: 0;
    margin: 0;
    max-width: 12vw;

    /* 画面サイズが大きくなると右側の余白が気になるのでファイル名表示部分を調整 */
    @media screen and (min-width: 2050px) {
      max-width: 20vw;
    }
  }

  .file-size {
    font-size: 10px;
    font-weight: bold;
    color: $ok-color;
  }
}

.validation-icon {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle !important;
}

.ok-icon {
  @extend .validation-icon;

  color: $ok-color;
  font-family: "Saira Extra Condensed", sans-serif;
  font-size: 20px;
  padding: 7px;
}

.ng-icon {
  @extend .validation-icon;

  color: $ng-color;
}

.trash-icon {
  @extend .validation-icon;

  color: white;
}

.platform-header {
  width: 80px;
  text-align: center !important;
  min-width: 40px;

  font-awesome-icon {
    font-size: 12px;
  }

  p {
    font-size: 8px;
  }
}

.google-header {
  @extend .platform-header;

  color: $google-color;
}

.yahoo-header {
  @extend .platform-header;

  color: $yahoo-color;

  // 他よりアイコンが小さいのでサイズを大きくした分余白を削る
  i {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
}

.ig-header {
  @extend .platform-header;

  color: $ig-color;
}

.fb-header {
  @extend .platform-header;

  color: $fb-color;
}

.hosting-header {
  @extend .platform-header;

  color: $hosting-color;
}

.google-icon {
  color: $google-color;
}

.platform-cell {
  text-align: center !important;
}
</style>
