import type { ColumnDefine } from "cheetah-grid";
import type {
    EntitiesStore,
    EntitiesAppleLocation,
    EntitiesAppleCategory,
    EntitiesAppleLocationUpdateMask,
} from "@/types/ls-api";

/** 区切り文字 */
export const DELIMITER = "|";

export class AppleCategoryMaster {
    categories: EntitiesAppleCategory[] = [];

    constructor(categories: EntitiesAppleCategory[]) {
        // nameが重複する場合は`name(qualifiedId)`という形式にする
        const nameCounts = {};
        for (const category of categories) {
            if (!nameCounts[category.name]) {
                nameCounts[category.name] = 0;
            }
            nameCounts[category.name]++;
        }
        for (const category of categories) {
            if (nameCounts[category.name] > 1) {
                category.name = `${category.name}(${category.qualifiedId})`;
            }
        }
        this.categories = categories;
    }
    find(id: string): EntitiesAppleCategory {
        return this.categories?.find((c) => c.qualifiedId === id);
    }
    findByName(name: string): EntitiesAppleCategory {
        return this.categories?.find((c) => c.name === name);
    }
}

/** Appleの店舗情報のステータスを日本語表示するための辞書 */
export const locationStatusDict = {
    OPEN: "開店",
    CLOSED_PERMANENTLY: "閉店",
    CLOSED_TEMPORARILY: "臨時休業",
};

export type Columnx = ColumnDefine<Row> & { readOnly: boolean; abcLocation?: string };
// 更新できる項目のみabcLocationを設定
// note: phase1ではメインカテゴリの更新は封鎖して、phase2以降で条件付きで更新できるようにする
export const columns: Columnx[] = [
    { caption: "店舗ID", field: "poiID", readOnly: true },
    { caption: "店舗コード", field: "storeCode", readOnly: true },
    { caption: "ビジネス名", field: "storeName", readOnly: true },
    {
        caption: "メインカテゴリ",
        field: "primaryCategory",
        readOnly: true,
        abcLocation: "categories",
    },
    {
        caption: "追加カテゴリ",
        field: "additionalCategories",
        readOnly: false,
        abcLocation: "categories",
    },
    {
        caption: "ビジネス情報",
        field: "description",
        readOnly: false,
        abcLocation: "locationDescriptions",
    },
    {
        caption: "緯度",
        field: "latitude",
        readOnly: false,
        abcLocation: "displayPoint.coordinates",
    },
    {
        caption: "経度",
        field: "longitude",
        readOnly: false,
        abcLocation: "displayPoint.coordinates",
    },
    { caption: "電話番号", field: "primaryPhone", readOnly: true },
    { caption: "追加電話番号", field: "additionalPhones", readOnly: true },
    { caption: "住所", field: "address", readOnly: true },
    { caption: "ステータス", field: "status", readOnly: true },
];

export type ApplePatchData = {
    poiId: number;
    storeName: string;
    mask: EntitiesAppleLocationUpdateMask[];
    location?: EntitiesAppleLocation;
};

export class Row {
    poiID: number;
    areas: number[];
    appleLocationID: string;
    isDirty: boolean;
    enabled: { [key: string]: boolean } = {};
    backup: { [key: string]: string } = {};
    categoryMaster: AppleCategoryMaster;
    // 以下フィールド
    storeName: string;
    storeCode: string;
    primaryCategory: EntitiesAppleCategory;
    additionalCategories: EntitiesAppleCategory[];
    description: string;
    latitude: string;
    longitude: string;
    primaryPhone: string;
    additionalPhones: string[];
    address: string;
    status: string;

    constructor(s: EntitiesStore, categoryMaster: AppleCategoryMaster) {
        this.poiID = s.poiID;
        // 判別がつきやすいようにAppleとの紐付けがなくてもGBPの店舗名を表示
        this.storeName = s.name;
        this.areas = s.areas ?? [];
        this.appleLocationID = s?.appleBusinessConnect?.locationId ?? "";
        // カテゴリを日本語で表示するために、カテゴリマスタを保持
        this.categoryMaster = categoryMaster;
        for (const column of columns) {
            const field = column.field as string;
            this.enabled[field] = !column.readOnly;
        }
    }
    get(column: Columnx): string {
        // カテゴリの場合の処理
        if (column.abcLocation === "categories") {
            // プライマリカテゴリの場合
            if (column.field === "primaryCategory") {
                return this.primaryCategory?.name ?? "";
            }
            // 追加カテゴリの場合
            return this.additionalCategories.map((category) => category.name).join(DELIMITER);
        }
        // その他のフィールドの場合
        return this[column.field as string] ?? "";
    }
    set(location: EntitiesAppleLocation) {
        if (!location) {
            return;
        }
        const details = location?.locationDetails;
        this.storeName = (details?.displayNames ?? [])[0]?.name ?? "";
        this.storeCode = details?.storeCode ?? "";
        this.primaryCategory = this.categoryMaster.find(details?.categories?.[0] ?? "");
        this.additionalCategories =
            details?.categories?.slice(1).map((c) => this.categoryMaster.find(c)) ?? [];
        this.description =
            (details?.locationDescriptions?.find((d) => d.type === "ABOUT")?.descriptions ?? [])[0]
                ?.text ?? "";
        this.latitude = details?.displayPoint?.coordinates?.latitude ?? "";
        this.longitude = details?.displayPoint?.coordinates?.longitude ?? "";
        this.primaryPhone = details?.phoneNumbers?.find((p) => p.primary)?.phoneNumber ?? "";
        this.additionalPhones = details?.phoneNumbers
            ?.filter((p) => !p.primary)
            .map((p) => p.phoneNumber);
        this.address = details?.mainAddress?.fullAddress ?? "";
        this.status = details?.locationStatus?.status ?? "";
        for (const column of columns) {
            const field = column.field as string;
            this.backup[field] = this[field];
        }
    }
    setCategories(categories: string[], isPrimary: boolean) {
        if (isPrimary) {
            this.primaryCategory = this.categoryMaster.find(categories[0]);
        } else {
            this.additionalCategories = categories.map((c) => this.categoryMaster.find(c)) ?? [];
        }
    }
    updateIsDirty(): void {
        for (const column of columns) {
            if (!column.abcLocation) continue;
            const field = column.field as string;
            if (this.hasFieldChanged(field)) {
                this.isDirty = true;
                return;
            }
        }
        this.isDirty = false;
    }
    hasFieldChanged(field: string): boolean {
        if (Array.isArray(this[field]) && Array.isArray(this.backup[field])) {
            return !arraysAreEqual(this[field], this.backup[field]);
        }
        return this[field] !== this.backup[field];
    }
    isReadOnly(field: string): boolean {
        return !this.enabled[field];
    }
    createPatchData(): ApplePatchData {
        const patchData: ApplePatchData = {
            poiId: this.poiID,
            storeName: this.storeName,
            mask: [],
            location: { locationDetails: {} },
        };
        // カテゴリ
        if (
            this.hasFieldChanged("primaryCategory") ||
            this.hasFieldChanged("additionalCategories")
        ) {
            patchData.mask.push("categories");
            // primaryCategoryは先頭に来る
            const categories = [
                this.primaryCategory?.qualifiedId,
                ...this.additionalCategories.map((c) => c.qualifiedId),
            ];
            // 重複を省く(メインの方を優先し、追加カテゴリで同じカテゴリを設定できないようにする)
            const categoriesSet = new Set(categories);
            patchData.location.locationDetails.categories = [...Array.from(categoriesSet)];
        }
        // 店舗の説明
        if (this.hasFieldChanged("description")) {
            patchData.mask.push("description");
            patchData.location.locationDetails.locationDescriptions = [
                {
                    type: "ABOUT",
                    descriptions: [
                        {
                            locale: "ja-JP",
                            text: this.description,
                        },
                    ],
                },
            ];
        }
        // 店舗の緯度経度
        if (this.hasFieldChanged("latitude") || this.hasFieldChanged("longitude")) {
            patchData.mask.push("latLng");
            patchData.location.locationDetails.displayPoint = {};
            patchData.location.locationDetails.displayPoint.coordinates = {
                latitude: this.latitude,
                longitude: this.longitude,
            };
        }
        // updateMaskの重複を排除(念の為)
        patchData.mask = patchData.mask.filter(
            (mask, index, self) => self.findIndex((m) => m === mask) === index
        );
        return patchData;
    }
}

// 配列の比較(単純な比較だとインスタンスが異なる場合にfalseになるため、要素の値が同じかどうかで比較)
export function arraysAreEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}
