<template>
  <div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card elevation="2" class="mb-2">
      <v-card-text>メニュー</v-card-text>
    </v-card>
    <div class="d-flex align-center mb-2">
      <v-btn class="me-auto" :elevation="0" :to="{ name: 'Stores' }">
        <v-icon left>fa-regular fa-arrow-alt-circle-left</v-icon>
        &nbsp; 店舗設定画面へ戻る
      </v-btn>
      <v-btn class="primary mr-3" @click="exportDialog.show = true">
        反映済み料金の一括ダウンロード
      </v-btn>
      <v-btn variant="outlined" class="mr-3" @click="reload(poiGroupId)">
        <v-icon left>fas fa-refresh</v-icon>
        最新の状態を取得
      </v-btn>
      <v-btn
        v-if="canManage"
        ref="showCreateDialog"
        class="primary"
        @click="createDialog.show = true"
      >
        メニューを新規追加
      </v-btn>
    </div>
    <div>
      <div v-if="groups.length === 0 && !isLoading">メニューはありません</div>
      <o-table
        v-else
        :data="groups"
        detailed
        :show-detail-icon="true"
        custom-row-key="foodMenuGroupID"
      >
        <o-table-column v-slot="props" label="メニュー名">
          {{ props.row.title }}
          <v-btn v-if="canManage" :elevation="0" @click="showRenameDialog(props.row)">
            <v-icon small>fas fa-pencil</v-icon>
          </v-btn>
        </o-table-column>
        <o-table-column v-slot="props" label="登録日時">
          {{ utcToJst(props.row.createdAt) }}
        </o-table-column>
        <o-table-column v-slot="props" label="更新日時">
          {{ utcToJst(props.row.updatedAt) }}
        </o-table-column>
        <o-table-column v-slot="props" label="店舗数">
          {{ props.row.stores.length }}
        </o-table-column>
        <o-table-column v-slot="props" label="状態 (待機中/反映中/成功/失敗)">
          <span v-if="0 < props.row.counts.ready + props.row.counts.running">反映中</span>
          ({{ props.row.counts.ready }}/{{ props.row.counts.running }}/{{
            props.row.counts.success
          }}/{{ props.row.counts.failure }})
        </o-table-column>
        <o-table-column v-if="canManage" v-slot="props" field="retry" label="反映">
          <div v-if="!props.row.applicationScheduledTime" class="button-col">
            <v-btn
              class="primary"
              :disabled="0 === props.row.counts.modified"
              @click="applyFoodMenus(poiGroupId, props.row.foodMenuGroupID)"
            >
              変更を反映
            </v-btn>
            <v-btn
              key="reserve"
              variant="outlined"
              :disabled="0 === props.row.counts.modified"
              @click="showReserveDialog(props.row)"
            >
              <v-icon left>fas fa-calendar</v-icon>
              反映を予約
            </v-btn>
          </div>
          <div v-if="props.row.applicationScheduledTime">
            <span>
              {{ utcToJst(props.row.applicationScheduledTime, "yyyy/MM/dd HH:mm") }} に反映予定
            </span>
            <v-btn
              key="remove-schedule"
              variant="outlined"
              @click="showRemoveScheduleDialog(props.row)"
            >
              予約を取り消し
            </v-btn>
          </div>
        </o-table-column>
        <o-table-column v-slot="props" :label="toEditLabel">
          <v-btn
            class="primary"
            :text="toEditLabel"
            :elevation="0"
            :to="{
              name: 'FoodMenuGroupEdit',
              params: {
                poiGroupId: props.row.poiGroupID,
                foodMenuGroupId: props.row.foodMenuGroupID,
              },
            }"
          />
        </o-table-column>
        <template #detail="props">
          <table v-if="props.row.stores.length !== 0" class="store-status">
            <thead>
              <tr>
                <th>店舗コード</th>
                <th>店舗名</th>
                <th>現在の状態</th>
                <th>状態更新日時</th>
                <th>最終反映日時</th>
                <th>変更有無</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="store in props.row.stores" :key="store.poiId">
                <td>{{ store.code }}</td>
                <td>{{ store.name }}</td>
                <td
                  v-if="
                    store.currentTaskFoodMenuGroupID &&
                    props.row.foodMenuGroupID === store.currentTaskFoodMenuGroupID
                  "
                >
                  <span v-if="store.currentTaskStatus === 'READY'" class="status-ready">
                    <v-icon>fas fa-hourglass</v-icon>
                    反映待ち
                  </span>
                  <span v-if="store.currentTaskStatus === 'RUNNING'" class="status-running">
                    <v-icon>fas fa-spinner</v-icon>
                    反映中
                  </span>
                  <span v-if="store.currentTaskStatus === 'SUCCESS'" class="status-success">
                    <v-icon>fas fa-check</v-icon>
                    反映成功
                  </span>
                  <span v-if="store.currentTaskStatus === 'FAILURE'" class="status-failure">
                    <v-icon color="#E95454">fas fa-exclamation-triangle</v-icon>
                    反映失敗
                  </span>
                  <span v-if="store.currentTaskStatus === 'CANCELED'" class="status-canceled">
                    <v-icon>fas fa-xmark</v-icon>
                    キャンセル
                  </span>
                </td>
                <td v-else></td>
                <td
                  v-if="
                    store.currentTaskFoodMenuGroupID &&
                    props.row.foodMenuGroupID === store.currentTaskFoodMenuGroupID
                  "
                >
                  {{ utcToJst(store.currentTaskUpdatedAt) }}
                </td>
                <td v-else></td>
                <td>
                  <span @click="getTasks(store.poiGroupID, store.poiID)">
                    {{ utcToJst(store.lastAppliedTaskUpdatedAt) }}
                  </span>
                </td>
                <td>
                  <span @click="showLastAppliedMenus(store.poiGroupID, store.poiID)">
                    <span v-if="store.isModified" class="status-ready">変更あり</span>
                    <span v-else>変更なし</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </o-table>
    </div>

    <!-- 料金エクスポートダイアログ -->
    <v-dialog v-model="exportDialog.show" max-width="600">
      <export-price-dialog :groups="groups" @cancel="exportDialog.show = false" />
    </v-dialog>

    <!-- メニューを新規追加ダイアログ -->
    <v-dialog v-model="createDialog.show" max-width="600">
      <v-card>
        <v-card-title class="text-h5">メニューを新規追加</v-card-title>
        <v-card-text name="menuname">
          <v-text-field
            v-model="createDialog.title"
            label="メニュー名"
            :rules="[rules.maxLength(70)]"
            counter="70"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createDialog.show = false">キャンセル</v-btn>
          <v-btn
            depressed
            color="primary"
            :disabled="!createDialog.title || createDialog.title.length > 70"
            @click="create()"
          >
            新規追加
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- メニュー名編集ダイアログ -->
    <v-dialog v-model="renameDialog.show" max-width="600">
      <v-card>
        <v-card-title class="text-h5">メニュー名編集</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="renameDialog.title"
            label="メニュー名"
            variant="underlined"
            :rules="[rules.maxLength(70)]"
            counter="70"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="renameDialog.show = false">キャンセル</v-btn>
          <v-btn
            class="primary"
            :disabled="!renameDialog.title || renameDialog.title.length > 70"
            @click="rename()"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- メニュー差分ダイアログ -->
    <v-dialog v-model="diffMenusDialog.show" max-width="1000">
      <v-card>
        <v-card-title class="text-h5">最後に反映したメニューと設定上のメニュー</v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col>
                最後に反映したメニュー
                <food-menus :menus="diffMenusDialog.prev" />
              </v-col>
              <v-col>
                設定上のメニュー
                <food-menus :menus="diffMenusDialog.next" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="diffMenusDialog.show = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 反映を予約ダイアログ -->
    <v-dialog v-model="reserveDialog.show" max-width="600">
      <ReserveDialog @cancel="reserveDialog.show = false" @submit="reserve" />
    </v-dialog>

    <!-- 反映予約の削除ダイアログ -->
    <v-dialog v-model="removeScheduleDialog.show" max-width="600">
      <confirm-dialog
        message="反映予約を取り消しますか？"
        cancel-button="キャンセル"
        submit-button="予約を取り消し"
        @cancel="removeScheduleDialog.show = false"
        @submit="removeSchedule()"
      />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ZoneId, DateTimeFormatter, ZonedDateTime } from "@js-joda/core";
import dayjs from "dayjs";
import type {
  EntitiesFoodMenuGroup,
  EntitiesStore,
  MybusinessbusinessinformationMetadata,
  MybusinessFoodMenu,
} from "@/types/ls-api";
import { trimLocationName } from "@/helpers/gmb";
import { api } from "@/helpers/api/food-menu";
import { VuetifyValidator, sleep } from "@/helpers";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import type { Group, Status } from "./group-list-utils";
import FoodMenus from "./food-menus.vue";
import ConfirmDialog from "./confirm-dialog.vue";
import ExportPriceDialog from "./export-price-dialog.vue";
import ReserveDialog from "./reserve-dialog.vue";
import { useSnackbar } from "@/storepinia/snackbar";
import { useIndexedDb } from "@/storepinia/idxdb";
import { getOperationLogParams } from "@/routes/operation-log";

export default defineComponent({
  components: { ConfirmDialog, FoodMenus, ExportPriceDialog, ReserveDialog },
  data: () => {
    return {
      range: { from: "", to: "" },
      isLoading: true,
      groups: [] as Group[],
      locationMetadata: {} as { [poiId: number]: MybusinessbusinessinformationMetadata },
      exportDialog: { show: false },
      createDialog: { show: false, title: "" },
      renameDialog: { show: false, title: "", row: null as EntitiesFoodMenuGroup },
      dialog: false,
      reserveDialog: {
        show: false,
        date: "",
        time: "",
        row: null as EntitiesFoodMenuGroup,
      },
      removeScheduleDialog: { show: false, row: null as EntitiesFoodMenuGroup },
      diffMenusDialog: {
        show: false,
        prev: [] as MybusinessFoodMenu[],
        next: [] as MybusinessFoodMenu[],
      },
      rules: VuetifyValidator,
    };
  },
  computed: {
    poiGroupId: function () {
      return parseInt(this.$route.params.poiGroupId as string);
    },
    canManage(): boolean {
      return useIndexedDb().canManageMenu;
    },
    toEditLabel(): string {
      return this.canManage ? "編集" : "詳細";
    },
  },
  async created() {
    await this.getFoodMenuGroups(this.poiGroupId);
    await this.getFoodMenuStores(this.poiGroupId);
    this.isLoading = false;
  },
  methods: {
    /** 再読み込みする */
    async reload(poiGroupId: number) {
      const oplogParams = getOperationLogParams(this.$route, "get", "foodmenu_list");
      const groups = await api.getFoodMenuGroups(poiGroupId, oplogParams);
      for (const newGroup of groups) {
        const g = this.groups.find((g) => g.foodMenuGroupID === newGroup.foodMenuGroupID);
        if (g) {
          g.applicationScheduledBy = newGroup.applicationScheduledBy;
          g.applicationScheduledTime = newGroup.applicationScheduledTime;
          g.createdAt = newGroup.createdAt;
          g.title = newGroup.title;
          g.updatedAt = newGroup.updatedAt;
        }
      }
      await this.getFoodMenuStores(poiGroupId);
    },
    addSnackbar: function (message: SnackbarToast): void {
      useSnackbar().addSnackbarMessages(message);
    },
    utcToJst(utc: string, format?: string): string {
      if (utc == null || utc === "") {
        return "";
      } else {
        format = format ? format : "yyyy/MM/dd HH:mm:ss";
        return ZonedDateTime.parse(utc)
          .withZoneSameInstant(ZoneId.of("Asia/Tokyo"))
          .format(DateTimeFormatter.ofPattern(format));
      }
    },

    /** メニューを新規追加 */
    async create() {
      const oplogParams = getOperationLogParams(this.$route, "post", "foodmenu_list");
      const group: Group = await api.postFoodMenuGroup(
        this.poiGroupId,
        this.createDialog.title,
        oplogParams
      );
      group.stores = [];
      group.counts = { ready: 0, running: 0, success: 0, failure: 0, canceled: 0, modified: 0 };
      this.groups.push(group);
      this.groups.sort((a, b) => (a.title < b.title ? -1 : 1));
      this.createDialog.title = "";
      this.createDialog.show = false;
      this.addSnackbar({
        text: "新規追加しました",
        color: "info",
      });
    },
    /** メニュー名変更ダイアログを表示 */
    showRenameDialog(row: Group) {
      this.renameDialog.title = row.title;
      this.renameDialog.row = row;
      this.renameDialog.show = true;
    },
    /** メニュー名を変更 */
    async rename() {
      const oplogParams = getOperationLogParams(this.$route, "put", "foodmenu_list");
      const row = this.renameDialog.row;
      const res = await api.putFoodMenuGroup(
        row.poiGroupID,
        row.foodMenuGroupID,
        {
          title: this.renameDialog.title,
        },
        oplogParams
      );
      Object.assign(row, res);
      this.groups.sort((a, b) => (a.title < b.title ? -1 : 1));
      this.renameDialog.show = false;
      this.addSnackbar({
        text: "保存しました",
        color: "info",
      });
    },
    /** 変更を反映 ボタン */
    async applyFoodMenus(poiGroupId: number, foodMenuGroupId: number) {
      const group = this.groups.find((g) => g.foodMenuGroupID === foodMenuGroupId);
      const poiIDs = group.stores.filter((s) => s.isModified).map((s) => s.poiID);
      const _res = await api.applyFoodMenus(poiGroupId, poiIDs, foodMenuGroupId);
      await sleep(1000);
      await this.reload(poiGroupId);
    },

    /** 反映を予約ダイアログを表示 */
    showReserveDialog(row: Group) {
      this.reserveDialog.row = row;
      this.reserveDialog.show = true;
    },
    /** 反映を予約する */
    async reserve(date: string, time: string) {
      const row = this.reserveDialog.row;
      const dt = dayjs(`${date} ${time}`).toISOString();
      const res = await api.putFoodMenuGroupSchedule(row.poiGroupID, row.foodMenuGroupID, dt);
      Object.assign(row, res);
      this.reserveDialog.show = false;
      this.addSnackbar({
        text: "反映を予約しました",
        color: "info",
      });
    },
    /** 反映予約キャンセルダイアログを表示 */
    showRemoveScheduleDialog(row: Group) {
      console.log(row);
      this.removeScheduleDialog.row = row;
      this.removeScheduleDialog.show = true;
    },
    /** 反映予約キャンセルする */
    async removeSchedule() {
      const row = this.removeScheduleDialog.row;
      const res = await api.putFoodMenuGroupSchedule(row.poiGroupID, row.foodMenuGroupID, null);
      Object.assign(row, res);
      row.applicationScheduledTime = null;
      this.removeScheduleDialog.show = false;
      this.addSnackbar({
        text: "反映予定を取り消しました",
        color: "info",
      });
    },

    /** 反映履歴取得 */
    async getTasks(poiGroupId: number, poiId: number) {
      // 単にAPIを実行するだけ。画面には表示しない
      const _fmats = await api.getFoodMenuApplicationTasks(poiGroupId, poiId);
    },
    /** メニュー差分ダイアログ 表示 */
    async showLastAppliedMenus(poiGroupId: number, poiId: number) {
      this.isLoading = true;
      const fms = await api.getFoodMenuStore(poiGroupId, poiId);
      this.isLoading = false;
      this.diffMenusDialog.prev = fms.lastAppliedMenus ?? [];
      this.diffMenusDialog.next = fms.nextMenus ?? [];
      this.diffMenusDialog.show = true;
    },
    // ---------------- 以下は画面からは直接実行されないメソッド ----------------
    /** food_menu_groups を取得 */
    async getFoodMenuGroups(poiGroupId: number) {
      this.isLoading = true;
      const oplogParams = getOperationLogParams(this.$route, "get", "foodmenu_list");
      const groups = (await api.getFoodMenuGroups(poiGroupId, oplogParams)) as Group[];
      for (const g of groups) {
        g.stores = [];
        g.counts = { ready: 0, running: 0, success: 0, failure: 0, canceled: 0, modified: 0 };
      }
      groups.sort((a, b) => (a.title < b.title ? -1 : 1));
      this.groups = groups;
      this.isLoading = false;
    },
    /** food_menu_stores を取得 */
    async getFoodMenuStores(poiGroupId: number) {
      this.isLoading = true;
      this.groups.forEach((g) => {
        g.stores = [];
        g.counts = { ready: 0, running: 0, success: 0, failure: 0, canceled: 0, modified: 0 };
      });
      let statuses = (await api.getFoodMenuStores(poiGroupId)) as Status[];
      // 登録済みでない店舗を除外する
      const idxdb = useIndexedDb();
      const stores: { [poiId: number]: EntitiesStore } = {};
      idxdb.stores.stores.forEach((s) => (stores[s.poiID] = s));
      statuses = statuses.filter((s) => !!stores[s.poiID] && stores[s.poiID].enabled);
      // canHaveFoodMenus が true でないものを除外するため locations.metadata を取得
      const accName = idxdb.company.gmbAccount;
      const locNames: string[] = statuses
        .map((s) => stores[s.poiID]?.gmbLocationID?.replace(/^accounts\/[^/]+\//, "") ?? "")
        .filter((s) => !!s);
      const metadatas: { [locName: string]: MybusinessbusinessinformationMetadata } = {};
      for (const loc of await api.getLocationInParallel(
        poiGroupId,
        accName,
        locNames,
        "name,metadata",
        100,
        10
      )) {
        metadatas[loc.location.name] = loc.location.metadata;
      }
      for (const store of statuses) {
        const cachedStore = idxdb.stores?.stores?.find((v) => v.poiID === store.poiID);
        if (cachedStore) {
          store.name = cachedStore.name;
          store.code = cachedStore.gmbStoreCode;
        }
        if (
          !store.foodMenuGroupID ||
          !metadatas[trimLocationName(cachedStore.gmbLocationID)]?.canHaveFoodMenus
        ) {
          continue;
        }
        for (const g of this.groups) {
          if (g.foodMenuGroupID === store.foodMenuGroupID) {
            if (store.currentTaskStatus === "READY") {
              g.counts.ready++;
            } else if (store.currentTaskStatus === "RUNNING") {
              g.counts.running++;
            } else if (store.currentTaskStatus === "SUCCESS") {
              g.counts.success++;
            } else if (store.currentTaskStatus === "FAILURE") {
              g.counts.failure++;
            } else if (store.currentTaskStatus === "CANCELED") {
              g.counts.canceled++;
            }
            if (store.isModified) {
              g.counts.modified++;
            }
            g.stores.push(store);
            break;
          }
        }
      }
      this.isLoading = false;
    },
  },
});
</script>
<style lang="scss" scoped>
@use "@/components/style/button.scss" as button;

.button-col {
  button:not(:last-child) {
    margin-right: 1rem;
  }
}
table.store-status {
  background-color: #ffffff;
  th,
  td,
  tbody,
  thead {
    border-width: 1px;
    border-color: #b3b3b3;
    border-style: solid;
  }
  th {
    background-color: #fafafa;
  }
  td {
    background-color: #ffffff;
  }
}
span.status-ready {
  color: #ee9d26;
  svg {
    color: #ee9d26;
  }
}
span.status-running {
  color: #42a4e4;
  svg {
    color: #42a4e4;
  }
}
span.status-success {
  color: #73c92d;
  svg {
    color: #73c92d;
  }
}
span.status-failure {
  color: #e95454;
  svg {
    color: #e95454;
  }
}
span.status-canceled {
  color: #757575;
  svg {
    color: #757575;
  }
}
</style>
