<template>
  <div>
    <v-container class="post-preview">
      <v-card :disabled="!step.enabled" :readonly="readonly" color="transparent" elevation="0">
        <v-form ref="form" v-model="isValidateForm">
          <!-- タイトル -->
          <v-row dense>
            <v-col>
              <v-textarea
                v-model="post.title"
                class="mt-n5"
                rows="1"
                auto-grow
                placeholder="タイトル"
                :disabled="!step.enabled"
                counter="50"
                variant="underlined"
                hide-details="auto"
                persistent-counter
                color="primary"
                :readonly="readonly"
              />
            </v-col>
          </v-row>
          <v-row class="information" dense>
            <v-col v-show="step.enabled">
              ※:画像の表示・本文の表示は店舗ページのデザインに依存します。タイトルは表示されません。
            </v-col>
          </v-row>
          <!-- 画像 -->
          <v-row v-if="files.length > 0" class="image-row">
            <v-hover v-slot="{ isHovering, props }">
              <v-col v-bind="props" class="image-box">
                <span v-show="isHovering && !isMobile && !readonly" class="edit-span">
                  <v-btn class="edit-button" flat rounded @click="editImage(files[0].state)">
                    <v-icon color="white" left>fas fa-cog</v-icon>
                    <span class="ml-2">編集</span>
                  </v-btn>
                </span>
                <v-img
                  v-for="file in files"
                  :key="file.imageUrl"
                  :src="file.imageUrl"
                  class="image"
                />
              </v-col>
            </v-hover>
          </v-row>
          <!-- 投稿リンク -->
          <v-row v-if="files.length > 0">
            <v-col>
              <v-text-field
                v-model="post.link"
                :placeholder="'投稿リンクを入力してください'"
                :disabled="!step.enabled"
                :rules="[rules.url]"
                variant="underlined"
                hide-details="auto"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
          </v-row>
          <!-- 本文 -->
          <v-row>
            <v-col>
              <v-textarea
                v-model="post.text"
                :placeholder="'本文を入力してください'"
                :disabled="!step.enabled"
                :rules="[rules.minLengthWithoutCounter(10), rules.maxLengthWithoutCounter(330)]"
                counter="330"
                rows="1"
                auto-grow
                variant="underlined"
                hide-details="auto"
                persistent-counter
                class="detail-text"
                color="primary"
                :readonly="readonly"
              />
            </v-col>
          </v-row>
          <v-row v-if="fileErrorMessages != ''" align="center" class="my-1">
            <p class="error-message">{{ fileErrorMessages }}</p>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script lang="ts">
import type { VForm } from "vuetify/lib/components/index.mjs";

import type { StepStatus } from "../post-stepper.vue";
import type { FileSelectionItem } from "@/models/v2-file-selection";
import type { FileSelectionState } from "@/models/v2-file-selection";
import { platformOfficialNames, sortFileSelectionItemList } from "@/models/v2-file-selection";
import { VuetifyValidator } from "@/helpers";
import { validateFile } from "../validator";
import { defineComponent } from "vue";
import { HostingPostForm } from "./hosting-request";

export default defineComponent({
  components: {},
  props: {
    modelValue: { type: Object as () => HostingPostForm, required: true }, // v-model
    baseText: { type: String, required: true },
    targetName: { type: String, required: true },
    fileSelections: { type: Array as () => FileSelectionItem[], required: true },
    step: { type: Object as () => StepStatus, required: true },
    isMobile: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "edit-image", "update-step"],
  data: () => ({
    post: new HostingPostForm(),
    files: [] as FileSelectionItem[],
    isValidateForm: false,
    fileErrorMessages: "",
    rules: VuetifyValidator,
  }),
  watch: {
    modelValue: {
      immediate: true,
      async handler(newval: HostingPostForm, oldval) {
        this.post.title = newval.title;
        this.post.text = newval.text;
        this.post.link = newval.link;
        await this.$nextTick();
        await (this.$refs.form as VForm)?.validate();
      },
    },
    isValidateForm: {
      handler() {
        this.isCompleted();
      },
    },
    baseText: {
      immediate: true,
      handler(newval: string, oldval) {
        // 共通テキストの変更で上書き
        this.post.text = this.baseText;
      },
    },
    fileSelections: {
      deep: true,
      immediate: true,
      async handler() {
        // 選択済みのファイルを順番通りにソートした上でfilesに格納する
        this.files = sortFileSelectionItemList(this.fileSelections);
        // 画像選択されたらプレビューを有効化する
        if (this.files.length > 0 && !this.$props.step.enabled) {
          this.$props.step.enabled = true;
        }
        await this.validateFiles();
      },
    },
    post: {
      deep: true,
      async handler() {
        await this.$nextTick();
        await (this.$refs.form as VForm)?.validate();
        await this.validateFiles();
        this.$emit("update:modelValue", this.post);
      },
    },
    step: {
      deep: true,
      immediate: true,
      async handler() {
        await this.validateFiles();
      },
    },
  },
  methods: {
    isCompleted(): void {
      this.$emit("update-step", {
        isComplete: this.isValidateForm && this.fileErrorMessages == "",
        errorMessage: `${platformOfficialNames.hosting}への投稿では${this.fileErrorMessages}`,
      });
    },
    async validateFiles(): Promise<void> {
      // vue 上でpropが変更されるのを待つ
      await this.$nextTick();
      if (!this.$props.step.enabled) {
        this.fileErrorMessages = "";
        return;
      }
      // 画像もテキストも無い
      if (this.files.length == 0 && this.post.text == "") {
        this.fileErrorMessages = "画像・動画を選択するか投稿内容の入力が必要です";
        this.$emit("update-step", {
          errorMessage: `${platformOfficialNames.hosting}への投稿では${this.fileErrorMessages}`,
        });
        this.isCompleted();
        return;
      }
      // 画像のサイズチェック
      var errorMessages: string[] = [];
      for (const file of this.files) {
        await validateFile(file, this.files.length, "hosting", errorMessages, true);
      }
      // 冗長なのでpreviewに表示するエラー文は短くする
      this.fileErrorMessages = errorMessages
        .map((m) => m.replace(platformOfficialNames.hosting, ""))
        .join("\n");
      this.$emit("update-step", { errorMessage: errorMessages.join("\n") });
      this.isCompleted();
    },
    editImage(order: FileSelectionState): void {
      // EditImageSelectedを作ってposts.vueのイベントを親コンポーネントを経由して発火させる
      this.$emit("edit-image", { platform: "hosting", order: order });
    },
  },
});
</script>

<style lang="scss" scoped>
.post-preview {
  max-width: 340px;
  padding: 0;
  margin-top: 12px;
  margin-bottom: 1rem;
}

.row {
  margin-top: 0 !important;
  margin-bottom: 0;
}

.preview-row {
  margin: 0;
  padding: 0 16px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 2px 3px #00000029;
  border-radius: 6px;
  opacity: 1;
}

.detail-text {
  padding-top: 0;
  border: #707070;
  font-size: 0.8em;
}

.error-message {
  color: red;
  font-size: 0.75rem;
  white-space: pre-wrap;
  padding-left: 1rem;
}

.information {
  color: red;
  font-size: 0.7rem;
}

.image-box {
  margin: 0 !important;
  position: relative;
}

.edit-span {
  position: absolute;
  top: 1.2rem;
  width: 95%;
  text-align: center;
  z-index: var(--z-index-loading);
}

.edit-button {
  background-color: rgba(0, 0, 0, 0.54) !important;
  color: white !important;
  border: none;
}
</style>
