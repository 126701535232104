<template>
  <div class="d-flex flex-column p-2">
    <h3 class="text-h5 font-weight-bold mb-2">{{ title }}</h3>
    <v-data-table
      :headers="headers"
      :items="items"
      :page="page"
      :items-per-page="10"
      hide-default-footer
      class="ranking-table"
    >
      <template #item="{ item }">
        <tr class="height: 0">
          <td class="rank">{{ item.index }}</td>
          <td v-if="item.index <= 3" class="name top">{{ item.name }}</td>
          <td v-else class="name">{{ item.name }}</td>
          <td class="value">
            <span v-if="orderBy === 'count'">{{ item.count }}</span>
            <span v-else>{{ fraction2(item.average, "-") }}</span>
          </td>
        </tr>
      </template>
      <template #no-data>
        <tr v-if="items.length === 0">
          <td colspan="3">指定された条件にヒットするデータなし</td>
        </tr>
      </template>
    </v-data-table>
    <!-- ページネーション -->
    <v-pagination
      v-model="page"
      :length="Math.floor(items.length / 10) + 1"
      total-visible="10"
      density="compact"
      class="mt-auto"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { VDataTable } from "vuetify/components/VDataTable";
import type { DomainReviewsRatingRanking } from "@/types/ls-api";

type Header = Pick<
  VDataTable["$props"]["headers"][number],
  "key" | "title" | "align" | "width" | "sortable"
>;
export type Datum = {
  index: number;
  name: string;
} & DomainReviewsRatingRanking["rankingItems"][number];

export default defineComponent({
  props: {
    title: { type: String, default: "" },
    orderBy: { type: String, required: true }, // "count" | "average"
    data: { type: Array as PropType<Datum[]>, required: true },
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    headers(): Header[] {
      const headers: Header[] = [
        { title: "順位", align: "center", key: "index", sortable: true, width: "5em" },
        { title: "店舗名", align: "center", key: "name", sortable: true, width: "" },
      ];
      const value: Header =
        this.orderBy === "count"
          ? { title: "件数", align: "center", key: "count", sortable: true, width: "5em" }
          : { title: "平均", align: "center", key: "average", sortable: true, width: "5em" };
      headers.push(value);
      return headers;
    },
    items(): Datum[] {
      const items = this.data.map((d) => ({ ...d }));
      // 並び替える
      if (this.orderBy === "count") {
        // ORDER BY count DESC, average DESC, name ASC
        items.sort((a, b) => {
          const d1 = b.count - a.count;
          if (d1 < 0 || 0 < d1) return d1;
          const d2 = b.average - a.average;
          if (d2 < 0 || 0 < d2) return d2;
          return a.name.localeCompare(b.name);
        });
      } else {
        // ORDER BY average DESC, count DESC, name ASC
        items.sort((a, b) => {
          const d1 = b.average - a.average;
          if (d1 < 0 || 0 < d1) return d1;
          const d2 = b.count - a.count;
          if (d2 < 0 || 0 < d2) return d2;
          return a.name.localeCompare(b.name);
        });
      }
      // index をつける
      items.forEach((item, i) => (item.index = i + 1));
      return items;
    },
  },
  mounted() {},
  methods: {
    fraction2(value: number, defaultValue: string): string {
      if (isNaN(value)) {
        return defaultValue;
      }
      return Intl.NumberFormat("ja-JP", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
  },
});
</script>

<style lang="scss" scoped>
%arrow {
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
  background-size: 8px 12px;
}
:deep(.ranking-table) {
  table {
    min-width: 300px;
    border-width: 1px;
    border-style: solid;
    border-color: #d6d6d6;
    border-collapse: collapse;
    border-radius: 0;
    th {
      position: relative;
      height: 0 !important;
      text-align: center !important;
      vertical-align: middle;
      font: normal normal bold 14px/18px sans-serif;
      letter-spacing: 0px;
      color: #404040;
      background: #f6f6f6 0% 0% no-repeat padding-box;
      padding: 5px 0 !important;
      border-width: 1px;
      border-style: solid;
      border-color: #d6d6d6;
      i {
        display: none;
      }
      &:not(.v-data-table__th--sorted).v-data-table__th--sortable {
        // TM-6940 デザイン再現の為に画像でやる必要がある
        background-image: url("@/assets/images/arrow_up_down.svg");
        @extend %arrow;
      }
      &.v-data-table__th--sorted:has(i.mdi-arrow-up) {
        // TM-6940 デザイン再現の為に画像でやる必要がある
        background-image: url("@/assets/images/arrow_up_active.svg");
        @extend %arrow;
      }
      &.v-data-table__th--sorted:has(i.mdi-arrow-down) {
        // TM-6940 デザイン再現の為に画像でやる必要がある
        background-image: url("@/assets/images/arrow_down_active.svg");
        @extend %arrow;
      }
    }

    td {
      height: 40px !important;
      padding: 5px 15px !important;
      vertical-align: middle;
      font: normal normal 14px/20px sans-serif;
      border-width: 0;
      border-bottom: none !important;
      border-color: #d6d6d6;
      &.rank {
        text-align: right;
      }
      &.name {
        border-width: 0 1px;
        border-style: dashed;
        font-weight: bold;
        color: #68cbb6;
        &.top {
          font: normal bold 20px/29px sans-serif;
        }
      }
      &.value {
        text-align: right;
      }
    }
  }
}
</style>
