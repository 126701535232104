<template>
  <div>
    <v-card>
      <v-row class="my-1 mx-1">
        <v-col>
          <div class="text-h6 font-weight-bold">ユーザー管理／一覧</div>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <input ref="importInput" type="file" hidden @change="readXlsx" />
          <v-btn
            class="primary"
            prepend-icon="mdi-upload"
            text="XLSXインポート"
            @click="$refs.importInput.click()"
          />
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="primary"
            prepend-icon="mdi-download"
            text="XLSXエクスポート"
            @click="exportXlsx"
          />
        </v-col>
        <v-col cols="auto">
          <v-btn
            id="openUserForm"
            color="primary"
            variant="outlined"
            :text="dict.userFormButtonLabel"
            @click="$router.push({ name: 'UsersNew' })"
          >
            <template #prepend>
              <v-icon color="primary">fas fa-plus</v-icon>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-3 mb-1" justify="start">
      <v-col cols="auto">
        <v-text-field
          v-model="searchWord"
          data-testid="search-word"
          label="検索キーワード"
          variant="underlined"
          density="compact"
          single-line
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          color="primary"
          class="mb-1"
          style="width: 400px; max-width: 400px"
          @keypress.enter="searchUsers"
          @click:clear="
            searchWord = '';
            searchUsers();
          "
        >
          <template #append>
            <v-btn
              data-testid="filter-by-search-word"
              color="primary"
              size="small"
              text="絞り込む"
              @click="searchUsers"
            >
              <template #prepend>
                <v-icon>fas fa-filter</v-icon>
              </template>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col class="ml-2 mt-1" align-self="center">
        <span style="white-space: nowrap">{{ userRecord.length }}/{{ users.length }} 件表示中</span>
      </v-col>
    </v-row>
  </div>
  <div v-if="users.length === 0 && !loading">{{ dict.result_message_loading }}</div>
  <div v-else class="custom-grid">
    <c-grid
      ref="cgrid"
      :frozen-col-count="1"
      style="font-size: small"
      class="text-sm-left cgrid"
      :font="gridFont"
      :theme="customTheme"
      :allow-range-paste="false"
      :delete-cell-value-on-del-key="true"
      :move-cell-on-tab-key="true"
      :move-cell-on-enter-key="true"
      :select-all-on-ctrl-a-key="true"
      :data="userRecord"
    >
      <c-grid-input-column field="email" width="300" :readonly="true" :sort="sortColumn">
        {{ dict.email.title }}
      </c-grid-input-column>
      <c-grid-input-column field="familyName" width="150" :readonly="true" :sort="sortColumn">
        {{ dict.familyName.title }}
      </c-grid-input-column>
      <c-grid-input-column field="firstName" width="150" :readonly="true" :sort="sortColumn">
        {{ dict.firstName.title }}
      </c-grid-input-column>
      <c-grid-input-column field="roleName" width="200" :readonly="true" :sort="sortColumn">
        {{ dict.role.title }}
      </c-grid-input-column>
      <c-grid-input-column field="group" width="200" :readonly="true" :sort="sortColumn">
        {{ dict.areas.title }}
      </c-grid-input-column>
      <c-grid-input-column field="store" width="200" :readonly="true" :sort="sortColumn">
        {{ dict.stores.title }}
      </c-grid-input-column>
      <c-grid-check-column v-if="isMFASetting" field="mfaEnabled" width="50" :readonly="true">
        {{ dict.mfa.title }}
      </c-grid-check-column>
      <c-grid-button-column
        width="100"
        field="editCaption"
        :column-style="{ textAlign: 'center' }"
        @click="editUser"
      ></c-grid-button-column>
      <c-grid-button-column
        width="100"
        field="deleteCaption"
        :disabled="(row) => isMyself(row)"
        :column-style="
          (row) =>
            isMyself(row)
              ? { textAlign: 'center', buttonBgColor: 'transparent' }
              : { textAlign: 'center' }
        "
        @click="deleteConfirm"
      ></c-grid-button-column>
    </c-grid>
    <!-- インポート確認ダイアログ -->
    <v-dialog :model-value="confirmDialog.show" persistent width="800" scrollable>
      <v-card>
        <template #title>反映内容の確認</template>
        <template #text>
          <div class="text-h5">追加するユーザ</div>
          <v-data-table
            :headers="[{ title: 'email', key: 'mailAddress' }]"
            :items="confirmDialog.inserts"
            no-data-text="追加はありません"
            density="compact"
            hide-default-footer
          >
            <template #item="{ item }">
              <tr>
                <td class="font-weight-bold" :colspan="4">{{ item.mailAddress }}</td>
              </tr>
            </template>
          </v-data-table>

          <div class="text-h5 mt-3">更新するユーザ</div>
          <v-data-table
            :headers="[
              { title: 'email', key: 'mail' },
              { title: '変更項目', key: 'col' },
              { title: '変更前', key: '' },
              { title: '変更後', key: '' },
            ]"
            :items="confirmDialog.updateDiffs"
            no-data-text="更新はありません"
            density="compact"
            hide-default-footer
          >
            <template #item="{ item }">
              <tr>
                <td class="font-weight-bold" :colspan="4">{{ item.mail }}</td>
              </tr>
              <tr v-for="(diff, index) in item.diffs" :key="index">
                <td></td>
                <td>{{ diff.col }}</td>
                <td>{{ diff.a }}</td>
                <td>{{ diff.b }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
        <template #actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="confirmDialog.show = false">キャンセル</v-btn>
          <v-btn
            color="primary"
            variant="elevated"
            @click="
              confirmDialog.show = false;
              importXlsx();
            "
          >
            反映処理を開始
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
    <!-- 反映ダイアログ -->
    <ProgressDialog
      v-model="importDialog.show"
      title="XLSXインポート"
      :message="importDialog.message"
      :percentage="importDialog.percentage"
      submit-button="OK"
      :submit-button-disabled="importDialog.submitButtonDisabled"
      @submit="importDialog.show = false"
    />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
