import dayjs from "dayjs";
import { apiBase } from "@/const";
import { requiredAuth } from "@/helpers";

import type { DomainReviewsRatingSummary, DomainReviewsRatingRanking } from "@/types/ls-api";
import { type SelectedTarget } from "@/components/shared/store-selector.vue";

export const api = { getRatingSummary, getRatingRanking };

/**
 * 全期間の評価サマリーを取得する
 * @param poiGroupId 企業ID
 * @param target SelectedTarget
 * @returns 全期間の評価サマリー
 */
async function getRatingSummary(
    poiGroupId: number,
    target: SelectedTarget
): Promise<DomainReviewsRatingSummary> {
    const url = `${apiBase}/companies/${poiGroupId}/reviews/rating-summary`;
    const params = {};
    if (target.isAll) {
        // パラメータなしなら全店舗
    } else if (target.isArea && target.areaId > -1) {
        params["areaID"] = target.areaId;
    } else if (target.poiIds.length > 0) {
        params["poiID"] = target.poiIds[0];
    }
    const res = await requiredAuth<DomainReviewsRatingSummary>("get", url, params);
    return res?.data ?? {};
}

/**
 * 評価ランキングを取得する
 * @param poiGroupId 企業ID
 * @param target SelectedTarget
 * @param from 開始月文字列 YYYY-MM
 * @param to 終了月文字列 YYYY-MM
 * @returns 評価ランキング
 */
async function getRatingRanking(
    poiGroupId: number,
    target: SelectedTarget,
    from: string,
    to: string
): Promise<DomainReviewsRatingRanking> {
    const url = `${apiBase}/companies/${poiGroupId}/reviews/rating-ranking`;
    const params = {};
    if (target.isAll) {
        // パラメータなしなら全店舗
    } else if (target.isArea && target.areaId > -1) {
        params["areaID"] = target.areaId;
    } else if (target.poiIds.length > 0) {
        params["poiID"] = target.poiIds[0];
    }
    if (from !== "" && to !== "") {
        const dfrom = dayjs(from, "YYYY-MM");
        const dto = dayjs(to, "YYYY-MM");
        params["startYear"] = dfrom.year();
        params["startMonth"] = dfrom.month() + 1;
        params["endYear"] = dto.year();
        params["endMonth"] = dto.month() + 1;
    }
    const res = await requiredAuth<DomainReviewsRatingRanking>("get", url, params);
    return res?.data ?? {};
}
