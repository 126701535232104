import type { EntitiesV2HostingPostData } from "@/types/ls-api";

export class HostingPostForm {
    title: string;
    text: string;
    link: string;

    constructor(title: string = "", text: string = "", link: string = "") {
        this.title = title;
        this.text = text;
        this.link = link;
    }

    convertFrom(postData: EntitiesV2HostingPostData) {
        this.title = postData.title;
        this.text = postData.text;
        this.link = postData.link;
    }

    convertTo(): EntitiesV2HostingPostData {
        return {
            title: this.title,
            text: this.text,
            link: this.link,
        };
    }
}
