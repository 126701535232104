import type {
    EntitiesStoresResponse,
    EntitiesLocalBusinessInfo,
    EntitiesDepartment,
    EntitiesRival,
    MybusinessbusinessinformationAttribute,
    MybusinessbusinessinformationLocation,
    MybusinessbusinessinformationMoreHours,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { localBusinessTypeHierarchy } from "@/const";
import { flattenMoreHours, getRegularHour, getSpecialHour } from "./business-hours";

type Attribute = MybusinessbusinessinformationAttribute[];

export type GmbLocationInfo = EntitiesStoresResponse["stores"][number]["gmbLocation"];

/** Handsontableに食わせるためにGmbLocationを平坦なオブジェクトにしたもの */
export interface FlattenedLocation {
    name: string;
    locationState: string;
    storeCode: string;
    title: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    address5: string;
    prefecture: string;
    regionCode: string;
    zipCode: string;
    latitude: number | "";
    longitude: number | "";
    primaryPhone: string;
    additionalPhones: string;
    webSite: string;
    primaryCategory: string;
    additionalCategories: string;
    businessHoursSun: string;
    businessHoursMon: string;
    businessHoursTue: string;
    businessHoursWed: string;
    businessHoursThu: string;
    businessHoursFri: string;
    businessHoursSat: string;
    businessHoursSpecial: string;
    moreHours: { [name: string]: string };
    description: string;
    openingDate: string;
    openStatus: string;
    labels: string;
    adPhones: string;
    attributes: { [name: string]: string | boolean };
    paymentAccepted: string;
    currenciesAccepted: string;
    priceRange: string;
    areaServed: string;
    maximumAttendeeCapacity: string;
    smokingAllowed: string;
    brandName: string;
    brandLogo: string;
    brandSlogan: string;
    department: string;
    primaryType: string;
    subType: string;
    rivalName: string;
    rivalKeyword: string;
}
export function flattenLocation(
    l: GmbLocationInfo,
    att: MybusinessbusinessinformationAttribute[],
    mhs: MybusinessbusinessinformationMoreHours[],
    i: EntitiesLocalBusinessInfo,
    r: EntitiesRival[],
    categoryNameToDisplayNameMap: { [name: string]: string }
): FlattenedLocation {
    // 店舗のステータス
    const ls = wordDictionary.storeLocation.normal;
    // TODO: Metadataに情報が足りない
    // if (l?.locationState) {
    //     const els: EntitiesLocationState = l.locationState;
    //     if (els.hasPendingEdits) {
    //         // 審査中
    //         ls = wordDictionary.storeLocation.hasPendingEdits;
    //     } else if (els.isDisabled) {
    //         // 無効
    //         ls = wordDictionary.storeLocation.disabled;
    //     } else if (els.isDuplicate) {
    //         // 重複
    //         ls = wordDictionary.storeLocation.double;
    //     } else if (els.isDisconnected) {
    //         // オーナー確認が必要
    //         ls = wordDictionary.storeLocation.ownerConfirmation;
    //     }
    // }
    const fl: FlattenedLocation = {
        name: l.location.name,
        locationState: ls,
        storeCode: l.location.storeCode ?? "",
        title: l.location.title ?? "",
        address1: getAddressLine(l.location.storefrontAddress, 0),
        address2: getAddressLine(l.location.storefrontAddress, 1),
        address3: getAddressLine(l.location.storefrontAddress, 2),
        address4: getAddressLine(l.location.storefrontAddress, 3),
        address5: getAddressLine(l.location.storefrontAddress, 4),
        prefecture: l.location.storefrontAddress?.administrativeArea ?? "",
        regionCode: l.location.storefrontAddress?.regionCode ?? "",
        zipCode: l.location.storefrontAddress?.postalCode ?? "",
        latitude: (l.location.latlng?.latitude ?? "") as number | "",
        longitude: (l.location.latlng?.longitude ?? "") as number | "",
        primaryPhone: l.location.phoneNumbers.primaryPhone ?? "",
        additionalPhones: (l.location.phoneNumbers.additionalPhones ?? []).join("|"),
        webSite: l.location.websiteUri ?? "",
        primaryCategory:
            categoryNameToDisplayNameMap[l.location.categories.primaryCategory?.name] ?? "",
        additionalCategories: (l.location.categories.additionalCategories ?? [])
            .map((c) => categoryNameToDisplayNameMap[c.name])
            .join("|"),
        description: l.location.profile?.description ?? "",
        openingDate: getOpeningDate(l.location.openInfo),
        openStatus: getOpenStatusName(l.location.openInfo?.status),
        labels: (l.location.labels ?? []).join("|"),
        adPhones: l.location.adWordsLocationExtensions?.adPhone ?? "",
        // 営業時間タブの情報
        businessHoursSun: getRegularHour(l.location.regularHours, "SUNDAY"),
        businessHoursMon: getRegularHour(l.location.regularHours, "MONDAY"),
        businessHoursTue: getRegularHour(l.location.regularHours, "TUESDAY"),
        businessHoursWed: getRegularHour(l.location.regularHours, "WEDNESDAY"),
        businessHoursThu: getRegularHour(l.location.regularHours, "THURSDAY"),
        businessHoursFri: getRegularHour(l.location.regularHours, "FRIDAY"),
        businessHoursSat: getRegularHour(l.location.regularHours, "SATURDAY"),
        businessHoursSpecial: getSpecialHour(l.location.specialHours),
        moreHours: flattenMoreHours(mhs ?? []),
        // 店舗属性タブの情報
        attributes: flattenAttributes(att ?? []),
        // 構造化用情報タブの情報
        paymentAccepted: i?.paymentAccepted ?? "",
        currenciesAccepted: i?.currenciesAccepted ?? "",
        priceRange: i?.priceRange ?? "",
        areaServed: i?.areaServed ?? "",
        maximumAttendeeCapacity: (i?.maximumAttendeeCapacity ?? "").toString(),
        smokingAllowed: i?.smokingAllowed == null || i?.smokingAllowed === false ? "x" : "o",
        brandName: i?.brand.name ?? "",
        brandLogo: i?.brand.logo ?? "",
        brandSlogan: i?.brand.slogan ?? "",
        department: getDepartment(i?.department),
        primaryType: getStructType(i?.type)[0] ?? "",
        subType: getStructType(i?.type)[1] ?? "",
        // 競合店舗タブの情報
        rivalName: r?.[0]?.name ?? "",
        rivalKeyword: r?.[0]?.keyword ?? "",
    };
    // 文字列中の改行コードをLFにする
    for (const key of Object.keys(fl)) {
        const val = fl[key];
        if (typeof val === "string") {
            fl[key] = val.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
        }
    }
    return fl;
}
function flattenAttributes(attrs: Attribute): { [key: string]: string } {
    const fl: { [key: string]: string } = {};
    for (const a of attrs) {
        if (a.valueType === "BOOL") {
            fl[a.name] = a.values.length === 0 ? "" : a.values[0] ? "o" : "x";
        } else if (a.valueType === "ENUM") {
            fl[a.name] = a.values.length === 0 ? "" : (a.values[0] as string);
        } else if (a.valueType === "REPEATED_ENUM") {
            for (const v of a.repeatedEnumValue?.setValues || []) {
                fl[`${a.name}-${v}`] = "o";
            }
            for (const v of a.repeatedEnumValue?.unsetValues || []) {
                fl[`${a.name}-${v}`] = "x";
            }
        } else if (a.valueType === "URL") {
            // urlValuesが複数存在するときは、改行で区切って表示する
            fl[a.name] = (a.uriValues ?? [])
                .map((uv) => {
                    return uv.uri ?? "";
                })
                .join("\n");
        }
    }
    return fl;
}
/**
 * FlattenedLocation の中の GmbLocation の情報を上書きする
 * この関数は flattenLocation の「店舗情報タブの情報」「店舗属性タブの情報」の部分と同じである。
 */
export function updateFlattenedLocation(
    fl: FlattenedLocation,
    l: MybusinessbusinessinformationLocation,
    a: MybusinessbusinessinformationAttribute[],
    m: MybusinessbusinessinformationMoreHours[],
    categoryNameToDisplayNameMap: { [name: string]: string }
): void {
    if (l !== null) {
        fl.name = l.name;
        // TODO: metadaにpendingEditsがない
        // fl.locationState = l?.locationState?.hasPendingEdits
        //     ? wordDictionary.storeLocation.hasPendingEdits
        //     : "";
        fl.storeCode = l.storeCode ?? "";
        fl.title = l.title ?? "";
        fl.address1 = getAddressLine(l.storefrontAddress, 0);
        fl.address2 = getAddressLine(l.storefrontAddress, 1);
        fl.address3 = getAddressLine(l.storefrontAddress, 2);
        fl.address4 = getAddressLine(l.storefrontAddress, 3);
        fl.address5 = getAddressLine(l.storefrontAddress, 4);
        fl.prefecture = l.storefrontAddress?.administrativeArea ?? "";
        fl.regionCode = l.storefrontAddress?.regionCode ?? "";
        fl.zipCode = l.storefrontAddress?.postalCode ?? "";
        fl.latitude = (l.latlng?.latitude ?? "") as number | "";
        fl.longitude = (l.latlng?.longitude ?? "") as number | "";
        fl.primaryPhone = l.phoneNumbers.primaryPhone ?? "";
        fl.additionalPhones = (l.phoneNumbers.additionalPhones ?? []).join("|");
        fl.webSite = l.websiteUri ?? "";
        fl.primaryCategory = categoryNameToDisplayNameMap[l.categories.primaryCategory?.name] ?? "";
        fl.additionalCategories = (l.categories.additionalCategories ?? [])
            .map((c) => categoryNameToDisplayNameMap[c.name])
            .join("|");
        fl.businessHoursSun = getRegularHour(l.regularHours, "SUNDAY");
        fl.businessHoursMon = getRegularHour(l.regularHours, "MONDAY");
        fl.businessHoursTue = getRegularHour(l.regularHours, "TUESDAY");
        fl.businessHoursWed = getRegularHour(l.regularHours, "WEDNESDAY");
        fl.businessHoursThu = getRegularHour(l.regularHours, "THURSDAY");
        fl.businessHoursFri = getRegularHour(l.regularHours, "FRIDAY");
        fl.businessHoursSat = getRegularHour(l.regularHours, "SATURDAY");
        fl.businessHoursSpecial = getSpecialHour(l.specialHours);
        fl.moreHours = flattenMoreHours(m ?? []);
        fl.description = l.profile?.description ?? "";
        fl.openingDate = getOpeningDate(l.openInfo);
        fl.labels = (l.labels ?? []).join("|");
        fl.adPhones = l.adWordsLocationExtensions?.adPhone ?? "";
    }
    if (a !== null) {
        // 店舗属性タブの情報
        fl.attributes = flattenAttributes(a ?? []);
    }
}

export const diffMasks: { [data in keyof FlattenedLocation]: string } = {
    name: "name",
    locationState: "locationState",
    storeCode: "storeCode",
    title: "title",
    address1: "storefrontAddress.addressLines",
    address2: "storefrontAddress.addressLines",
    address3: "storefrontAddress.addressLines",
    address4: "storefrontAddress.addressLines",
    address5: "storefrontAddress.addressLines",
    prefecture: "storefrontAddress.administrativeArea",
    regionCode: "storefrontAddress.regionCode",
    zipCode: "storefrontAddress.postalCode",
    latitude: "latlng.latitude",
    longitude: "latlng.longitude",
    primaryPhone: "phoneNumbers.primaryPhone",
    additionalPhones: "phoneNumbers.additionalPhones",
    webSite: "websiteUri",
    primaryCategory: "categories.primaryCategory",
    additionalCategories: "categories.additionalCategories",
    businessHoursSun: "regularHours",
    businessHoursMon: "regularHours",
    businessHoursTue: "regularHours",
    businessHoursWed: "regularHours",
    businessHoursThu: "regularHours",
    businessHoursFri: "regularHours",
    businessHoursSat: "regularHours",
    businessHoursSpecial: "specialHours",
    moreHours: "moreHours",
    description: "profile.description",
    openingDate: "openInfo.openingDate",
    openStatus: "openInfo.status",
    labels: "labels",
    adPhones: "adWordsLocationExtensions.adPhone",
    attributes: "attributes",
    paymentAccepted: "paymentAccepted",
    currenciesAccepted: "currenciesAccepted",
    priceRange: "priceRange",
    areaServed: "areaServed",
    maximumAttendeeCapacity: "maximumAttendeeCapacity",
    smokingAllowed: "smokingAllowed",
    brandName: "brandName",
    brandLogo: "brandLogo",
    brandSlogan: "brandSlogan",
    department: "department",
    primaryType: "primaryType",
    subType: "subType",
    rivalName: "rivalName",
    rivalKeyword: "rivalKeyword",
};

interface RowIndex {
    rownum: number;
    poiID: number;
    readOnly: boolean;
    structuredPageID: string;
}

export type Row = FlattenedLocation & RowIndex;

export interface Category {
    displayName: string;
    categoryId: string;
}

function getAddressLine(
    a: GmbLocationInfo["location"]["storefrontAddress"],
    index: number
): string {
    return a?.addressLines?.[index] ?? "";
}

function getOpeningDate(x: GmbLocationInfo["location"]["openInfo"]): string {
    const d = x?.openingDate;
    if (!d || d.month === 0) {
        return "";
    }
    if (d.day) {
        return `${zeropad(d.year, 4)}-${zeropad(d.month, 2)}-${zeropad(d.day, 2)}`;
    } else {
        return `${zeropad(d.year, 4)}-${zeropad(d.month, 2)}`;
    }
}
const openStatusMap = new Map(
    wordDictionary.storeLocationForm.openStatuses.map((x) => [x.value, x.name])
);
function getOpenStatusName(openStatus: string): string {
    return openStatusMap.get(openStatus);
}
function zeropad(n: number, length: number): string {
    return `${n}`.padStart(length, "0");
}

const dayOfWeek = wordDictionary.storeLocationForm.weekdays;
const dayOfWeekArray = Object.keys(dayOfWeek).map(function (key) {
    return dayOfWeek[key];
});

// 場所ごとの営業時間
export function getDepartment(departments: EntitiesDepartment[]): string {
    departments = departments ?? [];
    return departments
        .map((d) => {
            const spec = d.openingHoursSpecification[0];
            const time = `${(spec.opens ?? "").slice(0, 5)}-${(spec.closes ?? "").slice(0, 5)}`;
            return `${d.name ?? ""}|${time}|${(spec.dayOfWeek ?? [])
                .map((w) => dayOfWeek[w.toUpperCase()])
                .sort((a, b) => {
                    return dayOfWeekArray.indexOf(a) > dayOfWeekArray.indexOf(b) ? 1 : -1;
                })
                .join(",")}`;
        })
        .join("\n");
}

export function getStructType(structType: string): string[] {
    // Typeからカテゴリ・サブカテゴリを特定
    let cat = "";
    let subcat = "";

    typeLoop: for (const lbt of localBusinessTypeHierarchy) {
        cat = lbt.name;
        subcat = "";
        if (lbt.name == structType) {
            break typeLoop;
        }

        for (const nested of lbt.subcategories) {
            if (nested.name == structType) {
                subcat = nested.name;
                break typeLoop;
            }
        }
        cat = "";
    }

    return [cat, subcat];
}

export function getRivals(rivals: EntitiesRival[]): string {
    if (rivals == null) {
        return "|";
    }
    return rivals.map((r) => `${r?.name ?? ""}|${r?.keyword ?? ""}`).join("\n");
}
