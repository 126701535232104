import type { EntitiesAppleLocation, EntitiesAppleError } from "@/types/ls-api";

/**
 * Apple Business Connect Locationからcompany IDとlocation IDを結合した文字列を取得する
 * @param location Apple Business Connect Location
 * @returns company ID and location IDを結合した文字列
 */
export function getAppleLocationID(location: EntitiesAppleLocation): string {
    return `companies/${location.companyId}/locations/${location.id}`;
}

/**
 * Errorリストからエラーメッセージのリストを生成して返す
 * @param error ABCのErrorリスト
 * @returns エラーメッセージのリスト
 */
export function getErrorMessageList(error: EntitiesAppleError[]): string[] {
    const errorMsgList: string[] = [];
    if (error != null) {
        error.forEach((e) => {
            const errorMsg = `${e.code}, ${e.message}`;
            errorMsgList.push(errorMsg);
        });
    }
    return errorMsgList;
}
