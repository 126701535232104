import { equals, sort, uniq } from "@/helpers/arrays";
import type { SelectItem } from "@/helpers/select-items";
import type { EntitiesUser, EntitiesRole } from "@/types/ls-api";

// ヘッダー名と必須かどうかのマップ
const cols = {
    mailAddress: { title: "メールアドレス", required: true },
    familyName: { title: "姓", required: true },
    firstName: { title: "名", required: true },
    role: { title: "権限", required: true },
    areas: { title: "担当グループ", required: false },
    stores: { title: "担当店舗", required: false },
    subscriptionAreas: { title: "クチコミアラート通知許可グループ", required: false },
    subscriptionStores: { title: "クチコミアラート通知許可店舗", required: false },
    locationUpdateNotificationEnabled: { title: "Google更新通知のON/OFF", required: true },
    useMFA: { title: "MFA認証を有効化", required: true },
};
/** XLSXに表示するデータを作成する */
export function makeXlsxData(
    users: EntitiesUser[],
    mroles: EntitiesRole[], // 権限マスタ
    mareas: SelectItem[], // エリアマスタ
    mstores: SelectItem[], // 店舗マスタ
    msareas: SelectItem[], // クチコミオプションが有効な店舗を含むエリアマスタ
    msstores: SelectItem[] // クチコミオプションが有効な店舗マスタ
): string[][][] {
    // --- users シート作成 ---
    type V = { a: number[]; s: number[]; sa: number[]; ss: number[] }; // 可変長の項目を持つオブジェクト
    const vs: V[] = [];
    for (const user of users) {
        const v: V = {
            a: user.areas ?? [],
            s: user.stores ?? [],
            sa: user.reviewSubscriptions?.areas ?? [],
            ss: user.reviewSubscriptions?.stores ?? [],
        };
        // マスタに存在しないIDを削除して uniq して sort する
        v.a = sort(uniq(v.a.filter((id) => mareas.some((x) => x.id === id))));
        v.s = sort(uniq(v.s.filter((id) => mstores.some((x) => x.id === id))));
        v.sa = sort(uniq(v.sa.filter((id) => msareas.some((x) => x.id === id))));
        v.ss = sort(uniq(v.ss.filter((id) => msstores.some((x) => x.id === id))));
        vs.push(v);
    }
    // 各項目の最大長を調べ、ダミー値を追加して最大長に長さを揃える
    const maxA = Math.max(1, ...vs.map((x) => x.a.length));
    const maxS = Math.max(1, ...vs.map((x) => x.s.length));
    const maxSa = Math.max(1, ...vs.map((x) => x.sa.length));
    const maxSs = Math.max(1, ...vs.map((x) => x.ss.length));
    const DUMMY = Number.MAX_SAFE_INTEGER;
    for (const v of vs) {
        v.a = v.a.concat(Array(maxA - v.a.length).fill(DUMMY));
        v.s = v.s.concat(Array(maxS - v.s.length).fill(DUMMY));
        v.sa = v.sa.concat(Array(maxSa - v.sa.length).fill(DUMMY));
        v.ss = v.ss.concat(Array(maxSs - v.ss.length).fill(DUMMY));
    }
    // ヘッダー行を作成
    const header = [
        cols.mailAddress.title,
        cols.familyName.title,
        cols.firstName.title,
        cols.role.title,
        ...Array(maxA).fill(cols.areas.title),
        ...Array(maxS).fill(cols.stores.title),
        ...Array(maxSa).fill(cols.subscriptionAreas.title),
        ...Array(maxSs).fill(cols.subscriptionStores.title),
        cols.locationUpdateNotificationEnabled.title,
        cols.useMFA.title,
    ];
    const rows: string[][] = [header];
    for (let i = 0; i < users.length; i++) {
        const user = users[i];
        const v = vs[i];
        const row = [
            user.mailAddress,
            user.familyName,
            user.firstName,
            mroles.find((role) => role.roleLv === user.roleLv)?.caption ?? "",
            ...v.a.map((id) => mareas.find((x) => x.id === id)?.title ?? ""),
            ...v.s.map((id) => mstores.find((x) => x.id === id)?.title ?? ""),
            ...v.sa.map((id) => msareas.find((x) => x.id === id)?.title ?? ""),
            ...v.ss.map((id) => msstores.find((x) => x.id === id)?.title ?? ""),
            user.locationUpdateNotificationEnabled ? "ON" : "OFF",
            user.useMFA ? "ON" : "OFF",
        ];
        rows.push(row);
    }
    // --- master シート作成 ---
    const mheader = [
        cols.role.title,
        cols.areas.title,
        cols.stores.title,
        cols.subscriptionAreas.title,
        cols.subscriptionStores.title,
    ];
    const max = Math.max(
        mroles.length,
        mareas.length,
        mstores.length,
        msareas.length,
        msstores.length
    );
    const mrows: string[][] = [mheader];
    for (let i = 0; i < max; i++) {
        const row = [
            mroles[i]?.caption ?? "",
            mareas[i]?.title ?? "",
            mstores[i]?.title ?? "",
            msareas[i]?.title ?? "",
            msstores[i]?.title ?? "",
        ];
        mrows.push(row);
    }
    return [rows, mrows];
}

/** XLSXから読み取った情報を元に、ユーザエンティティの配列を作成する */
export function makeImportUserData(
    rows: string[][],
    mroles: EntitiesRole[], // 権限マスタ
    mareas: SelectItem[], // エリアマスタ
    mstores: SelectItem[], // 店舗マスタ
    mrareas: SelectItem[], // クチコミオプションが有効な店舗を含むエリアマスタ
    mrstores: SelectItem[] // クチコミオプションが有効な店舗マスタ
): [EntitiesUser[], string[]] {
    const errors: string[] = [];
    const headers = rows[0];
    // 必須のヘッダーの存在と重複をチェック
    for (const { title, required } of Object.values(cols)) {
        if (!required) continue;
        if (!headers.includes(title)) {
            errors.push(`ヘッダー "${title}" は必須です。`);
        } else if (headers.filter((header) => header === title).length >= 2) {
            errors.push(`ヘッダー "${title}" は重複しています。`);
        }
    }
    // ヘッダー名が正しいかチェックしつつ、各列のインデックスを作成
    const index = Object.fromEntries(Object.keys(cols).map((colname) => [colname, []])) as {
        [colname in keyof typeof cols]: number[];
    };
    loop: for (let i = 0; i < headers.length; i++) {
        for (const colname of Object.keys(cols)) {
            if (headers[i] === cols[colname].title) {
                index[colname].push(i);
                continue loop;
            }
        }
        errors.push(`${i + 1} 列目の ヘッダー名 "${headers[i]}" が正しくありません。`);
    }
    if (errors.length > 0) return [[], errors]; // ヘッダ行にエラーがある場合は処理を中断

    // --- ユーザ情報を作成 ---
    const users: EntitiesUser[] = [];
    for (let i = 1; i < rows.length; i++) {
        const n = i + 1; // エラーメッセージ表示用の行番号
        const row = rows[i];
        const mailAddress = row[index.mailAddress[0]];
        if (!mailAddress) {
            continue; // メールアドレスが空の行はスキップ
        }
        const familyName = row[index.familyName[0]];
        if (!familyName) {
            errors.push(`${n} 行目の ${cols.familyName.title} が空です`);
        }
        const firstName = row[index.firstName[0]];
        if (!firstName) {
            errors.push(`${n} 行目の ${cols.firstName.title} が空です`);
        }
        const role = mroles.find((role) => role.caption === row[index.role[0]]);
        if (!role) {
            errors.push(`${n} 行目の ${cols.role.title} "${row[index.role[0]]}" は存在しません`);
            continue;
        }
        let areas: number[] = [];
        for (const j of index.areas) {
            if (!row[j]) continue; // 空文字をスキップ
            const id = mareas.find((area) => area.title === row[j])?.id;
            if (!id) {
                errors.push(`${n} 行目の ${cols.areas.title} "${row[j]}" は存在しません`);
            } else {
                areas.push(id);
            }
        }
        // 担当グループ
        areas = sort(uniq(areas));
        if (role.viewRange === 4 && areas.length === 0) {
            const message = `${n} 行目の ${cols.areas.title} は ${role.caption} 権限の場合は指定が必要です`;
            errors.push(message);
        } else if (role.viewRange !== 4 && areas.length > 0) {
            const message = `${n} 行目は ${role.caption} 権限の場合 ${cols.areas.title} を指定できません`;
            errors.push(message);
        } else if (20 < areas.length) {
            errors.push(`${n} 行目の ${cols.areas.title} は 20 以下である必要があります`);
        }
        // 担当店舗
        let stores: number[] = [];
        for (const j of index.stores) {
            if (!row[j]) continue; // 空文字をスキップ
            const id = mstores.find((store) => store.title === row[j])?.id;
            if (!id) {
                errors.push(`${n} 行目の ${cols.stores.title} "${row[j]}" は存在しません`);
            } else {
                stores.push(id);
            }
        }
        stores = sort(uniq(stores));
        if (role.viewRange === 5 && stores.length === 0) {
            const message = `${n} 行目の ${cols.stores.title} は ${role.caption} 権限の場合は指定が必要です`;
            errors.push(message);
        } else if (role.viewRange !== 5 && stores.length > 0) {
            const message = `${n} 行目は ${role.caption} 権限の場合 ${cols.stores.title} を指定できません`;
            errors.push(message);
        } else if (20 < stores.length) {
            errors.push(`${n} 行目の ${cols.stores.title} は 20 以下である必要があります`);
        }
        // クチコミアラート通知許可グループ
        let subscriptionAreas: number[] = [];
        for (const j of index.subscriptionAreas) {
            if (!row[j]) continue; // 空文字をスキップ
            const id = mrareas.find((area) => area.title === row[j])?.id;
            if (!id) {
                errors.push(
                    `${n} 行目の ${cols.subscriptionAreas.title} "${row[j]}" は存在しません`
                );
            } else {
                subscriptionAreas.push(id);
            }
        }
        subscriptionAreas = sort(uniq(subscriptionAreas));
        // クチコミアラート通知許可店舗
        let subscriptionStores: number[] = [];
        for (const j of index.subscriptionStores) {
            if (!row[j]) continue; // 空文字をスキップ
            const id = mrstores.find((store) => store.title === row[j])?.id;
            if (!id) {
                errors.push(
                    `${n} 行目の ${cols.subscriptionStores.title} "${row[j]}" は存在しないか、クチコミオプションが無効です`
                );
            } else {
                subscriptionStores.push(id);
            }
        }
        subscriptionStores = sort(uniq(subscriptionStores));
        const locationUpdateNotificationEnabled = row[index.locationUpdateNotificationEnabled[0]];
        if (["ON", "OFF"].includes(locationUpdateNotificationEnabled) === false) {
            errors.push(
                `${n} 行目の ${cols.locationUpdateNotificationEnabled.title} は "ON" または "OFF" である必要があります`
            );
        }
        const useMFA = row[index.useMFA[0]];
        if (["ON", "OFF"].includes(useMFA) === false) {
            errors.push(
                `${n} 行目の ${cols.useMFA.title} は "ON" または "OFF" である必要があります`
            );
        }
        const user: EntitiesUser = {
            familyName,
            firstName,
            mailAddress,
            roleLv: role.roleLv,
            areas,
            stores,
            reviewSubscriptions: {
                areas: subscriptionAreas,
                stores: subscriptionStores,
            },
            locationUpdateNotificationEnabled: locationUpdateNotificationEnabled === "ON",
            useMFA: useMFA === "ON",
        };
        users.push(user);
    }
    return [users, errors];
}

/** ユーザ情報を比較して、更新された項目の一覧を作成する */
export function diffUsers(
    a: EntitiesUser,
    b: EntitiesUser,
    mroles: EntitiesRole[], // 権限マスタ
    mareas: SelectItem[], // エリアマスタ
    mstores: SelectItem[], // 店舗マスタ
    mrareas: SelectItem[], // クチコミオプションが有効な店舗を含むエリアマスタ
    mrstores: SelectItem[] // クチコミオプションが有効な店舗マスタ
): { col: string; a: string; b: string }[] {
    const list: { col: string; a: string; b: string }[] = [];
    if (a.familyName !== b.familyName) {
        list.push({ col: cols.familyName.title, a: a.familyName, b: b.familyName });
    }
    if (a.firstName !== b.firstName) {
        list.push({ col: cols.firstName.title, a: a.firstName, b: b.firstName });
    }
    if (a.mailAddress !== b.mailAddress) {
        list.push({ col: cols.mailAddress.title, a: a.mailAddress, b: b.mailAddress });
    }
    if (a.roleLv !== b.roleLv) {
        list.push({
            col: cols.role.title,
            a: mroles.find((role) => role.roleLv === a.roleLv)?.caption ?? "",
            b: mroles.find((role) => role.roleLv === b.roleLv)?.caption ?? "",
        });
    }
    if (!equals(a.areas, b.areas)) {
        list.push({
            col: cols.areas.title,
            a: a.areas.map((id) => mareas.find((x) => x.id === id)?.title).join(", "),
            b: b.areas.map((id) => mareas.find((x) => x.id === id)?.title).join(", "),
        });
    }
    if (!equals(a.stores, b.stores)) {
        list.push({
            col: cols.stores.title,
            a: a.stores.map((id) => mstores.find((x) => x.id === id)?.title).join(", "),
            b: b.stores.map((id) => mstores.find((x) => x.id === id)?.title).join(", "),
        });
    }
    if (!equals(a.reviewSubscriptions?.areas, b.reviewSubscriptions?.areas)) {
        list.push({
            col: cols.subscriptionAreas.title,
            a: a.reviewSubscriptions?.areas
                .map((id) => mrareas.find((x) => x.id === id)?.title)
                .join(", "),
            b: b.reviewSubscriptions?.areas
                .map((id) => mrareas.find((x) => x.id === id)?.title)
                .join(", "),
        });
    }
    if (!equals(a.reviewSubscriptions?.stores, b.reviewSubscriptions?.stores)) {
        list.push({
            col: cols.subscriptionStores.title,
            a: a.reviewSubscriptions?.stores
                .map((id) => mrstores.find((x) => x.id === id)?.title)
                .join(", "),
            b: b.reviewSubscriptions?.stores
                .map((id) => mrstores.find((x) => x.id === id)?.title)
                .join(", "),
        });
    }
    if (a.locationUpdateNotificationEnabled !== b.locationUpdateNotificationEnabled) {
        list.push({
            col: cols.locationUpdateNotificationEnabled.title,
            a: a.locationUpdateNotificationEnabled ? "ON" : "OFF",
            b: b.locationUpdateNotificationEnabled ? "ON" : "OFF",
        });
    }
    if (a.useMFA !== b.useMFA) {
        list.push({
            col: cols.useMFA.title,
            a: a.useMFA ? "ON" : "OFF",
            b: b.useMFA ? "ON" : "OFF",
        });
    }
    return list;
}
