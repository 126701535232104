<template>
  <div>
    <form class="stores-area">
      <div class="stores-head">
        <div class="stores-menu-container">
          <div class="table-menu-stores">
            <v-dialog v-model="dialog.show" width="500" persistent>
              <v-card data-testid="dialog">
                <v-card-title class="headline grey lighten-2" primary-title>
                  {{ dialog.title }}
                </v-card-title>
                <v-progress-linear
                  v-model="dialog.percentage"
                  color="light-blue"
                ></v-progress-linear>
                <v-card-text>
                  <p>{{ dialog.message }}</p>
                  <p v-if="dialog.errorCode">
                    <b>エラーコード:</b>
                    {{ dialog.errorCode }}
                  </p>
                  <p v-if="dialog.errorCause">
                    <b>エラー理由</b>
                    <br />
                    {{ dialog.errorCause }}
                  </p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    variant="text"
                    :disabled="!dialog.cancelButton"
                    @click="dialog.show = false"
                  >
                    {{ dialog.cancelButton }}
                  </v-btn>
                  <v-btn
                    data-testid="dialog-accept"
                    variant="text"
                    :disabled="!dialog.acceptButton"
                    @click="acceptMatter"
                  >
                    {{ dialog.acceptButton }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <div>
              <o-field grouped>
                <o-upload
                  v-model="xlsxFile"
                  accept=".xlsx"
                  class="xlsx-import-button"
                  @update:model-value="importFile(false)"
                >
                  <a class="button is-primary is-small">
                    <span>XLSXインポート</span>
                  </a>
                </o-upload>

                <o-button
                  class="xlsx-export-button"
                  variant="primary"
                  size="small"
                  @click="exportFile()"
                >
                  XLSXエクスポート
                </o-button>

                <o-button
                  data-testid="keyword-setting"
                  class="keyword-setting-button"
                  variant="primary"
                  size="small"
                  @click="setKeywords()"
                >
                  キーワード設定
                </o-button>
                <o-button
                  data-testid="sync-gmb"
                  class="gmb-sync-button"
                  variant="primary"
                  size="small"
                  @click="showSyncGMB()"
                >
                  GBP店舗同期
                </o-button>

                <o-button
                  data-testid="sync"
                  class="setting-sync"
                  variant="primary"
                  size="small"
                  @click="showSyncStoresSetting()"
                >
                  設定反映
                </o-button>
              </o-field>
            </div>
          </div>
          <p class="error-message mt-2">
            不具合の原因となるため、クチコミ分析オプションをONにした直後にOFFに戻さないでください。1分程度は間隔をあけてください。
          </p>
        </div>
        <div class="search-container">
          <div class="search-box">
            <v-text-field
              v-model="searchWord"
              data-testid="search-word"
              label="検索キーワード"
              variant="underlined"
              density="compact"
              single-line
              hide-details
              clearable
              prepend-inner-icon="mdi-magnify"
              color="primary"
              class="mr-2 mb-1"
              style="width: 200px; max-width: 200px"
              @keypress.enter="searchStores"
              @click:clear="
                searchWord = '';
                searchStores();
              "
            ></v-text-field>
            <o-button
              data-testid="filter-by-search-word"
              variant="primary"
              size="small"
              @click="searchStores"
            >
              絞り込み
            </o-button>
            <span style="margin-left: 10px">{{ storeRecords.length }}件表示中</span>
          </div>
          <div class="control-display-checkbox">
            <o-checkbox v-model="isAll">
              {{ dict.checkbox_label_isAll }}
            </o-checkbox>
          </div>
        </div>
      </div>

      <div v-if="initialized" class="custom-grid">
        <c-grid
          ref="cgrid"
          :frozen-col-count="3"
          style="font-size: small"
          class="text-sm-left cgrid"
          :font="gridFont"
          :theme="customTheme"
          :allow-range-paste="false"
          :delete-cell-value-on-del-key="true"
          :move-cell-on-tab-key="true"
          :move-cell-on-enter-key="true"
          :select-all-on-ctrl-a-key="true"
          :data="storeRecords"
        >
          <c-grid-input-column
            field="poiID"
            width="80"
            :readonly="true"
            :column-style="{ textAlign: 'right' }"
            :sort="sortColumn"
          >
            店舗ID
          </c-grid-input-column>
          <c-grid-input-column
            field="gmbStoreCode"
            width="150"
            :readonly="true"
            :column-style="{ textOverflow: 'ellipsis' }"
            :sort="sortColumn"
          >
            店舗コード
          </c-grid-input-column>
          <c-grid-input-column
            field="name"
            width="200"
            :readonly="true"
            :column-style="{ textOverflow: 'ellipsis' }"
            :sort="sortColumn"
          >
            店舗名
          </c-grid-input-column>
          <c-grid-input-column
            field="keyword"
            width="200"
            :input-validator="keywordValidator"
            :sort="sortColumn"
          >
            検索キーワード
          </c-grid-input-column>
          <c-grid-link-column
            field="failedCount"
            width="180"
            :column-style="{ textAlign: 'center' }"
            :disabled="keywordProblem"
            :href="failedClick"
            :readonly="true"
            :sort="sortColumn"
          >
            順位取得失敗キーワード数
          </c-grid-link-column>
          <c-grid-check-column field="review" width="150" :sort="sortColumn">
            クチコミ分析
          </c-grid-check-column>
          <c-grid-check-column field="post" width="150" :sort="sortColumn">
            投稿
          </c-grid-check-column>
          <c-grid-check-column field="citation" width="150" :sort="sortColumn">
            Facebook/Instagram連携
          </c-grid-check-column>
          <c-grid-check-column field="yplace" width="150" :sort="sortColumn">
            Yahoo!プレイス連携
          </c-grid-check-column>
          <c-grid-check-column field="apple" width="150" :sort="sortColumn">
            Apple Business Connect連携
          </c-grid-check-column>
          <c-grid-check-column field="structuredPage" width="150" :sort="sortColumn">
            店舗ページ構造化
          </c-grid-check-column>
          <c-grid-check-column field="enabled" width="150" :sort="sortColumn">
            登録済/未登録
          </c-grid-check-column>
        </c-grid>

        <v-dialog v-model="storesInfoShow" class="info" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ storesInfoTitle }}
            </v-card-title>

            <v-spacer></v-spacer>

            <template v-for="(detail, index) in storesInfoDetails" :key="index">
              <v-card-text>
                {{ detail }}
              </v-card-text>
            </template>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" variant="text" @click="storesInfoShow = false">閉じる</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div v-else class="custom-grid d-flex justify-center pa-1 align-center mb-1">
        <v-progress-circular indeterminate color="primary" :size="100"></v-progress-circular>
      </div>
    </form>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss" scoped></style>
