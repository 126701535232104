<template>
  <v-dialog
    v-model="open"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @update:model-value="
      loading = false;
      $emit('update:modelValue', open);
    "
  >
    <v-card>
      <v-toolbar color="primary">
        <v-btn icon="mdi-close" @click="$emit('update:modelValue', false)" />
        <v-toolbar-title class="text-black">{{ title() }}</v-toolbar-title>
        <div class="d-flex align-center h-100 header-content">
          <v-text-field
            v-model="searchWord"
            data-testid="search-word"
            label="検索キーワード"
            variant="underlined"
            density="compact"
            single-line
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
            class="ml-3 text-black bg-white"
            style="width: 200px"
            @keypress.enter="searchHistories"
            @click:clear="
              searchWord = '';
              searchHistories();
            "
          />
          <v-btn
            data-testid="filter-by-search-word"
            text="絞り込み"
            size="small"
            variant="flat"
            color="primary"
            class="mx-3"
            @click="searchHistories"
          />
        </div>
        <div class="d-flex align-center h-100 header-content px-3">
          <v-btn
            text="XLSXエクスポート"
            size="small"
            :disabled="loading"
            prepend-icon="mdi-download"
            variant="flat"
            color="primary"
            @click="exportXLSX"
          />
        </div>
      </v-toolbar>
      <v-tabs
        v-model="tab"
        class="tabbar"
        slider-color="primary"
        @update:model-value="updateGridSize"
      >
        <template v-for="item in tabItems" :key="item.key">
          <v-tab
            v-if="item.enabled"
            :id="'detail-tab-' + item.key"
            :value="item.key"
            class="tab-heading"
          >
            {{ postCaption(item.key) }}
          </v-tab>
        </template>
      </v-tabs>
      <v-window v-model="tab" style="height: calc(100vh - 120px)">
        <template v-for="item in tabItems" :key="item.key">
          <v-window-item
            v-if="item.enabled"
            :value="item.key"
            style="height: calc(100vh - 120px)"
            :transition="false"
            :reverse-transition="false"
          >
            <c-grid
              :ref="`grid-${item.key}`"
              :frozen-col-count="1"
              :font="gridFont"
              :theme="customTheme"
              :data="item.statuses"
              class="cgrid"
              style="height: calc(100vh - 120px)"
            >
              <c-grid-column
                caption="店舗ID"
                width="100"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                field="poiID"
              />
              <c-grid-column
                caption="店舗コード"
                width="120"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                :field="(item) => findStoreCode(item.poiID)"
              />
              <c-grid-column
                caption="店舗名"
                width="350"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                :field="(item) => findStoreName(item.poiID)"
              />
              <c-grid-column
                caption="状態"
                width="100"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                :field="(item) => getStatus(item.status)"
              />
              <!-- 店舗個別の予約時間が存在しない場合は親ファイルの予約時間を表示 -->
              <c-grid-column
                caption="掲載開始日時"
                width="160"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                :field="(item) => convertDateTime(item?.posting, true)"
              />
              <c-grid-column
                caption="掲載終了日時"
                width="160"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                :field="(item) => convertDateTime(item?.cancellationReservation, true)"
              />
              <c-grid-column
                caption="更新日時"
                width="160"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                :field="(item) => convertDateTime(item.lastUpdate)"
              />
              <c-grid-column
                caption="付加情報"
                width="750"
                :sort="(order, col, grid) => item.sortState.sortColumn(order, col, grid)"
                field="message"
                column-type="multilinetext"
                :column-style="{ autoWrapText: true }"
              />
            </c-grid>
          </v-window-item>
        </template>
      </v-window>
    </v-card>
    <div v-if="loading" class="progress-circular-container">
      <v-progress-circular :size="80" :width="4" color="primary" indeterminate />
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, toNative } from "vue-facing-decorator";
import { type ListGrid } from "cheetah-grid";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

import { api } from "@/helpers/api/posts";
import { convertDateTime } from "@/helpers/date";
import type { EntitiesStore, EntitiesV2HistoryPostData, EntitiesV2PoiStatus } from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { getter } from "@/storepinia/idxdb";
import { useSnackbar } from "@/storepinia/snackbar";

class SortBase {
  // 英語の投稿状態を日本語に変換
  convertState(state: string, errorCount: number = 0): string {
    if (state in wordDictionary.v2post.histories.status) {
      return (
        wordDictionary.v2post.histories.status[state] + (errorCount == 0 ? "" : `(${errorCount})`)
      );
    }
    return wordDictionary.v2post.histories.status.UNKNOWN;
  }

  // RFC3339形式から読みやすい形式に日時を変換
  convertDateTime(rfc3339: string | undefined): string {
    return convertDateTime(rfc3339);
  }
}

// cheetah-gridのソート状態を記録するためのクラス
class CustomSortState extends SortBase {
  constructor(statuses: EntitiesV2PoiStatus[], storeMap: { [poiID: number]: EntitiesStore }) {
    super();
    this.statuses = statuses;
    this.storeMap = storeMap;
  }

  col: number = 0;
  order: "desc" | "asc" | null = null;
  firstIndex = 1;
  // カラムの並び順
  columnKeys: string[] = [
    "poiID",
    "storeCode",
    "storeName",
    "status",
    "posting",
    "cancellationReservation",
    "updateDateTime",
    "message",
  ];
  statuses: EntitiesV2PoiStatus[];
  storeMap: { [poiID: number]: EntitiesStore };

  // cheetah-gridヘッダ部をクリックされたイベント
  sortColumn(order: string | null, col: number, grid: ListGrid<EntitiesV2PoiStatus>): void {
    if (col !== this.col) {
      // 直前のと違う列をソートしようとした場合はorderを初期化する
      this.order = "desc";
      this.col = col;
    } else {
      switch (this.order) {
        case null:
          this.order = "desc";
          break;
        case "desc":
          this.order = "asc";
          break;
        case "asc":
          this.order = null;
          break;
      }
    }
    // 実際にデータをソートする
    this.sortRecord(col, grid, this.order);
    // ↑↓マーク制御
    grid.sortState.order = this.order;
  }
  // クリックされたカラムでソート処理を実行
  private sortRecord(
    col: number,
    grid: ListGrid<EntitiesV2PoiStatus>,
    order: string | null = "asc"
  ): void {
    let orderVal: number = 1;
    let key = this.columnKeys[col];
    if (order === null) {
      // 昇りでも降りでもない場合は初期の並び順であるcreateDateTimeの下り順でのソートになる
      key = this.columnKeys[this.firstIndex];
    } else if (order === "asc") {
      orderVal = -1;
    }
    this.statuses = this.statuses.sort((a, b) => {
      const aDash = this.getSortItem(key, a);
      const bDash = this.getSortItem(key, b);
      if (key == "updateDateTime") {
        // 更新日時はソート順を逆にする（最新の投稿を一番先頭に持ってきたいため）
        if (aDash > bDash) {
          return -1 * orderVal;
        } else {
          return 1 * orderVal;
        }
      }
      if (aDash > bDash) {
        return 1 * orderVal;
      } else {
        return -1 * orderVal;
      }
    });
    // 検索フィルターの適用
    grid.invalidate();
  }

  private getSortItem(key: string, item: EntitiesV2PoiStatus): string {
    switch (key) {
      case "poiID":
        return item?.poiID?.toString();
      case "storeCode":
        return item?.poiID in this.storeMap ? this.storeMap[item?.poiID]?.gmbStoreCode : "";
      case "storeName":
        return item?.poiID in this.storeMap ? this.storeMap[item?.poiID]?.name : "";
      case "status":
        return this.convertState(item?.status);
      case "posting":
        return this.convertDateTime(item?.posting);
      case "cancellationReservation":
        return this.convertDateTime(item?.cancellationReservation);
      case "updateDateTime":
        return this.convertDateTime(item?.lastUpdate);
      case "message":
        return item?.message === undefined || item?.message === null ? "" : item?.message;
    }
    return "";
  }
}

// gbp/yahoo/fb/ig/hostingの順番に格納されていることを期待しているので、順番を変更しないこと
const PostEnum = {
  gbp: 0,
  yahoo: 1,
  fb: 2,
  ig: 3,
  hosting: 4,
} as const;

type PostType = (typeof PostEnum)[keyof typeof PostEnum];

class TabItem extends SortBase {
  constructor(key: PostType, storeMap: { [poiID: number]: EntitiesStore }) {
    super();
    this.key = key;
    this._rawStatuses = [];
    this._filterStatuses = [];
    this._storeMap = storeMap;
    this.sortState = new CustomSortState(this._filterStatuses, storeMap);
  }
  sortState: CustomSortState;
  key: PostType;
  enabled: boolean = false;
  private _storeMap: { [poiID: number]: EntitiesStore };
  private searchKeyword: string = "";
  private _rawStatuses: EntitiesV2PoiStatus[];
  private _filterStatuses: EntitiesV2PoiStatus[];
  get statuses(): EntitiesV2PoiStatus[] {
    return this._filterStatuses;
  }
  set statuses(value: EntitiesV2PoiStatus[]) {
    this._rawStatuses = value;
    this.updateSearchKeyword();
  }

  setSearchKeyword(keyword: string | null) {
    // X 検索 の順でクリックするとnullが代入されてしまう対策
    this.searchKeyword = keyword ?? "";
    this.updateSearchKeyword();
  }

  private updateSearchKeyword() {
    if (this.searchKeyword === "") {
      this._filterStatuses = this._rawStatuses;
    } else {
      this._filterStatuses = [];
      for (const state of this._rawStatuses) {
        const poi = state?.poiID?.toString();
        if (this.searchKeyword.includes(poi) || poi.includes(this.searchKeyword)) {
          this._filterStatuses.push(state);
          continue;
        }
        if (this.convertState(state?.status).includes(this.searchKeyword)) {
          this._filterStatuses.push(state);
          continue;
        }
        if (this.convertDateTime(state?.lastUpdate).includes(this.searchKeyword)) {
          this._filterStatuses.push(state);
          continue;
        }
        if (state?.message?.includes(this.searchKeyword)) {
          this._filterStatuses.push(state);
          continue;
        }

        const store = state.poiID in this._storeMap ? this._storeMap[state?.poiID] : undefined;
        if (store === undefined) {
          continue;
        }
        if (store?.gmbStoreCode.includes(this.searchKeyword)) {
          this._filterStatuses.push(state);
          continue;
        }
        if (store?.name.includes(this.searchKeyword)) {
          this._filterStatuses.push(state);
          continue;
        }
      }
    }
    this.sortState.statuses = this._filterStatuses;
  }
}

@Component({ emits: ["update:modelValue"] })
class V2PostDetails extends Vue {
  stores = getter().stores;
  company = getter().company;
  @Prop({ type: Boolean, default: false }) modelValue: boolean;
  @Prop({ type: Object, default: null }) postData: EntitiesV2HistoryPostData | null;

  // import した関数をそのまま呼び出すために定義
  convertDateTime = convertDateTime;

  open: boolean = false;
  loading: boolean = false;
  isDestroyed: boolean = false;
  storeMap: { [poiID: number]: EntitiesStore } = {};
  tab: string | null = null;
  // タブ内に表示するデータを保持している
  tabItems: TabItem[] = [];

  gridFontSize = 12.8;
  gridFont = `${this.gridFontSize}px sans-serif`;
  // cheetah-gridのカスタムテーマ設定（失敗ステータスの背景色を変更）
  customTheme = {
    checkbox: {
      borderColor: "#FF9800",
    },
    defaultBgColor({ col, row, grid }): string {
      if (col < grid.frozenColCount || row < grid.frozenRowCount) {
        return "#f0f0f0";
      }
      const r =
        grid.dataSource._sortedIndexMap == null
          ? row
          : grid.dataSource._sortedIndexMap[row - 1] + 1;
      const hdr = grid.header[col];
      const rec = grid.dataSource.source[r - 1];
      const failed = "#ffaaaa";
      const success = "#ffffff";

      switch (hdr.caption) {
        case "状態":
          return rec.status.includes("FAILED") || rec.status.includes("UNREGISTERED")
            ? failed
            : success;
      }
      return success;
    },
  };

  created(): void {
    this.isDestroyed = false;
    this.storeMap = {};
    if (this.stores?.stores?.length > 0) {
      this.stores?.stores?.forEach((rec) => (this.storeMap[rec.poiID] = rec));
    }
    Object.entries(PostEnum).forEach(([_, value]) => {
      this.tabItems.push(new TabItem(value, this.storeMap));
    });
  }

  beforeUnmount(): void {
    this.isDestroyed = true;
  }

  @Watch("modelValue")
  async onOpen(): Promise<void> {
    this.open = this.modelValue;
    // ダイアログが開いたらデータを取得する
    if (this.modelValue) {
      await this.fetch();
      for (let i = 0; i < this.tabItems.length; i++) {
        // 左端のタブを初期選択する
        if (document.getElementById(`detail-tab-${i}`)) {
          document.getElementById(`detail-tab-${i}`).click();
          break;
        }
      }
    }
  }

  private async fetch(): Promise<void> {
    this.loading = true;
    Object.entries(PostEnum).forEach(([_, value]) => {
      this.tabItems[value].enabled = false;
      this.tabItems[value].statuses = [];
    });
    await this.loadAllStatus();
    await this.applyReservationDates();
    await this.allUpdateGridSize();
    this.loading = false;
  }

  private async loadAllStatus() {
    const results = await api.loadAllStatus(this.postData);
    results.forEach((result) => {
      if (result?.file?.includes("gmb_")) {
        this.tabItems[PostEnum.gbp].enabled = true;
        this.tabItems[PostEnum.gbp].statuses = this.tabItems[PostEnum.gbp].statuses.concat(
          result?.details
        );
      } else if (result?.file?.includes("yahoo_")) {
        this.tabItems[PostEnum.yahoo].enabled = true;
        this.tabItems[PostEnum.yahoo].statuses = this.tabItems[PostEnum.yahoo].statuses.concat(
          result?.details
        );
      } else if (result?.file?.includes("fb_")) {
        this.tabItems[PostEnum.fb].enabled = true;
        this.tabItems[PostEnum.fb].statuses = this.tabItems[PostEnum.fb].statuses.concat(
          result?.details
        );
      } else if (result?.file?.includes("ig_")) {
        this.tabItems[PostEnum.ig].enabled = true;
        this.tabItems[PostEnum.ig].statuses = this.tabItems[PostEnum.ig].statuses.concat(
          result?.details
        );
      } else if (result?.file?.includes("hosting_")) {
        this.tabItems[PostEnum.hosting].enabled = true;
        this.tabItems[PostEnum.hosting].statuses = this.tabItems[PostEnum.hosting].statuses.concat(
          result?.details
        );
      }
    });
  }

  private async applyReservationDates() {
    // ファイル一括の場合は店舗個別の予約日時を適用し、そうでない場合は親ファイルの予約日時を適用する
    this.tabItems.forEach((item) => {
      item.statuses.forEach((status) => {
        status.posting = status?.posting ?? this.postData?.posting;
        status.cancellationReservation =
          status?.cancellationReservation ?? this.postData?.cancellationReservation;
      });
    });
  }

  postCaption(t: PostType): string {
    switch (t) {
      case PostEnum.gbp:
        return `Google Business Profile(${this.tabItems[PostEnum.gbp].statuses.length})`;
      case PostEnum.yahoo:
        return `Yahoo!プレイス(${this.tabItems[PostEnum.yahoo].statuses.length})`;
      case PostEnum.fb:
        return `Facebook(${this.tabItems[PostEnum.fb].statuses.length})`;
      case PostEnum.ig:
        return `Instagram(${this.tabItems[PostEnum.ig].statuses.length})`;
      case PostEnum.hosting:
        return `ホスティング連動(${this.tabItems[PostEnum.hosting].statuses.length})`;
    }
  }

  findStoreCode(poiId: number): string {
    try {
      return this.storeMap[poiId]?.gmbStoreCode;
    } catch {
      return "";
    }
  }
  findStoreName(poiId: number): string {
    try {
      return this.storeMap[poiId]?.name;
    } catch {
      return "";
    }
  }

  getStatus(state: string): string {
    if (state in wordDictionary.v2post.histories.status) {
      return wordDictionary.v2post.histories.status[state];
    }
    return wordDictionary.v2post.histories.status.UNKNOWN;
  }

  title(): string {
    if (this.postData == null) {
      return "";
    }
    if (this.postData?.xlsxMode) {
      return `ファイル一括投稿 詳細：${this.postData.title} / ${this.convertDateTime(
        this.postData.createDateTime
      )}`;
    }
    return `詳細：${this.postData.title} / ${this.convertDateTime(this.postData.createDateTime)}`;
  }

  searchWord: string = "";
  searchHistories(): void {
    Object.entries(PostEnum).forEach(([_, value]) => {
      this.tabItems[value].setSearchKeyword(this.searchWord);
    });
  }

  async updateGridSize() {
    await this.$nextTick();
    const gridRefKey = `grid-${this.tab}`;
    if (!this.$refs[gridRefKey]) {
      return;
    }
    this.updateGrid(gridRefKey);
  }

  async allUpdateGridSize() {
    // 履歴を切り替えたときにどのtabが選択されているかは投稿によるので、全てのtabに対して更新処理を行う
    await this.$nextTick();
    for (const tabItem of this.tabItems) {
      const gridRefKey = `grid-${tabItem.key}`;
      if (!this.$refs[gridRefKey]) {
        continue;
      }
      this.updateGrid(gridRefKey);
    }
  }

  private updateGrid(gridRefKey: string): void {
    // v-for の中でrefを作成しているので、refの配列になるため配列の最初の要素を取得する
    const grid = this.$refs[gridRefKey][0] as ListGrid<EntitiesV2PoiStatus>;
    grid?.updateSize();
    grid?.invalidate();
  }

  async exportXLSX(): Promise<void> {
    this.loading = true;
    try {
      const wb = XLSX.utils.book_new();
      // タブごとに一覧をシートに出力
      for (const tabItem of this.tabItems) {
        if (tabItem.statuses.length === 0) continue;
        const jsonData = tabItem.statuses.map((status) => {
          return {
            店舗ID: status?.poiID,
            店舗コード: this.findStoreCode(status?.poiID),
            店舗名: this.findStoreName(status?.poiID),
            状態: this.getStatus(status?.status),
            掲載開始日時: this.convertDateTime(status?.posting),
            掲載終了日時: this.convertDateTime(status?.cancellationReservation),
            更新日時: this.convertDateTime(status?.lastUpdate),
            付加情報: status?.message ?? "",
          };
        });
        const ws = XLSX.utils.json_to_sheet(jsonData);
        XLSX.utils.book_append_sheet(wb, ws, this.postCaption(tabItem.key));
      }
      const companyName = this.company.name;
      const dateString = dayjs().format("YYYYMMDD");
      const fileName = `${this.postData.title}-投稿履歴詳細エクスポート-${companyName}-${dateString}.xlsx`;
      XLSX.writeFile(wb, fileName);
    } catch (e: any) {
      console.error(e);
      useSnackbar().addSnackbarMessages({
        text: "投稿履歴のXLSXエクスポートに失敗しました",
        color: "danger",
      });
    } finally {
      this.loading = false;
    }
  }
}
export default toNative(V2PostDetails);
</script>
<style lang="scss" scoped>
.header-content {
  background-color: #fff;
}
.tab-heading {
  text-transform: unset !important;
}
</style>
