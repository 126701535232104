<template>
  <v-card-text>
    <div class="wrapper">
      <div align="right" style="margin-bottom: 10px">
        <v-btn-toggle v-model="chartType" density="compact" variant="outlined" mandatory>
          <v-btn value="stack" :disabled="isButtonLoading" @click="onChangeChartType()">
            積上表示
          </v-btn>
          <v-btn value="ratio" :disabled="isButtonLoading" @click="onChangeChartType()">
            割合表示
          </v-btn>
        </v-btn-toggle>
      </div>
      <div :id="chartId" :ref="chartId" class="chart" />
      <v-overlay :model-value="isLoading" persistent contained class="align-center justify-center">
        <v-progress-circular indeterminate size="64" color="primary" />
      </v-overlay>
    </div>
  </v-card-text>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { initChart } from "@/components/shared/am5-shared";
import type { DomainPerformanceInsightResponse } from "@/types/ls-api";
import type { DateParams } from "./performance";
import dayjs from "dayjs";

type TrendData = {
  date: string;
  map: number;
  search: number;
  sumImpression: number;
  website: number;
  call: number;
  route: number;
  sumReaction: number;
  reactionRate: number;
};

export default defineComponent({
  props: {
    isLoading: { type: Boolean, default: true },
    isImpression: { type: Boolean, default: true }, // true:表示回数, false:反応数
    dateParams: { type: Object as () => DateParams, required: true },
    performanceTrend: {
      type: Object as () => DomainPerformanceInsightResponse,
      default: () => ({ seriesList: [] } as DomainPerformanceInsightResponse),
    },
  },
  data() {
    return {
      chartId: "performance-trend-" + Math.floor(Math.random() * 1000000),
      includesPastData: true,
      chartType: "stack",
      seriesSettings: [
        {
          name: "map",
          label: "MAP経由",
          isImpression: true,
          color: "#D8DFEB",
        },
        {
          name: "search",
          label: "検索経由",
          isImpression: true,
          color: "#9EAECD",
        },
        {
          name: "route",
          label: "経路検索数",
          isImpression: false,
          color: "#CFD7E7",
        },
        {
          name: "call",
          label: "通話数",
          isImpression: false,
          color: "#BBC6DC",
        },

        {
          name: "website",
          label: "WEBサイト遷移数",
          isImpression: false,
          color: "#9EAECD",
        },
      ] as {
        name: string;
        label: string;
        isImpression: boolean;
        color: string;
      }[],
      legendSortSetting: {
        検索経由: 1,
        MAP経由: 2,
        WEBサイト遷移数: 3,
        通話数: 4,
        経路検索数: 5,
        反応率: 6,
      },
      isButtonLoading: false,
      reactionLabelColor: am5.color("#5ca2df"),
    };
  },
  computed: {
    minDateTrendData(): string {
      return this.performanceTrend.seriesList?.[0]?.data[0]?.date ?? "";
    },
    maxDateTrendData(): string {
      return this.performanceTrend.seriesList?.[0]?.data.slice(-1)[0]?.date ?? "";
    },
    // データの期間の範囲内にdateParamsを収めて返す
    dateParamsInTrendDataRange(): DateParams {
      if (!this.minDateTrendData || !this.maxDateTrendData) {
        return this.dateParams;
      }
      const minDate = dayjs(this.minDateTrendData);
      // データの日付は1日で返ってくるので月末までにする
      const maxDate = dayjs(this.maxDateTrendData).add(1, "month").date(1).add(-1, "day");
      // 日付範囲を調整するヘルパー関数
      const adjustDateRange = (startDateStr: string, endDateStr: string) => {
        const result = { startDate: "", endDate: "" };
        const startDate = dayjs(startDateStr);
        const endDate = dayjs(endDateStr);

        // 全くの範囲外だったら空文字を返す
        const isOutOfRange =
          (startDate.isAfter(maxDate) || startDate.isBefore(minDate)) &&
          (endDate.isAfter(maxDate) || endDate.isBefore(minDate));
        if (isOutOfRange) {
          return result;
        }

        if (startDate.isBefore(minDate)) {
          result.startDate = this.minDateTrendData;
        } else if (startDate.isAfter(maxDate)) {
          result.startDate = maxDate.format("YYYY-MM-DD");
        } else {
          result.startDate = startDateStr;
        }

        if (endDate.isAfter(maxDate)) {
          result.endDate = maxDate.format("YYYY-MM-DD");
        } else if (endDate.isBefore(minDate)) {
          result.endDate = this.minDateTrendData;
        } else {
          result.endDate = endDateStr;
        }

        return result;
      };

      const currentDataRange = adjustDateRange(
        this.dateParams.currentStartDate,
        this.dateParams.currentEndDate
      );
      const prevDataRange = adjustDateRange(
        this.dateParams.previousStartDate,
        this.dateParams.previousEndDate
      );
      return {
        currentStartDate: currentDataRange.startDate,
        currentEndDate: currentDataRange.endDate,
        previousStartDate: prevDataRange.startDate,
        previousEndDate: prevDataRange.endDate,
      };
    },
  },
  watch: {
    performanceTrend: {
      immediate: false,
      handler(newVal, oldVal) {
        this.createStackedViewChart();
      },
    },
  },
  created(): void {},
  mounted(): void {
    // タブ切替後などbackgroundの描画ができないことがあるのでnextTickで実行する
    this.$nextTick(() => {
      this.createStackedViewChart();
    });
  },
  methods: {
    // 積上・割合の切り替え
    onChangeChartType(): void {
      this.isButtonLoading = true;
      this.createStackedViewChart();
      setTimeout(() => {
        // 連打防止
        this.isButtonLoading = false;
      }, 1);
    },
    reactionRate(t: TrendData): number {
      if (t.search + t.map === 0) {
        return 0;
      }
      const reaction = (t.website + t.call + t.route) / (t.search + t.map);
      return Math.round(reaction * 10000) / 100; // 小数点以下2桁で四捨五入
    },
    getShortDate(date: string): string {
      return date?.slice(0, -3).replace("-", "/");
    },
    generateTrendData(performanceTrend: DomainPerformanceInsightResponse): TrendData[] {
      // 辞書を用意
      const dateMap: { [key: string]: TrendData } = {};
      performanceTrend.seriesList?.[0]?.data.forEach((d) => {
        dateMap[d.date] = { date: this.getShortDate(d.date) } as TrendData;
      });

      // 辞書に格納していく
      performanceTrend.seriesList?.forEach((series) => {
        series.data.forEach((d) => {
          switch (series.name) {
            case "business_impressions_search":
              return (dateMap[d.date].search = d.value);
            case "business_impressions_maps":
              return (dateMap[d.date].map = d.value);
            case "website_clicks":
              return (dateMap[d.date].website = d.value);
            case "call_clicks":
              return (dateMap[d.date].call = d.value);
            case "business_direction_requests":
              return (dateMap[d.date].route = d.value);
          }
        });
      });

      // 反応率を計算
      Object.keys(dateMap).forEach((k) => {
        dateMap[k].reactionRate = this.reactionRate(dateMap[k]);
      });

      Object.keys(dateMap).forEach((k) => {
        const d = dateMap[k];
        // 合計値
        d.sumImpression = d.search + d.map;
        d.sumReaction = d.website + d.call + d.route;

        if (this.chartType === "ratio") {
          // 割合表示
          d.map = Math.round((d.map / d.sumImpression) * 10000) / 100;
          d.search = Math.round((d.search / d.sumImpression) * 10000) / 100;

          d.website = Math.round((d.website / d.sumReaction) * 10000) / 100;
          d.call = Math.round((d.call / d.sumReaction) * 10000) / 100;
          d.route = Math.round((d.route / d.sumReaction) * 10000) / 100;
        }
      });

      return Object.values(dateMap);
    },
    getMax(data: TrendData[]): number {
      let max: number = 0;
      data.forEach((d) => {
        const sum = this.isImpression ? d.sumImpression : d.sumReaction;
        if (max < sum) {
          max = sum;
        }
      });
      return max;
    },
    createStackedViewChart(): void {
      if (
        !document.getElementById(this.chartId) || // 高速でページ離脱するとAmcharts5のroot生成に失敗でエラーになるので中断する
        !this.chartId ||
        !this.dateParams ||
        !this.performanceTrend
      ) {
        return;
      }
      const data: TrendData[] = this.generateTrendData(this.performanceTrend);

      const root: am5.Root = initChart(this.chartId);
      const chart: am5xy.XYChart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout,
        })
      );

      const legend: am5.Legend = chart.children.push(
        am5.Legend.new(root, {
          nameField: "name",
          centerX: am5.percent(50),
          x: am5.percent(50),
          y: am5.percent(95),
          layout: am5.GridLayout.new(root, {}),
        })
      );

      // ツールチップ表示する為にcursorを加える
      const cursor: am5xy.XYCursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);
      cursor.selection.setAll({
        fill: am5.color(0x000055),
        fillOpacity: 0.2,
      });

      const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(root, {
        minGridDistance: 40,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      });
      xRenderer.grid.template.setAll({ location: 0.5 });
      xRenderer.labels.template.setAll({ location: 0.5, multiLocation: 0.5 });

      // X軸(年月)
      const categoryAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.2,
          renderer: xRenderer,
          startLocation: 0,
          endLocation: 1,
          dx: 0,
          categoryField: "date",
          tooltip: am5.Tooltip.new(root, {}),
        })
      );
      categoryAxis.get("renderer").grid.template.setAll({
        visible: false,
      });
      categoryAxis.get("renderer").labels.template.setAll({
        maxWidth: 52,
        // ラベルのフォントサイズ自動調整
        oversizedBehavior: "fit",
        // ラベルのフォントサイズ手動調整
        fontSize: 10,
        fill: am5.color("#333333"),
        paddingTop: 10,
      });
      // チャート下部のツールチップ消す
      categoryAxis.set(
        "tooltip",
        am5.Tooltip.new(root, {
          forceHidden: true,
        })
      );

      categoryAxis.data.setAll(data);

      // Y軸(表示回数/反応数)
      const valueAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      const max = this.chartType === "ratio" ? 100 : Math.ceil(this.getMax(data) / 4) * 4;
      valueAxis.setAll({
        min: 0,
        max,
        strictMinMax: true,
      });

      let unit: string;
      if (this.chartType === "ratio") {
        unit = "%";
      } else {
        unit = "回";

        // 表示回数最大値を表示する
        const maxValue: am5.Label = am5.Label.new(root, {
          text: this.getMax(data).toLocaleString(),
          fill: am5.color("#333333"),
          position: "absolute",
          fontSize: 14,
          x: -4,
          y: -20,
        });
        valueAxis.children.unshift(maxValue);
      }

      // amcharts4のtitleの替わりにlabelを使って表現する
      const kai: am5.Label = am5.Label.new(root, {
        text: `(${unit})`,
        position: "absolute",
        x: -32,
        y: am5.percent(95),
      });
      valueAxis.children.unshift(kai);

      for (const setting of this.seriesSettings.filter(
        (s) => s.isImpression === this.isImpression
      )) {
        const series: am5xy.ColumnSeries = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: setting.label,
            xAxis: categoryAxis,
            yAxis: valueAxis,
            valueYField: setting.name,
            categoryXField: "date",
            maskContent: true,
            // 棒グラフの塗り色
            fill: am5.color(setting.color),
            maskBullets: false,
            stacked: true,
          })
        );

        series.columns.template.setAll({
          // 棒グラフの幅
          width: 20,
        });
        series.data.setAll(data);
        // Legendにseriesを関連付ける
        legend.data.push(series);
      }

      // Y軸(反応率)
      const reactionAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, {
            opposite: true,
            minGridDistance: 50,
          }),
        })
      );
      reactionAxis.setAll({
        strictMinMax: true,
        min: 0,
        max: 100,
      });

      // 反応率グリッド消す
      reactionAxis.get("renderer").grid.template.setAll({
        visible: false,
      });
      // 反応率ラベル消す;
      reactionAxis.get("renderer").labels.template.setAll({
        fill: this.reactionLabelColor as am5.Color,
      });

      // amcharts4のtitleの替わりにlabelを使って表現する
      const percent: am5.Label = am5.Label.new(root, {
        text: "(%)",
        position: "absolute",
        x: 20,
        y: am5.percent(95),
        fill: this.reactionLabelColor as am5.Color,
      });
      reactionAxis.children.unshift(percent);

      const lineSeries: am5xy.LineSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "反応率",
          minBulletDistance: 10,
          xAxis: categoryAxis,
          yAxis: reactionAxis,
          valueYField: "reactionRate",
          categoryXField: "date",
          fill: this.reactionLabelColor as am5.Color,
          stroke: this.reactionLabelColor as am5.Color,
        })
      );
      lineSeries.strokes.template.setAll({
        strokeWidth: 2,
      });
      lineSeries.fills.template.setAll({
        visible: true,
      });

      // Legendにseriesを関連付ける
      legend.data.push(lineSeries);

      // ツールチップ
      const tooltip: am5.Tooltip = lineSeries.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          pointerOrientation: "horizontal",
        })
      );
      tooltip.get("background").setAll({
        fill: am5.color("#EBEDF0"),
        shadowColor: am5.color("#000000"),
        shadowBlur: 4,
        shadowOffsetY: 2,
        shadowOpacity: 0.5,
      });
      // トータルの項もあるとフレンドリーかもしれない
      // const totalValue = this.chartType === "ratio" ? "" : "トータル : {sumImpression}回<br>";
      if (this.isImpression) {
        tooltip.label.setAll({
          html: `<div style='line-height: 1.5em; text-align: left;'>
          {date}<br>
          検索経由 : {search}${unit}<br>
          MAP経由 : {map}${unit}<br>
          反応率 : {reactionRate}%</div>`,
        });
      } else {
        tooltip.label.setAll({
          html: `<div style='line-height: 1.5em; text-align: left;'>
          {date}<br>
          WEBサイト遷移数 : {website}${unit}<br>
          通話数 : {call}${unit}<br>
          経路検索数 : {route}${unit}<br>
          反応率 : {reactionRate}%</div>`,
        });
      }

      lineSeries.data.setAll(data);
      const termRectContainer: am5.Container = am5.Container.new(root, {});
      chart.plotContainer.children.push(termRectContainer);
      termRectContainer.toBack();
      // 期間外の背景色を無効化するために期間を再計算する
      const displayDateParams = this.dateParamsInTrendDataRange;
      am5.ready(() => {
        // ウィンドウリサイズ時に期間枠の高さ再描画時にズレない様に最初に描画された時の値を保持しておく
        const plotContainerHeight = chart.plotContainer.height();
        drawBackgrounds(chart, root, termRectContainer, displayDateParams, plotContainerHeight);
        chart.plotContainer.events.on("boundschanged", () => {
          drawBackgrounds(chart, root, termRectContainer, displayDateParams, plotContainerHeight);
        });
      });
    },
  },
});

// 期間を示す背景色を描画
function drawBackgrounds(
  chart: am5xy.XYChart,
  root: am5.Root,
  termRectContainer: am5.Container,
  params: DateParams,
  plotContainerHeight: number
) {
  termRectContainer.children.clear();

  drawBackground(
    chart,
    root,
    termRectContainer,
    "#f5c682",
    params.currentStartDate,
    params.currentEndDate,
    plotContainerHeight
  );
  drawBackground(
    chart,
    root,
    termRectContainer,
    "#77ccff",
    params.previousStartDate,
    params.previousEndDate,
    plotContainerHeight
  );
}
function drawBackground(
  chart: am5xy.XYChart,
  root: am5.Root,
  termRectContainer: am5.Container,
  color: string,
  from: string,
  to: string,
  plotContainerHeight
) {
  const containerWidth = chart.plotContainer.width();
  const containerHeight = plotContainerHeight;
  const dfrom = dayjs(from);
  const dto = dayjs(to);
  const dstart = dayjs().add(-17, "month").date(1);
  const days = dayjs().endOf("month").diff(dstart, "day");

  // plotContainerの中での100分率でx軸上での位置を表現する
  const left = dfrom.diff(dstart, "day") / days;
  const width = dto.diff(dfrom, "day") / days;

  const rect: am5.Graphics = am5.Graphics.new(root, {
    position: "absolute",
    stroke: am5.color(color),
    strokeWidth: 1,
    fill: am5.color(color),
    fillOpacity: 0.2,
    draw: function (display) {
      display.drawRect(containerWidth * left, 0, containerWidth * width, containerHeight - 53);
    },
  });
  termRectContainer.children.push(rect);
}
</script>

<style lang="scss" scoped>
.wrapper {
  text-align: right;
  border: 1px solid #d6d6d6;
  padding: 20px;
}

.chart {
  width: 100%;
  height: 400px;
}

.v-btn-toggle > .v-btn.v-btn--active {
  background-color: var(--primary);
  color: white;
  font-weight: bold;
}
</style>
