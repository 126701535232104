import type { ThemeDefinition } from "vuetify";

export type TostoreTheme = {
    themeName: string;
    appName: string;
    operatingCompany: string;
    copyRight: string;
    favicon: string;
    bgLogin: string;
    logoLogin: string;
    logoTop: string;
    vuetifyTheme: ThemeDefinition;
};

// 相対パスの解決のためにimportを使用する
import faviconTostore from "@/assets/images/favicon-tostore.ico";
import logoLoginTostore from "@/assets/images/logo-login-tostore.svg";
import logoTopTostore from "@/assets/images/logo-top-tostore.svg";
import bgLoginTostore from "@/assets/images/bg-login-tostore.png";

export const tostore: TostoreTheme = {
    themeName: "tostore",
    appName: "トストア",
    operatingCompany: "株式会社オプト",
    copyRight: "© OPT, Inc. All Rights Reserved.",
    favicon: faviconTostore,
    bgLogin: bgLoginTostore,
    logoLogin: logoLoginTostore,
    logoTop: logoTopTostore,
    vuetifyTheme: {
        dark: false,
        colors: {
            primary: "#EE9D26",
            primaryInvert: "#ffffff",
            primaryHover: "#FDCB53",
            primaryTabBack: "#f5eddc",
            "on-primary": "#FFFFFF",
            secondary: "#03DAC6",
            secondaryDarken1: "#018786",
            error: "#B00020",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FB8C00",
            activeSidebar: "#fff3d6",
            loginInput: "#E8F0FE",
            loginText: "#FFF",
            loginTextBg: "rgba(255, 255, 255, 0)",
            background: "#FFFFFF",
            surface: "#FFF",
            google: "#34A853",
            yahoo: "#ff0033",
            instagram: "#E1306C",
            facebook: "#2577F2",
        },
    },
};

// Go!Good様のテーマ
import faviconGoGood from "@/assets/images/favicon-go-good.ico";
import logoLoginGoGood from "@/assets/images/logo-login-go-good.png";
import logoTopGoGood from "@/assets/images/logo-top-go-good.png";
import bgLoginGoGood from "@/assets/images/bg-login-go-good.jpg";

export const goGood: TostoreTheme = {
    themeName: "goGood",
    appName: "Go!Good MEO",
    operatingCompany: "Go Good株式会社",
    copyRight: "Copyright © 2023 Go Good CO.,LTD. All Rights Reserved.",
    favicon: faviconGoGood,
    bgLogin: bgLoginGoGood,
    logoLogin: logoLoginGoGood,
    logoTop: logoTopGoGood,
    vuetifyTheme: {
        dark: false,
        colors: {
            primary: "#042C6E",
            primaryInvert: "#ffffff",
            primaryHover: "#345C9E",
            primaryTabBack: "#DDE2EC",
            "on-primary": "#FFFFFF",
            secondary: "#03DAC6",
            secondaryDarken1: "#018786",
            error: "#B00020",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FB8C00",
            activeSidebar: "#DDE2EC",
            loginInput: "#E8F0FE",
            loginText: "#000",
            loginTextBg: "rgba(255, 255, 255, 0.5)",
            background: "#FFFFFF",
            surface: "#FFF",
            google: "#34A853",
            yahoo: "#ff0033",
            instagram: "#E1306C",
            facebook: "#2577F2",
        },
    },
};

// SCINEX様のテーマ
import faviconScinex from "@/assets/images/favicon-scinex.ico";
import logoLoginScinex from "@/assets/images/logo-login-scinex.svg";
import logoTopScinex from "@/assets/images/logo-top-scinex.svg";
import bgLoginScinex from "@/assets/images/bg-login-scinex.png";

export const scinex: TostoreTheme = {
    themeName: "scinex",
    // CSからマニュアルを受領するとファイル名はUTF-8-Macだが、gitにコミットすればUTF-8に変換されるため、下もUTF-8で定義している
    appName: "わが街Mappingプラス",
    operatingCompany: "株式会社サイネックス",
    copyRight: "©Copyright SCINEX CORPORATION. All rights reserved.",
    favicon: faviconScinex,
    bgLogin: bgLoginScinex,
    logoLogin: logoLoginScinex,
    logoTop: logoTopScinex,
    vuetifyTheme: {
        dark: false,
        colors: {
            primary: "#0B318F",
            primaryInvert: "#ffffff",
            primaryHover: "#345C9E",
            primaryTabBack: "#DDE2EC",
            "on-primary": "#FFFFFF",
            secondary: "#03DAC6",
            secondaryDarken1: "#018786",
            error: "#B00020",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FB8C00",
            activeSidebar: "#DDE2EC",
            loginInput: "#E8F0FE",
            loginText: "#000",
            loginTextBg: "rgba(255, 255, 255, 0.5)",
            background: "#FFFFFF",
            surface: "#FFF",
            google: "#34A853",
            yahoo: "#ff0033",
            instagram: "#E1306C",
            facebook: "#2577F2",
        },
    },
};

export function getVuetifyThemes(): Record<string, ThemeDefinition> {
    return {
        tostore: tostore.vuetifyTheme,
        goGood: goGood.vuetifyTheme,
        scinex: scinex.vuetifyTheme,
    };
}

export function currentTheme(): TostoreTheme {
    const domain = window.location.hostname;
    if (domain.startsWith("gogoodmeo") || domain.startsWith("10.")) {
        return goGood;
    } else if (domain.startsWith("wmp") || domain.startsWith("192.")) {
        return scinex;
    } else {
        return tostore;
    }
}
