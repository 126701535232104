<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="selections"
    :items="items"
    item-title="title"
    item-value="id"
    :custom-filter="customFilter"
    :hint="`選択済み: ${modelValue.length} / ${maxCount > 0 ? maxCount : ids.length}`"
    multiple
    chips
    small-chips
    persistent-hint
    :clearable="!readonly"
    variant="underlined"
    :menu-props="{ maxHeight: 500 }"
    :style="isMobile ? 'width:80%' : 'width: 50%'"
    color="primary"
    @update:model-value="onUpdateModelValue"
  >
    <template v-if="showAllSelect" #prepend-item>
      <v-list-item ripple :disabled="readonly" @click="toggleAllItems">
        <v-icon :color="selections.length > 0 ? 'primary darken-2' : ''">
          {{ checkBoxIcon() }}
        </v-icon>
        全選択
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template #item="{ props, item }">
      <v-list-item
        v-bind="props"
        :title="item?.raw.title"
        :disabled="item.raw.isHeader || readonly"
      >
        <template #prepend="{ isSelected }">
          <v-checkbox
            v-if="!item.raw.isHeader"
            :key="item.value"
            :model-value="isSelected"
            :ripple="false"
            tabindex="-1"
            hide-details
            density="compact"
            color="primary"
            :readonly="readonly"
          />
        </template>
      </v-list-item>
    </template>
    <template #chip="{ item, index }">
      <v-chip
        v-if="index < chipCount"
        :closable="closableChips"
        @click:close="readonly ? null : (selections = selections.filter((s) => s !== item.value))"
      >
        {{ item.title }}
      </v-chip>
      <span v-if="index === chipCount">(+{{ modelValue.length - chipCount }} {{ unit }})</span>
    </template>
  </v-autocomplete>
</template>
<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import type { VAutocomplete } from "vuetify/lib/components/index.mjs";
import type { SelectItem } from "@/helpers/select-items";

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<number[]>, default: () => [] as number[] },
    items: { type: Array as PropType<Array<SelectItem>>, default: () => [] as SelectItem[] },
    /** 「全選択」チェックボックスを表示するか否か */
    showAllSelect: { type: Boolean, default: false },
    /** 表示する chip 数。これより多いものは省略表示する */
    chipCount: { type: Number, default: 3 },
    /** 最大選択数。0 の場合は無制限 */
    maxCount: { type: Number, default: 0 },
    /** 例えば '店舗' を指定した場合、chip を省略表示した場合に '(+12 店舗)' のように表示するのに用いる。 */
    unit: { type: String, default: "" },
    /** chip に閉じるボタンを表示するか */
    closableChips: { type: Boolean, default: false },
    /** モバイル表示か否か */
    isMobile: { type: Boolean, default: false },
    /** 入力変更無効にする場合(閲覧権限・認証レビュー時などで使用) */
    readonly: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  computed: {
    /** 選択したものの id */
    selections: {
      get(): number[] {
        return this.modelValue;
      },
      set(value: number[]) {
        this.$emit("update:modelValue", value);
      },
    },
    /** items の id の重複を除外したもの */
    ids(): number[] {
      const itemKeys = {};
      this.items
        .filter((item) => typeof item.id === "number" || typeof item.id === "bigint")
        .forEach((item) => {
          itemKeys[item.id] = true;
        });
      return Object.keys(itemKeys).map((key) => Number(key));
    },
  },
  methods: {
    /** 「全選択」チェックボックスのアイコンを返す */
    checkBoxIcon(): string {
      if (this.selections.length === this.ids.length) return "mdi-close-box";
      if (this.selections.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    /** 「全選択」チェックボックスを押したときの動作 */
    toggleAllItems(): void {
      if (this.selections.length === this.ids.length) {
        this.selections = [];
      } else {
        this.selections = this.ids;
      }
    },
    validate(): void {
      (this.$refs.autocomplete as typeof VAutocomplete).validate();
    },
    customFilter(value: string, query: string, item?: { raw: SelectItem }): boolean {
      const keyword = item?.raw?.keyword ?? "";
      if (keyword === "") {
        return value.toLowerCase().includes(query.toLowerCase());
      }
      return keyword.toLowerCase().includes(query.toLowerCase());
    },
    onUpdateModelValue(newSelections: number[]): void {
      // 最大選択数が指定されていた場合は制限する
      if (this.maxCount === 0 || newSelections.length <= this.maxCount) {
        this.selections = newSelections;
      } else {
        this.selections = newSelections.slice(0, this.maxCount);
      }
    },
  },
});
</script>
