import type { FieldValidationMetaInfo } from "@vee-validate/i18n";
import emojiRegex from "emoji-regex";
import { defineRule } from "vee-validate";

const EMOJI_REGEX = emojiRegex();
/** 通常のURLの形式を検証する正規表現パターン */
const REGEX_URL =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%.,_+~#=/*]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.,~#?&//=/*]*)/;
/** 空文字または有効なURLの形式を検証する正規表現パターン。URLが入力されていない場合や、有効なURLの形式で入力されている場合にマッチします。 */
export const REGEX_URL_OR_BLANK = new RegExp(`^(|${REGEX_URL.source})$`);
/** 空文字または複数のURLを含むテキストを検証する正規表現パターン。改行文字によって複数のURLが区切られているテキストを検証します。 */
export const REGEX_MULTI_URL_OR_BLANK = new RegExp(
    `^(|${REGEX_URL.source}([\\n|]${REGEX_URL.source})*)$`
);
export const REGEX_NON_NEGATIVE_INT = /^(?:0|[1-9]\d*)?$/;
export const REGEX_POSITIVE_INT = /^(?:[1-9]\d*)?$/;

// v-validator（V1投稿などで使用）
export function defineVeeValidateRules(): void {
    defineRule("required", (value: any, params: string[], ctx) => {
        // note: ロジックはvee-validateのrequired ruleの実装に従っている
        // https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/required.ts

        // 判定させたくないときはvalidatorに"false"というfieldを渡してチェックをスキップする
        const enabled = params && params.length > 0 ? params[0] === "true" : true;
        if (!enabled) {
            return true;
        }

        // null判定及び空配列判定
        const isNullOrUndefined = value === null || value === undefined;
        const isEmptyArray = Array.isArray(value) && value.length === 0;
        if (isNullOrUndefined || isEmptyArray) {
            return `${ctx.field}は必須項目です。`;
        }
        // チェックボックス等へのチェックが必須という意味(booleanのrequired条件はtrue必須のみ設定してください)
        if (value === false) {
            return `${ctx.field}は必須項目です。`;
        }

        // 上記以外はstringとみなし、空文字・未入力の場合はfalseが返る
        if (String(value).trim().length) {
            return true;
        }
        return `${ctx.field}は必須項目です。`;
    });
    defineRule<string, string[]>("maxLength", (value, [max], ctx) => {
        // 全角・半角を区別するため、strLengthを使用
        if (strLength(value) <= parseInt(max, 10)) {
            return true;
        }
        return `${ctx.field}は${max}文字以内で入力してください。`;
    });
    defineRule<string, string[]>("maxLengthUTF", (value, [max], ctx) => {
        // 全角・半角を区別しない
        if (value.length <= parseInt(max, 10)) {
            return true;
        }
        return `${ctx.field}は${max}文字以内で入力してください。`;
    });
    defineRule<string, string[]>("maxLines", (value, [max], ctx) => {
        const list = value.split("\n").filter((l) => l !== "");
        if (list.length <= parseInt(max, 10)) {
            return true;
        }
        return `${ctx.field}は${max}行以内で入力してください。`;
    });
    defineRule<string, any>("postalCode", (value, _, ctx) => {
        const m = value.match(/^〒?\d{3}-?\d{4}$/);
        if (m) {
            return true;
        }
        return `${ctx.field}は「000-0000」の形式で入力してください。`;
    });
    defineRule<string, string[]>("administrativeArea", (value, [regionCode], ctx) => {
        // TODO: 国際対応
        const areas = administrativeAreaList[regionCode];
        if (!areas) {
            // 定義していない regionCode の場合は一律で valid とみなす
            return true;
        }
        if (areas.includes(value)) {
            return true;
        }
        return `${ctx.field}は正式名称を入力してください。`;
    });
    defineRule<string, any>("phoneNumber", (value, _, ctx) => {
        // TODO: 国際対応
        const all = value.match(/^(\(0\d{1,3}\)|0\d{1,3}-)\d{1,4}-\d{1,4}$/);
        const mobile = value.match(/^(\(0[5789]0\)|0[5789]0-)/);
        if (all != null && value.replace(/[-()]/g, "").length <= (mobile != null ? 11 : 10)) {
            return true;
        }
        return `${ctx.field}は「000-0000-0000」の形式で入力してください。`;
    });
    defineRule<string, any>("url", (value, _, ctx) => {
        if (value?.trim().length === 0) {
            return true;
        }
        let m = value?.trim().match(/^https?:\/\/[\w/:%#$&?()~.,=+\-{}]+$/);
        if (m === null) {
            m = value?.trim().match(/{\d+}/);
        }
        if (m) {
            return true;
        }
        return `${ctx.field}は「http://〜」「https://〜」の形式で入力してください。`;
    });
    defineRule<string, any>("multiByteUrl", (value, _, ctx) => {
        if (value?.trim().length === 0) {
            return true;
        }
        // マルチバイト文字を含んだURLの場合に備え一旦入力内容をencodeして評価する
        const encodedValue = value ? encodeURI(value.trim()) : null;
        let m = encodedValue?.match(/^https?:\/\/[\w/:%#$&?()~.,=+\-{}]+$/);
        if (m === null) {
            m = value?.trim().match(/{\d+}/);
        }
        if (m) {
            return true;
        }
        return `${ctx.field}は「http://〜」「https://〜」の形式で入力してください。`;
    });
    defineRule<string, any>("multiUrl", (value, _, ctx) => {
        if (value?.trim().length === 0) {
            return true;
        }
        let m = value?.trim().match(REGEX_MULTI_URL_OR_BLANK);
        if (m === null) {
            m = value?.trim().match(/{\d+}/);
        }
        if (m) {
            return true;
        }
        return `${ctx.field}は「http://〜」「https://〜」の形式で入力してください。`;
    });
    defineRule<string[], any>("ipCIDR", (values, _, ctx) => {
        if (!values) {
            return true;
        }
        for (const value of values) {
            const m = value
                ?.trim()
                .match(
                    /^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2])){0,1}$/
                );
            if (m === null) {
                return "不正な形式のIPアドレスが含まれています";
            }
        }
        return true;
    });
    defineRule<string[], string[]>(
        "chipMax",
        (values: string[], [max]: string[], ctx: FieldValidationMetaInfo) => {
            if (!values || values.length <= parseInt(max, 10)) {
                return true;
            }
            return `${ctx.field}は${max}個以内にしてください。`;
        }
    );
}
export const validator: Record<string, any> = {
    required: {
        message: "{_field_}は必須項目です。",
        validate(value: any, params): boolean {
            // note: ロジックはvee-validateのrequired ruleの実装に従っている
            // https://github.com/logaretm/vee-validate/blob/main/packages/rules/src/required.ts

            // 判定させたくないときはvalidatorに"false"というfieldを渡してチェックをスキップする
            const enabled = params && params.length > 0 ? params[0] === "true" : true;
            if (!enabled) {
                return true;
            }

            // null判定及び空配列判定
            const isNullOrUndefined = value === null || value === undefined;
            const isEmptyArray = Array.isArray(value) && value.length === 0;
            if (isNullOrUndefined || isEmptyArray) {
                return false;
            }
            // チェックボックス等へのチェックが必須という意味(booleanのrequired条件はtrue必須のみ設定してください)
            if (value === false) {
                return false;
            }

            // 上記以外はstringとみなし、空文字・未入力の場合はfalseが返る
            return !!String(value).trim().length;
        },
        computesRequired: true,
    },
    maxLength: {
        message(field: string, params: Record<string, any>): string {
            return `${field}は${params.max}文字以内で入力してください。`;
        },
        validate(value: string, params: Record<string, any>): boolean {
            // 全角・半角を区別するため、strLengthを使用
            return strLength(value) <= parseInt(params.max, 10);
        },
        params: ["max"],
    },
    maxLengthUTF: {
        message(field: string, params: Record<string, any>): string {
            // 全角・半角を区別しない
            return `${field}は${params.max}文字以内で入力してください。`;
        },
        validate(value, params: Record<string, any>): boolean {
            // 全角・半角を区別しない
            return value.length <= parseInt(params.max, 10);
        },
        params: ["max"],
    },
    maxLines: {
        message(field: string, params: Record<string, any>): string {
            return `${field}は${params.max}行以内で入力してください。`;
        },
        validate(value: string, params: Record<string, any>): boolean {
            const list = value.split("\n").filter((l) => l !== "");
            return list.length <= parseInt(params.max, 10);
        },
        params: ["max"],
    },
    postalCode: {
        message: "{_field_}は「000-0000」の形式で入力してください。",
        validate(value: string): boolean {
            const m = value.match(/^〒?\d{3}-?\d{4}$/);
            return m != null;
        },
    },
    administrativeArea: {
        message: "{_field_}は正式名称を入力してください。",
        validate(value: string, params: Record<string, any>): boolean {
            if (Object.keys(administrativeAreaList).includes(params.regionCode)) {
                return administrativeAreaList[params.regionCode].includes(value);
            } else {
                // TODO: 国際対応
                return true;
            }
        },
        params: ["regionCode"],
    },
    phoneNumber: {
        message: "{_field_}は「000-0000-0000」の形式で入力してください。",
        validate(value): boolean {
            // TODO: 国際対応
            const all = value.match(/^(\(0\d{1,3}\)|0\d{1,3}-)\d{1,4}-\d{1,4}$/);
            const mobile = value.match(/^(\(0[5789]0\)|0[5789]0-)/);
            return all != null && value.replace(/[-()]/g, "").length <= (mobile != null ? 11 : 10);
        },
    },
    url: {
        message: "{_field_}は「http://〜」「https://〜」の形式で入力してください。",
        validate(value: string): boolean {
            if (value?.trim().length === 0) {
                return true;
            }
            let m = value?.trim().match(/^https?:\/\/[\w/:%#$&?()~.,=+\-{}/*]+$/);
            if (m === null) {
                m = value?.trim().match(/{\d+}/);
            }
            return m != null;
        },
    },
    multiByteUrl: {
        message: "{_field_}は「http://〜」「https://〜」の形式で入力してください。",
        validate(value: string): boolean {
            if (value?.trim().length === 0) {
                return true;
            }
            // マルチバイト文字を含んだURLの場合に備え一旦入力内容をencodeして評価する
            const encodedValue = value ? encodeURI(value.trim()) : null;
            let m = encodedValue?.match(/^https?:\/\/[\w/:%#$&?()~.,=+\-{}/*]+$/);
            if (m === null) {
                m = value?.trim().match(/{\d+}/);
            }
            return m != null;
        },
    },
    multiUrl: {
        message: "{_field_}は「http://〜」「https://〜」の形式で入力してください。",
        validate(value: string): boolean {
            if (value?.trim().length === 0) {
                return true;
            }
            let m = value?.trim().match(REGEX_MULTI_URL_OR_BLANK);
            if (m === null) {
                m = value?.trim().match(/{\d+}/);
            }
            return m != null;
        },
    },
    ipCIDR: {
        message: "不正な形式のIPアドレスが含まれています",
        validate(values): boolean {
            for (const value of values) {
                const m = value
                    ?.trim()
                    .match(
                        /^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2])){0,1}$/
                    );
                if (m === null) {
                    return false;
                }
            }
            return true;
        },
    },
    chipMax: {
        message: "{_field_}は{max}個以内にしてください。",
        validate(value: string[], params: Record<string, any>): boolean {
            return !value || value.length <= parseInt(params.max, 10);
        },
        params: ["max"],
    },
};

// 文字数をカウント（全角は2、半角は1）
export const strLength = (str: string, isUtf: boolean = true): number => {
    let count = 0;
    const setEncode = isUtf ? "UTF-8" : "Shift_JIS";

    for (let i = 0, len = str.length; i < len; i++) {
        const c = str.charCodeAt(i);
        if (setEncode === "UTF-8") {
            if (
                (c >= 0x0 && c < 0x81) ||
                c === 0xf8f0 ||
                (c >= 0xff61 && c < 0xffa0) ||
                (c >= 0xf8f1 && c < 0xf8f4)
            ) {
                count += 1;
            } else {
                count += 2;
            }
        } else if (setEncode === "Shift_JIS") {
            if (
                (c >= 0x0 && c < 0x81) ||
                c === 0xa0 ||
                (c >= 0xa1 && c < 0xdf) ||
                (c >= 0xfd && c < 0xff)
            ) {
                count += 1;
            } else {
                count += 2;
            }
        }
    }
    return count;
};

// 国ごとの行政区域のリスト（日本ならば都道府県）
export const administrativeAreaList = {
    JP: [
        "北海道",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
    ],
};

export const symbolCharacterBlocks = [
    [0x00a9, 0x00a9], // ©
    [0x00ae, 0x00ae], // ®
    [0x19e0, 0x19ff], // クメール文字用記号
    [0x2100, 0x214f], // 文字様記号
    [0x2300, 0x23ff], // その他の記述用記号
    [0x2400, 0x243f], // 制御機能用記号
    [0x2440, 0x245f], // 工学的文字認識
    [0x2500, 0x257f], // 罫線素片
    [0x2580, 0x259f], // ブロック要素
    [0x25a0, 0x25ff], // 幾何学模様
    [0x2600, 0x26ff], // その他の記号
    [0x2700, 0x27bf], // 装飾記号
    [0x2b00, 0x2bff], // その他の記号及び矢印
    [0x1f000, 0x1f02f], // マージャン記号
    [0x1f030, 0x1f09f], // ドミノ記号
    [0x1f0a0, 0x1f0ff], // トランプ記号
    [0x1f300, 0x1f5ff], // その他の記号及び絵記号
    [0x1f600, 0x1f64f], // 顔文字
    [0x1f680, 0x1f6ff], // 交通及び地図記号
    [0x1f700, 0x1f77f], // 錬金術記号
    [0x1f900, 0x1f9ff], // 補助記号及び絵記号
];

// Vuetify用のValidator（V2投稿などで使用）
export const VuetifyValidator = {
    required: (v: any): string | boolean => {
        if (typeof v === "string" && v.trim() === "") {
            return "この項目は入力必須です";
        }
        return !!v || "この項目は入力必須です";
    },
    minLength: (min: number): ((v: any) => string | boolean) => {
        // 全角・半角を問わない
        return (v) => {
            return (
                !v ||
                (v && min <= String(v).length) ||
                `${min}文字以上にしてください(現在${String(v).length}文字)`
            );
        };
    },
    minLengthWithoutCounter: (min: number): ((v: any) => string | boolean) => {
        // 全角・半角を問わない
        return (v) => {
            return !v || (v && min <= String(v).length) || `${min}文字以上にしてください`;
        };
    },
    maxLength: (max: number): ((v: any) => string | boolean) => {
        // 全角・半角を問わない
        return (v) => {
            return (
                !v ||
                (v && String(v).length <= max) ||
                `${max}文字以内にしてください(現在${String(v).length}文字)`
            );
        };
    },
    maxLengthWithoutCounter: (max: number): ((v: any) => string | boolean) => {
        // 全角・半角を問わない
        return (v) => {
            return !v || (v && String(v).length <= max) || `${max}文字以内にしてください`;
        };
    },
    maxPriceLength: (max: number): ((v: any) => string | boolean) => {
        // 全角・半角を問わない
        return (v) => {
            return !v || (v && String(v).length <= max) || `${max}桁以内にしてください`;
        };
    },
    minPrice: (min: string): ((v: any) => string | boolean) => {
        return (v) => {
            return (
                !v ||
                !min ||
                (v && min && Number(min) <= Number(v)) ||
                `最低価格より大きい金額を設定してください`
            );
        };
    },
    maxPrice: (max: string): ((v: any) => string | boolean) => {
        return (v) => {
            return (
                !v ||
                !max ||
                (v && max && Number(max) >= Number(v)) ||
                `最高価格より小さい金額を設定してください`
            );
        };
    },
    positiveNumber: (v: string): string | boolean => {
        if (v === "") {
            return true;
        }
        const n = Number(v);
        if (isNaN(n) || n < 0) {
            // 注意: メソッド名とは異なり 0 の場合も認めている
            return "正の値を設定してください";
        }
        return true;
    },
    decimalPoint: (v: string): string | boolean => {
        if (v === "") {
            return true;
        }
        // 指数表記は認めない
        if (/e/.test(v)) {
            return "固定小数点表記で入力してくください";
        }
        const ss = v.split(".");
        return ss.length === 1 || ss[1].length <= 2 || "小数点第二位までで設定してください";
    },
    url: (v: any): string | boolean => {
        if (v?.trim().length === 0) {
            return true;
        }
        const m = v?.trim().match(/^https?:\/\/[\w/:%#$&?()~.,=+\-{}/*]+$/);
        return m !== null || `「http://〜」「https://〜」の形式で入力してください`;
    },
    multiByteUrl: (v: any): string | boolean => {
        if (v?.trim().length === 0) {
            return true;
        }

        // マルチバイト文字を含んだURLの場合に備え一旦入力内容をencodeして評価する
        const encodedValue = v ? encodeURI(v.trim()) : null;
        const m = encodedValue?.match(/^https?:\/\/[\w/:%#$&?()~.,=+\-{}/*]+$/);
        return m !== null || `「http://〜」「https://〜」の形式で入力してください`;
    },
    maxHash: (max: number): ((v: any) => string | boolean) => {
        return (v) => {
            if (v?.trim().length === 0) {
                return true;
            }
            const m = v?.trim().match(/#[^ #]+/g);
            return !m || m.length <= max || `ハッシュタグは${max}個以内にしてください`;
        };
    },
    noEmoji: (v: string | number): string | boolean => {
        if (!v) {
            return true;
        }
        const match = String(v).match(EMOJI_REGEX);
        if (match) {
            return `無効な文字 [${match}] が入力されています`;
        }
        return true;
    },
    noSymbol: (v: string | number): string | boolean => {
        if (!v) {
            return true;
        }
        const text = String(v);
        const characters = [];
        for (const c of text) {
            for (const range of symbolCharacterBlocks) {
                const cp = c.codePointAt(0);
                if (range[0] <= cp && cp <= range[1]) {
                    characters.push(c);
                }
            }
        }
        if (characters.length > 0) {
            return `無効な記号 [${characters}] が入力されています`;
        }
        return true;
    },
    prohibitedYahooChars: (v: string | number): string | boolean => {
        if (!v) {
            return true;
        }
        // Yahoo!プレイス投稿でのみ使用不可の文字（シフトJISの文字コードで指定されているため、Unicodeで範囲指定できない）
        const prohibitedRegex = new RegExp(
            `[` +
                // 半角では使用できない文字
                `<>'"&¥《》` +
                // 13区の特殊文字（8740-879F）
                `№㏍℡㊤㊥㊦㊧㊨㈱㈲㈹㍾㍽㍼㍻㍉㎜㎝㎞㎎㎏㏄㍉㌔㌢㍍㌘㌧㌃㌶㍑㍗㌍㌦㌣㌫㍊㌻①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ∑∮∟⊿` +
                // IBM拡張文字（FA40-FC4B）+ NEC拡張文字（F040-F9FC）
                `ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ㈱№℡纊褜鍈銈蓜俉炻昱棈鋹曻彅丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏塚增墲夋奓奛奝奣妤妺孖寀甯寘寬尞岦岺峵崧嵓﨑嵂嵭嶸嶹巐弡弴彧德忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻桒柀栁桄棏﨓楨﨔榘槢樰橫橆橳橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵濵瀅瀇瀨炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珉珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠緖繒罇羡羽茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞都鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒隆﨩隝隯霳霻靃靍靏靑靕顗顥飯飼餧館馞驎髙髜魵魲鮏鮱鮻鰀鵰鵫鶴鸙黑` +
                `]`,
            "g" // 全件マッチのフラグ
        );
        const matches = String(v).match(prohibitedRegex);
        if (matches) {
            return `Yahooで禁止された文字 [${matches.join(", ")}] が入力されています`;
        }
        return true;
    },
};

/**
 * cheetah grid等で使うためのバリデーション関数
 */
export function validateMaxLength(value: any, max: number): string {
    // 全角・半角を問わない
    return !value || (value && String(value).length <= max) ? "" : `${max}文字以内にしてください`;
}
export function validateDecimalPoint(
    value: string,
    minDecimalPlaces: number = 0,
    maxDecimalPlaces: number = 2
): string {
    if (value === "") {
        return "";
    }
    // 指数表記は認めない
    if (/e/.test(value)) {
        return "固定小数点表記で入力してください";
    }
    const ss = value.split(".");
    if (ss.length === 1) {
        // 小数点がない場合
        return minDecimalPlaces === 0
            ? ""
            : `小数点以下${minDecimalPlaces}位以上で設定してください`;
    }
    const decimalLength = ss[1].length;
    if (decimalLength < minDecimalPlaces) {
        return `小数点以下${minDecimalPlaces}位以上で設定してください`;
    }
    if (decimalLength > maxDecimalPlaces) {
        return `小数点以下${maxDecimalPlaces}位までで設定してください`;
    }
    return "";
}
