export function uniq<T>(arr: T[], callbackFn?: (a: T, b: T) => boolean): T[] {
    if (!callbackFn) {
        return arr.filter((value, index, self) => self.indexOf(value) === index);
    }
    return arr.filter(
        (value, index, self) => self.findIndex((v) => callbackFn(value, v)) === index
    );
}

export function sort<T>(arr: T[]): T[] {
    if (arr.every((value) => typeof value === "number")) {
        return ([...arr] as number[]).sort((a, b) => a - b) as T[];
    } else {
        return [...arr].sort();
    }
}
export function containsAny<T>(arr: T[], values: T[]): boolean {
    return values.some((value) => arr.includes(value));
}
export function equals<T>(a: T[], b: T[]): boolean {
    return a.length === b.length && a.every((el, i) => el === b[i]);
}
