<template>
  <div class="preview-area">
    <post-preview
      v-model="gbpStepEnabled"
      :name="postSteps.PostCustomize.childStep[0].name"
      icon="$google"
      color="#34a853"
      :readonly="readonly"
      @update:model-value="changePostData"
      @update-step="updateStepGBP"
    >
      <template #preview-body>
        <gmb-preview
          ref="preview_0"
          v-model="googlePost"
          :base-text="baseText.gmbBaseText"
          :target-name="targetName"
          :file-selections="files.googleFiles"
          :step="postSteps.PostCustomize.childStep[0]"
          :aspect-no-check="gbpAspectNoCheck"
          :is-edit="isEdit"
          :readonly="readonly"
          @update:model-value="changePostData"
          @update-step="updateStepGBP"
          @edit-image="editImage"
        />
      </template>
    </post-preview>
    <post-preview
      v-model="yahooStepEnabled"
      :name="postSteps.PostCustomize.childStep[1].name"
      icon="$yahoo"
      color="#ff0033"
      :disabled="!platformEnabled.yahooPlace"
      :readonly="readonly"
      @update:model-value="changePostData"
      @update-step="updateStepYahoo"
    >
      <template #preview-body>
        <yahoo-preview
          ref="preview_1"
          v-model="yahooPost"
          :base-text="baseText.yahooBaseText"
          :target-name="targetName"
          :file-selections="files.yahooFiles"
          :step="postSteps.PostCustomize.childStep[1]"
          :is-edit="isEdit"
          :readonly="readonly"
          @update:model-value="changePostData"
          @update-step="updateStepYahoo"
          @edit-image="editImage"
        />
      </template>
    </post-preview>
    <post-preview
      v-model="igStepEnabled"
      :name="postSteps.PostCustomize.childStep[2].name"
      icon="$instagram"
      color="#E1306C"
      :disabled="!platformEnabled.instagram"
      :readonly="readonly"
      @update:model-value="changePostData"
      @update-step="updateStepIG"
    >
      <template #preview-body>
        <ig-preview
          ref="preview_2"
          v-model="instagramText"
          :base-text="baseText.igBaseText"
          :target-name="targetName"
          :file-selections="files.instagramFiles"
          :step="postSteps.PostCustomize.childStep[2]"
          :aspect-no-check="false"
          :readonly="readonly"
          @update:model-value="changePostData"
          @update-step="updateStepIG"
          @edit-image="editImage"
        />
      </template>
    </post-preview>
    <post-preview
      v-model="fbStepEnabled"
      :name="postSteps.PostCustomize.childStep[3].name"
      icon="$facebook"
      color="#2577F2"
      :disabled="!platformEnabled.facebook"
      :readonly="readonly"
      @update:model-value="changePostData"
      @update-step="updateStepFB"
    >
      <template #preview-body>
        <fb-preview
          ref="preview_3"
          v-model="facebookText"
          :base-text="baseText.fbBaseText"
          :target-name="targetName"
          :file-selections="files.facebookFiles"
          :step="postSteps.PostCustomize.childStep[3]"
          :aspect-no-check="false"
          :readonly="readonly"
          @update-step="updateStepFB"
          @edit-image="editImage"
        />
      </template>
    </post-preview>
    <post-preview
      v-model="htStepEnabled"
      :name="postSteps.PostCustomize.childStep[4].name"
      icon="$hosting"
      color=""
      :disabled="!platformEnabled.hosting"
      :readonly="readonly"
      @update:model-value="changePostData"
      @update-step="updateStepHosting"
      @edit-image="editImage"
    >
      <template #preview-body>
        <hosting-preview
          ref="preview_4"
          v-model="hostingPost"
          :base-text="baseText.htBaseText"
          :target-name="targetName"
          :file-selections="files.hostingFiles"
          :step="postSteps.PostCustomize.childStep[4]"
          :aspect-no-check="true"
          :readonly="readonly"
          @update-step="updateStepHosting"
          @edit-image="editImage"
        ></hosting-preview>
      </template>
    </post-preview>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PostSteps } from "./post-stepper.vue";
import type { FileSelectionItem, EditImageSelected } from "@/models/v2-file-selection";
import type { StepStatus } from "./post-stepper.vue";
import type { PostGMBForm } from "./gmb/gmb-request";
import type { YahooPostForm } from "./yahoo/yahoo-request";
import type { HostingPostForm } from "./hosting/hosting-request";
import PostPreview from "./post-preview.vue";
import GmbPreview from "./gmb/gmb-preview.vue";
import YahooPreview from "./yahoo/yahoo-preview.vue";
import IgPreview from "./ig/ig-preview.vue";
import FbPreview from "./fb/fb-preview.vue";
import HostingPreview from "./hosting/hosting-preview.vue";

export type PreviewInstance =
  | InstanceType<typeof GmbPreview>
  | InstanceType<typeof YahooPreview>
  | InstanceType<typeof IgPreview>
  | InstanceType<typeof FbPreview>
  | InstanceType<typeof HostingPreview>;

type BaseText = {
  gmbBaseText: string;
  yahooBaseText: string;
  igBaseText: string;
  fbBaseText: string;
  htBaseText: string;
};

type MediaFiles = {
  googleFiles: FileSelectionItem[];
  yahooFiles: FileSelectionItem[];
  instagramFiles: FileSelectionItem[];
  facebookFiles: FileSelectionItem[];
  hostingFiles: FileSelectionItem[];
};

type PlatformEnabled = {
  instagram: boolean;
  yahoo: boolean;
  facebook: boolean;
  yahooPlace: boolean;
  hosting: boolean;
};

export default defineComponent({
  components: { PostPreview, GmbPreview, YahooPreview, IgPreview, FbPreview, HostingPreview },
  props: {
    postSteps: { type: Object as () => PostSteps, required: true },
    postGMBForm: { type: Object as () => PostGMBForm, required: true }, // v-model
    postYahooForm: { type: Object as () => YahooPostForm, required: true }, // v-model
    igText: { type: String, required: true }, // v-model
    fbText: { type: String, required: true }, // v-model
    baseText: { type: Object as () => BaseText, required: true },
    targetName: { type: String, required: true },
    files: { type: Object as () => MediaFiles, required: true },
    gbpAspectNoCheck: { type: Boolean, required: true },
    isEdit: { type: Boolean, required: true },
    platformEnabled: { type: Object as () => PlatformEnabled, required: true },
    readonly: { type: Boolean, default: false },
    postHostingForm: { type: Object as () => HostingPostForm, required: true }, // v-model
  },
  emits: [
    "update:modelValue",
    "edit-image",
    "update-form:gbp",
    "update-form:yahoo",
    "update-text:ig",
    "update-text:fb",
    "update-form:ht",
    "update-step:gbp",
    "update-step:yahoo",
    "update-step:ig",
    "update-step:fb",
    "update-step:ht",
  ],
  computed: {
    gbpStepEnabled: {
      get() {
        return this.$props.postSteps.PostCustomize.childStep[0].enabled;
      },
      set(value: boolean) {
        const newPostSteps = { ...this.postSteps };
        newPostSteps.PostCustomize.childStep[0].enabled = value;
        this.$emit("update:modelValue", newPostSteps);
      },
    },
    yahooStepEnabled: {
      get() {
        return this.$props.postSteps.PostCustomize.childStep[1].enabled;
      },
      set(value: boolean) {
        const newPostSteps = { ...this.postSteps };
        newPostSteps.PostCustomize.childStep[1].enabled = value;
        this.$emit("update:modelValue", newPostSteps);
      },
    },
    igStepEnabled: {
      get() {
        return this.$props.postSteps.PostCustomize.childStep[2].enabled;
      },
      set(value: boolean) {
        const newPostSteps = { ...this.postSteps };
        newPostSteps.PostCustomize.childStep[2].enabled = value;
        this.$emit("update:modelValue", newPostSteps);
      },
    },
    fbStepEnabled: {
      get() {
        return this.$props.postSteps.PostCustomize.childStep[3].enabled;
      },
      set(value: boolean) {
        const newPostSteps = { ...this.postSteps };
        newPostSteps.PostCustomize.childStep[3].enabled = value;
        this.$emit("update:modelValue", newPostSteps);
      },
    },
    htStepEnabled: {
      get() {
        return this.$props.postSteps.PostCustomize.childStep[4].enabled;
      },
      set(value: boolean) {
        const newPostSteps = { ...this.postSteps };
        newPostSteps.PostCustomize.childStep[4].enabled = value;
        this.$emit("update:modelValue", newPostSteps);
      },
    },
    googlePost: {
      get() {
        return this.$props.postGMBForm;
      },
      set(value: PostGMBForm) {
        this.$emit("update-form:gbp", value);
        this.$emit("update:modelValue", value);
      },
    },
    yahooPost: {
      get() {
        return this.$props.postYahooForm;
      },
      set(value: YahooPostForm) {
        this.$emit("update-form:yahoo", value);
        this.$emit("update:modelValue", value);
      },
    },
    instagramText: {
      get() {
        return this.$props.igText;
      },
      set(value: string) {
        this.$emit("update-text:ig", value);
        this.$emit("update:modelValue", value);
      },
    },
    facebookText: {
      get() {
        return this.$props.fbText;
      },
      set(value: string) {
        this.$emit("update-text:fb", value);
        this.$emit("update:modelValue", value);
      },
    },
    hostingPost: {
      get() {
        return this.$props.postHostingForm;
      },
      set(value: HostingPostForm) {
        this.$emit("update-form:ht", value);
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    changePostData(value: any) {
      this.$emit("update:modelValue", value);
    },
    updateStepGBP(step: StepStatus) {
      this.$emit("update-step:gbp", step);
    },
    updateStepYahoo(step: StepStatus) {
      this.$emit("update-step:yahoo", step);
    },
    updateStepIG(step: StepStatus) {
      this.$emit("update-step:ig", step);
    },
    updateStepFB(step: StepStatus) {
      this.$emit("update-step:fb", step);
    },
    updateStepHosting(step: StepStatus) {
      this.$emit("update-step:ht", step);
    },
    editImage(selected: EditImageSelected) {
      this.$emit("edit-image", selected);
    },
    isCompleted(index: number) {
      (this.$refs[`preview_${index}`] as PreviewInstance)?.isCompleted();
    },
    onAspectNoCheckChange(): void {
      (this.$refs.preview_0 as InstanceType<typeof GmbPreview>).validateFiles();
      (this.$refs.preview_1 as InstanceType<typeof YahooPreview>).validateFiles();
      (this.$refs.preview_2 as InstanceType<typeof IgPreview>).validateFiles();
      (this.$refs.preview_3 as InstanceType<typeof FbPreview>).validateFiles();
      (this.$refs.preview_4 as InstanceType<typeof HostingPreview>).validateFiles();
    },
  },
});
</script>
<style lang="scss" scoped></style>
