export const poolData = {
    UserPoolId: import.meta.env.VITE_APP_COGNITO_USER_POOL_ID,
    ClientId: import.meta.env.VITE_APP_COGNITO_CLIENT_ID,
    Storage: sessionStorage,
};
// Google OAuth 2.0 クライアント ID 「ローカルサーチ Google My Business API用」
export const googleOauthClientId: string =
    "225207582211-41dvkmbc45og5lhteg056sn08cbout2h.apps.googleusercontent.com";
// Yahoo OAuth 2.0 クライアント ID
export const yahooOauthClientId: string =
    "dj00aiZpPTFxM1A5WHlLWWhneiZzPWNvbnN1bWVyc2VjcmV0Jng9OWU-";

export const fbAppID: string = import.meta.env.VITE_APP_FACEBOOK_APP_ID;
/* Facebookのアクセス許可のリスト */
export const fbScope: string[] = [
    "business_management",
    "pages_show_list",
    "pages_manage_metadata",
    "pages_manage_posts",
    "pages_read_engagement",
    "pages_read_user_content",
    "read_insights",
];
/* Instagramのアクセス許可リスト */
export const igScope: string[] = [
    "business_management",
    "pages_show_list",
    "pages_read_engagement",
    "instagram_basic",
    "instagram_manage_insights",
    "instagram_content_publish",
];

export const colors: string[] = ["#EE9D26", "#54D8FF", "#EF5350", "#19F79E", "#9813C3"];
export const colorGroups: string[][] = [
    ["#EE9D26", "#FFF0CC", "#E3CC9C", "#CE8F00"],
    ["#54D8FF", "#E2F8FF", "#A2CED9", "#419DB9"],
    ["#8DD120", "#E4F8C4", "#B2D37E", "#6BA809"],
    ["#ED6DED", "#FCE1FC", "#DEABDE", "#BD57BD"],
    ["#7387EB", "#E3E8FF", "#AFB7E0", "#3E57D6"],
    ["#ED4D4D", "#F8E2E2", "#D9ABAB", "#B10E0E"],
];
export const strokes: number[] = [2, 5, 3, 3];
export const lineDashes: string[] = ["", "", "15, 4", "7, 5", "1, 1", "5, 2, 5"];
export const bgcolors: string[] = [
    "rgba(246, 171, 0, 0.28)",
    "rgba(84, 216, 255, 0.28)",
    "rgba(239, 83, 80, 0.28)",
    "rgba(227, 248, 26, 0.28)",
    "rgba(152, 19, 195, 0.28)",
];

/** ISO-8601のDに対応する日本語名 */
export const dayOfWeekJa: { [day: string]: string } = {
    1: "月",
    2: "火",
    3: "水",
    4: "木",
    5: "金",
    6: "土",
    7: "日",
};

/** 比率の計算において0除算が発生したときの表示文言 */
export const ZERO_DIV_RATE = "0%";

export const apiBase: string = `${import.meta.env.VITE_APP_API_BASE}v1`;

export const moduleMap = {
    1: "HomeVisit",
    2: "RivalVisit",
    3: "Locations",
};

export const dictionary = {
    ja: {
        attributes: {
            name: "企業名",
            industrialType: "業種",
            userLimit: "ユーザ数上限",
            domain: "ドメイン",
            userName: "名前",
            email: "メールアドレス",
            role: "権限",
            companyName: "会社名",
            sectionName: "部署名",
            fullName: "氏名",
            nameKana: "ふりがな",
            phone: "電話番号",
            industrialTypeName: "業種名",
            minDwell: "最低足切り時間(秒)",
            maxDwell: "最大足切り時間(秒)",
            latitude: "緯度",
            longitude: "経度",
            radius: "半径",
            poiCategory: "カテゴリ",
            poiName: "店舗名",
            openAt: "開店時間",
            closeAt: "閉店時間",
        },
    },
};

export const titles = {
    beta: "Beta",
    companies: "企業管理",
    adminStores: "店舗管理",
    users: "ユーザー管理",
    usersEdit: "プロファイル管理",
    customRoles: "権限管理",
    areas: "グループ管理",
    stores: "店舗設定管理",
    gmbPostHistory: "投稿履歴",
    citationSettings: "Facebook/Instagram 連携設定",
    CitationSettingsYplace: "Yahoo!プレイス 連携設定",
    CitationSettingsApple: "Apple Business Connect連携設定",
};

export const routeNames = {
    beta: "Beta",
    companies: "AdminCompanies",
    company: "AdminCompaniesEdit",
    adminStores: "AdminStores",
    users: "Users",
    usersEdit: "UsersEdit",
    customRoles: "CustomRoles",
    areas: "Areas",
    stores: "Stores",
    gmbPostHistory: "GmbPostHistories",
    citationSettings: "CitationSettings",
    citationSettingsYplace: "CitationSettingsYplace",
    citationSettingsApple: "CitationSettingsApple",
};

export const MAX_STORES_PER_AREA = 5;
export const DISABLE_DELETE_ORG = "connectom";
export const COOKIE_KEY_TUTORIAL = "tutorial";
export const MONTH_NAMES: string[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((x) => `${x}月`);
export const LATLNG_DIGITS = 5;
export const TOAST_DURATION = 5000;
// TOAST_DURATION の 5 秒が短く感じたためクリティカルなものについては秒数を伸ばすことにした。
// TODO: 上記は toast 全般に言える話であるが全体の見直しについては後回しにした。
export const TOAST_CRITICAL_DURATION = 10000;

export const DIFF_CARD_STATUS = {
    LOADING: 0,
    LOADED: 1,
    ERROR: -1,
};

export const OLDEST_METRICS_DATE = "2018-01-01";
export const OLDEST_REVIEW_DATE = "2005-01-01";

export const sentimentLevels = [
    { value: "VeryUnfavorable", name: "とても批判的" },
    { value: "Unfavorable", name: "批判的" },
    { value: "BitUnfavorable", name: "やや批判的" },
    { value: "Neutral", name: "どちらともいえない" },
    { value: "BitFavorable", name: "やや好意的" },
    { value: "Favorable", name: "好意的" },
    { value: "VeryFavorable", name: "とても好意的" },
];

// 2020/5/20時点のhttps://schema.org/docs/full.html#datatype_treeから書き写したカテゴリ
export interface LocalBusinessType {
    name: string;
    subcategories?: Array<LocalBusinessType>;
}

export const localBusinessTypeHierarchy: Array<LocalBusinessType> = [
    {
        name: "AnimalShelter",
        subcategories: [],
    },
    {
        name: "ArchiveOrganization",
        subcategories: [],
    },
    {
        name: "AutomotiveBusiness",
        subcategories: [
            {
                name: "AutoBodyShop",
            },
            {
                name: "AutoDealer",
            },
            {
                name: "AutoPartsStore",
            },
            {
                name: "AutoRental",
            },
            {
                name: "AutoRepair",
            },
            {
                name: "AutoWash",
            },
            {
                name: "GasStation",
            },
            {
                name: "MotorcycleDealer",
            },
            {
                name: "MotorcycleRepair",
            },
        ],
    },
    {
        name: "ChildCare",
        subcategories: [],
    },
    {
        name: "Dentist",
        subcategories: [],
    },
    {
        name: "DryCleaningOrLaundry",
        subcategories: [],
    },
    {
        name: "EmergencyService",
        subcategories: [
            {
                name: "FireStation",
            },
            {
                name: "Hospital",
            },
            {
                name: "PoliceStation",
            },
        ],
    },
    {
        name: "EmploymentAgency",
        subcategories: [],
    },
    {
        name: "EntertainmentBusiness",
        subcategories: [
            {
                name: "AdultEntertainment",
            },
            {
                name: "AmusementPark",
            },
            {
                name: "ArtGallery",
            },
            {
                name: "Casino",
            },
            {
                name: "ComedyClub",
            },
            {
                name: "MovieTheater",
            },
            {
                name: "NightClub",
            },
        ],
    },
    {
        name: "FinancialService",
        subcategories: [
            {
                name: "AccountingService",
            },
            {
                name: "AutomatedTeller",
            },
            {
                name: "BankOrCreditUnion",
            },
            {
                name: "InsuranceAgency",
            },
        ],
    },
    {
        name: "FoodEstablishment",
        subcategories: [
            {
                name: "Bakery",
            },
            {
                name: "BarOrPub",
            },
            {
                name: "Brewery",
            },
            {
                name: "CafeOrCoffeeShop",
            },
            {
                name: "Distillery",
            },
            {
                name: "FastFoodRestaurant",
            },
            {
                name: "IceCreamShop",
            },
            {
                name: "Restaurant",
            },
            {
                name: "Winery",
            },
        ],
    },
    {
        name: "GovernmentOffice",
        subcategories: [
            {
                name: "PostOffice",
            },
        ],
    },
    {
        name: "HealthAndBeautyBusiness",
        subcategories: [
            {
                name: "BeautySalon",
            },
            {
                name: "DaySpa",
            },
            {
                name: "HairSalon",
            },
            {
                name: "HealthClub",
            },
            {
                name: "NailSalon",
            },
            {
                name: "TattooParlor",
            },
        ],
    },
    {
        name: "HomeAndConstructionBusiness",
        subcategories: [
            {
                name: "Electrician",
            },
            {
                name: "GeneralContractor",
            },
            {
                name: "HVACBusiness",
            },
            {
                name: "HousePainter",
            },
            {
                name: "Locksmith",
            },
            {
                name: "MovingCompany",
            },
            {
                name: "Plumber",
            },
            {
                name: "RoofingContractor",
            },
        ],
    },
    {
        name: "InternetCafe",
        subcategories: [],
    },
    {
        name: "LegalService",
        subcategories: [
            {
                name: "Attorney",
            },
            {
                name: "Notary",
            },
        ],
    },
    {
        name: "Library",
        subcategories: [],
    },
    {
        name: "LodgingBusiness",
        subcategories: [
            {
                name: "BedAndBreakfast",
            },
            {
                name: "Campground",
            },
            {
                name: "Hostel",
            },
            {
                name: "Hotel",
            },
            {
                name: "Motel",
            },
            {
                name: "Resort",
            },
        ],
    },
    {
        name: "MedicalBusiness",
        subcategories: [
            {
                name: "CommunityHealth",
            },
            {
                name: "Dentist",
            },
            {
                name: "Dermatology",
            },
            {
                name: "DietNutrition",
            },
            {
                name: "Emergency",
            },
            {
                name: "Geriatric",
            },
            {
                name: "Gynecologic",
            },
            {
                name: "MedicalClinic",
                subcategories: [
                    {
                        name: "CovidTestingFacility",
                    },
                ],
            },
            {
                name: "Midwifery",
            },
            {
                name: "Nursing",
            },
            {
                name: "Obstetric",
            },
            {
                name: "Oncologic",
            },
            {
                name: "Optician",
            },
            {
                name: "Optometric",
            },
            {
                name: "Otolaryngologic",
            },
            {
                name: "Pediatric",
            },
            {
                name: "Pharmacy",
            },
            {
                name: "Physician",
            },
            {
                name: "Physiotherapy",
            },
            {
                name: "PlasticSurgery",
            },
            {
                name: "Podiatric",
            },
            {
                name: "PrimaryCare",
            },
            {
                name: "Psychiatric",
            },
            {
                name: "PublicHealth",
            },
        ],
    },
    {
        name: "ProfessionalService",
        subcategories: [],
    },
    {
        name: "RadioStation",
        subcategories: [],
    },
    {
        name: "RealEstateAgent",
        subcategories: [],
    },
    {
        name: "RecyclingCenter",
        subcategories: [],
    },
    {
        name: "SelfStorage",
        subcategories: [],
    },
    {
        name: "ShoppingCenter",
        subcategories: [],
    },
    {
        name: "SportsActivityLocation",
        subcategories: [
            {
                name: "BowlingAlley",
            },
            {
                name: "ExerciseGym",
            },
            {
                name: "GolfCourse",
            },
            {
                name: "HealthClub",
            },
            {
                name: "PublicSwimmingPool",
            },
            {
                name: "SkiResort",
            },
            {
                name: "SportsClub",
            },
            {
                name: "StadiumOrArena",
            },
            {
                name: "TennisComplex",
            },
        ],
    },
    {
        name: "Store",
        subcategories: [
            {
                name: "AutoPartsStore",
            },
            {
                name: "BikeStore",
            },
            {
                name: "BookStore",
            },
            {
                name: "ClothingStore",
            },
            {
                name: "ComputerStore",
            },
            {
                name: "ConvenienceStore",
            },
            {
                name: "DepartmentStore",
            },
            {
                name: "ElectronicsStore",
            },
            {
                name: "Florist",
            },
            {
                name: "FurnitureStore",
            },
            {
                name: "GardenStore",
            },
            {
                name: "GroceryStore",
            },
            {
                name: "HardwareStore",
            },
            {
                name: "HobbyShop",
            },
            {
                name: "HomeGoodsStore",
            },
            {
                name: "JewelryStore",
            },
            {
                name: "LiquorStore",
            },
            {
                name: "MensClothingStore",
            },
            {
                name: "MobilePhoneStore",
            },
            {
                name: "MovieRentalStore",
            },
            {
                name: "MusicStore",
            },
            {
                name: "OfficeEquipmentStore",
            },
            {
                name: "OutletStore",
            },
            {
                name: "PawnShop",
            },
            {
                name: "PetStore",
            },
            {
                name: "ShoeStore",
            },
            {
                name: "SportingGoodsStore",
            },
            {
                name: "TireShop",
            },
            {
                name: "ToyStore",
            },
            {
                name: "WholesaleStore",
            },
        ],
    },
    {
        name: "TelevisionStation",
        subcategories: [],
    },
    {
        name: "TouristInformationCenter",
        subcategories: [],
    },
    {
        name: "TravelAgency",
        subcategories: [],
    },
    {
        name: "MedicalOrganization",
        subcategories: [
            {
                name: "Dentist",
            },
            {
                name: "DiagnosticLab",
            },
            {
                name: "Hospital",
            },
            {
                name: "MedicalClinic",
            },
            {
                name: "Pharmacy",
            },
            {
                name: "Physician",
            },
            {
                name: "VeterinaryCare",
            },
        ],
    },
    {
        name: "NGO",
        subcategories: [],
    },
    {
        name: "NewsMediaOrganization",
        subcategories: [],
    },
    {
        name: "PerformingGroup",
        subcategories: [
            {
                name: "DanceGroup",
            },
            {
                name: "MusicGroup",
            },
            {
                name: "TheaterGroup",
            },
        ],
    },
    {
        name: "Project",
        subcategories: [
            {
                name: "FundingAgency",
            },
            {
                name: "ResearchProject",
            },
        ],
    },
    {
        name: "SportsOrganization",
        subcategories: [
            {
                name: "SportsTeam",
            },
        ],
    },
    {
        name: "WorkersUnion",
        subcategories: [],
    },
];
