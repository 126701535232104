<template>
  <v-card>
    <v-card-title class="text-h5">{{ message }}</v-card-title>
    <v-card-text class="ml-8">
      {{ name }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="0 < cancelButton.length" variant="text" @click="cancel()">
        {{ cancelButton }}
      </v-btn>
      <v-btn flat color="primary" @click="submit()">
        {{ submitButton }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "" },
    cancelButton: { type: String, required: false, default: "キャンセル" },
    submitButton: { type: String, required: false, default: "OK" },
  },
  emits: ["cancel", "submit"],
  async created() {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    submit() {
      this.$emit("submit");
    },
  },
});
</script>
<style lang="scss" scoped></style>
