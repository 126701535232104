<template>
  <div ref="thisroot" style="position: fixed">
    <div class="v2-post-head">
      <div class="v2-post-menu-container">
        <button class="followed file-post-button back-button" @click.prevent="backToHistories()">
          履歴に戻る
        </button>
        <div class="d-flex align-center">
          <template v-if="mode == 'review' && isApproveAuthorization">
            <!-- レビュー -->
            <o-button
              data-testid="v2-post-approve"
              variant="primary"
              size="small"
              class="small-button mr-2"
              :disabled="!isImported || postIsExecuted || hasError"
              @click="openApproveConfirm = true"
            >
              承認
            </o-button>
            <approve-modal
              :open="openApproveConfirm"
              title="承認"
              :message="approvalMessage"
              :cautions="approvalCautions"
              :remark="approveRemark"
              @close-dialog="openApproveConfirm = false"
              @submit="approve"
            />
            <o-button
              data-testid="v2-post-reject"
              variant="info"
              color="light-blue"
              size="small"
              class="small-button mr-2"
              :disabled="!isImported || postIsExecuted"
              @click="openRejectConfirm = true"
            >
              却下
            </o-button>
            <approve-modal
              :open="openRejectConfirm"
              title="却下"
              message="投稿申請を却下します"
              :cautions="[
                '却下すると、申請者にメールが通知されて、申請者が却下理由を確認できます。',
              ]"
              :remark="rejectRemark"
              remark-required
              @close-dialog="openRejectConfirm = false"
              @submit="reject"
            />
          </template>
          <template v-else>
            <!-- 通常投稿 -->
            <o-upload
              v-if="canManage"
              v-model="xlsxFile"
              accept=".xlsx"
              @update:model-value="uploadPostFile()"
            >
              <o-button
                tag="a"
                variant="primary"
                size="small"
                class="small-button mr-2"
                :disabled="readonly"
              >
                XLSXアップロード
              </o-button>
            </o-upload>
            <o-upload
              v-if="canManage"
              v-model="imageFiles"
              :multiple="true"
              accept=".jpg,.jpeg,.png,.mov,.mp4,.zip"
              @update:model-value="uploadImageFiles()"
            >
              <o-button
                tag="a"
                variant="primary"
                size="small"
                class="small-button mr-2"
                :disabled="!isImported || readonly"
              >
                画像アップロード
              </o-button>
            </o-upload>
            <template v-if="isRequestAuthorization && canManage">
              <!-- 承認要求権限の場合 -->
              <o-button
                data-testid="v2-post"
                variant="primary"
                size="small"
                class="small-button mr-2"
                :disabled="!isImported || postIsExecuted || hasError"
                @click="confirmOpenRequest()"
              >
                承認要求
              </o-button>
              <confirm-modal
                :open-confirm="openRequestConfirm"
                :is-approve-request="true"
                :is-manual-approve-select="isManualApproveSelect"
                :disabled="!isImported || postIsExecuted"
                :messages="cautionMessages"
                @close-dialog="openRequestConfirm = false"
                @submit-post="request"
              />
            </template>
            <template v-else>
              <!-- 通常投稿の場合 -->
              <o-button
                v-if="canManage"
                data-testid="v2-post"
                variant="primary"
                size="small"
                class="small-button mr-2"
                :disabled="!isImported || postIsExecuted || hasError"
                @click="confirmOpenRequest()"
              >
                投稿
              </o-button>
              <confirm-modal
                :open-confirm="openRequestConfirm"
                :is-approve-request="false"
                :disabled="!isImported || postIsExecuted"
                :messages="cautionMessages"
                @close-dialog="openRequestConfirm = false"
                @submit-post="executePost"
              />
            </template>
          </template>
          <o-button
            variant="primary"
            size="small"
            class="small-button"
            :disabled="!isImported"
            @click="exportTemplate()"
          >
            エクスポート
          </o-button>
          <v-text-field
            v-model="postData.title"
            variant="underlined"
            density="compact"
            single-line
            hide-details
            label="投稿タイトル"
            color="primary"
            :rules="[rules.maxLength(80)]"
            :readonly="readonly"
          ></v-text-field>
        </div>
      </div>
      <div class="search-container">
        <div class="input-box">
          <v-text-field
            v-model="searchWord"
            data-testid="search-word"
            label="検索キーワード"
            variant="underlined"
            density="compact"
            single-line
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
            style="padding-top: 4px"
            color="primary"
            @keypress.enter="searchStores(false)"
            @click:clear="searchStores(true)"
          ></v-text-field>
          <o-button
            class="search-button"
            data-testid="filter-by-search-word"
            variant="primary"
            size="small"
            @click="searchStores(false)"
          >
            絞り込み
          </o-button>
          <span class="filter-count">{{ showStores() }}件表示中</span>
        </div>
      </div>
    </div>

    <div class="post-data-container">
      <div>
        <div class="platform-tabs">
          <v-btn
            :disabled="!gbpHeaders.main.length"
            :class="platformType === 0 ? 'tab tab-color-selected' : 'tab tab-color-normal'"
            depressed
            @click="changeTab(0)"
          >
            GBP{{ gbpHeaders.main.length ? `（NG:${gbpNGCount}）` : "" }}
          </v-btn>
          <v-btn
            :disabled="!fbHeaders.main.length"
            :class="platformType === 1 ? 'tab tab-color-selected' : 'tab tab-color-normal'"
            depressed
            @click="changeTab(1)"
          >
            Facebook {{ fbHeaders.main.length ? `（NG:${fbNGCount}）` : "" }}
          </v-btn>
          <v-btn
            :disabled="!igHeaders.main.length"
            :class="platformType === 2 ? 'tab tab-color-selected' : 'tab tab-color-normal'"
            depressed
            @click="changeTab(2)"
          >
            Instagram {{ igHeaders.main.length ? `（NG:${igNGCount}）` : "" }}
          </v-btn>
          <v-btn
            :disabled="!yahooHeaders.main.length"
            :class="platformType === 3 ? 'tab tab-color-selected' : 'tab tab-color-normal'"
            depressed
            @click="changeTab(3)"
          >
            Yahoo!プレイス {{ yahooHeaders.main.length ? `（NG:${yahooNGCount}）` : "" }}
          </v-btn>
          <v-btn
            :disabled="!hostingHeaders.main.length"
            :class="platformType === 4 ? 'tab tab-color-selected' : 'tab tab-color-normal'"
            depressed
            @click="changeTab(4)"
          >
            ホスティング連動 {{ hostingHeaders.main.length ? `（NG:${hostingNGCount}）` : "" }}
          </v-btn>
          <v-checkbox
            v-model="displayOnlyErrorItems"
            label="エラー行のみ表示"
            color="primary"
            hide-details
          />
          <v-tooltip>
            <template #activator="{ props }">
              <div v-bind="props">
                <v-checkbox
                  v-model="gbpAspectNoCheck"
                  label="Google Business Profile画像の縦横比チェックを行なわない"
                  color="primary"
                  hide-details
                  :disabled="readonly"
                  @change="changeAspectNoCheckValidate()"
                ></v-checkbox>
              </div>
            </template>
            <span>オンにすると、Googleマップ上で画像が見切れる可能性が高くなります</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="
          (platformType === 0 && gbpHeaders.main.length) ||
          (platformType === 1 && fbHeaders.main.length) ||
          (platformType === 2 && igHeaders.main.length) ||
          (platformType === 3 && yahooHeaders.main.length) ||
          (platformType === 4 && hostingHeaders.main.length)
        "
      >
        <div v-if="platformType === 0">
          <v-2-post-file-table :data="gbpPostData" :headers="gbpHeaders" />
        </div>
        <div v-else-if="platformType === 1">
          <v-2-post-file-table :data="fbPostData" :headers="fbHeaders" />
        </div>
        <div v-else-if="platformType === 2">
          <v-2-post-file-table :data="igPostData" :headers="igHeaders" />
        </div>
        <div v-else-if="platformType === 3">
          <v-2-post-file-table :data="yahooPostData" :headers="yahooHeaders" />
        </div>
        <div v-else-if="platformType === 4">
          <v-2-post-file-table :data="hostingPostData" :headers="hostingHeaders" />
        </div>
      </div>
      <div v-else style="margin: 20px 10px; color: #404040">
        {{ loadInfoMessage }}
      </div>
    </div>
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <div class="d-flex flex-column align-center justify-center">
        <div class="text-h5 text-white">{{ circularMessage }}</div>
        <v-progress-circular :size="80" :width="4" color="primary" indeterminate />
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative } from "vue-facing-decorator";
import wordDictionary from "@/word-dictionary";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import type { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { Watch } from "vue-facing-decorator";
import { parallels } from "@/helpers/parallel-promise";
import ApproveModal from "../v2-posts/approve-modal.vue";

import type {
  EntitiesV2PostData,
  ControllersWorkDirCreateInput,
  ControllersWorkDirCreateOutput,
  ControllersWorkDirUploadInput,
  ControllersWorkDirUploadOutput,
  ControllersWorkDirValidateOutput,
  DomainV2BulkPostWorkDirValidate,
  ControllersWorkDirBulkPostInput,
  ControllersWorkDirBulkPostOutput,
  DomainV2BulkPostWorkDirRecord,
  ControllersApproveJudgmentInput,
  ControllersApproveJudgmentOutput,
} from "@/types/ls-api";
import { PostItem } from "./post-item";
import V2PostFileTable from "./v2-post-file-table.vue";
import { TOAST_DURATION, TOAST_CRITICAL_DURATION } from "@/const";
import { requiredAuth } from "@/helpers/request";
import { getOperationLogParams } from "@/routes/operation-log";
import { VuetifyValidator } from "@/helpers";
import {
  determineNewActionType,
  determineEditActionType,
  determineApproveActionType,
  determineRejectActionType,
} from "@/helpers/post-status";
import { Headers } from "./post-item";
import { useSnackbar } from "@/storepinia/snackbar";
import { getter, action } from "@/storepinia/idxdb";
import ConfirmModal from "./confirm-modal.vue";

enum PlatformType {
  Google = 0,
  Facebook = 1,
  Instagram = 2,
  Yahoo = 3,
  Hosting = 4,
}

@Component({
  components: {
    V2PostFileTable,
    ApproveModal,
    ConfirmModal,
  },
})
class V2PostFile extends Vue {
  rules: typeof VuetifyValidator = VuetifyValidator;
  targetPostFile: string; // 編集対象の指示ファイルのパス（v2-post/5/history/user-uuid/post-uuid.json）

  company = getter().company;
  areas = getter().areas;
  user = getter().user;
  canManage = getter().canManagePost;
  postRow = getter().postRow;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  // 承認要求はrequest / 通常投稿は空 / レビュー時はreview
  @Prop({ default: "" }) mode: string;
  // レビュー時に承認要求者が設定したAspectNoCheckの値を受け取る
  @Prop({ default: "" }) defaultAspectNoCheck: string;
  isEdit: boolean = false;
  postIsExecuted: boolean = false;
  loadInfoMessage: string =
    "[XLSXアップロード]ボタンから投稿テンプレートXLSXをアップロードしてください。";
  gridHeight: number = 100;
  // 新規投稿権限がある場合はtrue
  isCreateAuthorization = getter().canPostCreate;
  // 編集権限がある場合はtrue
  isEditAuthorization = getter().canPostEdit;
  // 削除権限がある場合はtrue
  isDeleteAuthorization = getter().canPostDelete;
  // 承認権限がある場合はtrue
  isApproveAuthorization = getter().canPostApprove;
  // 承認要求権限の場合はtrue
  isRequestAuthorization = getter().canPostRequest;

  setPostRow = action().setPostRow;
  // 承認時ダイアログ表示用
  openApproveConfirm: boolean = false;
  approveRemark: string = "";
  // 承認却下時ダイアログ表示用
  openRejectConfirm: boolean = false;
  rejectRemark: string = "";
  // 承認要求時ダイアログ表示用
  openRequestConfirm: boolean = false;
  // 承認時に手動で承認者を選択できる場合にtrueを返す
  get isManualApproveSelect(): boolean {
    return this.company.options?.includes("v2-post-approval-manual");
  }
  get isReview(): boolean {
    return this.mode === "review"; // 承認レビューはtrue(承認者向け)
  }
  get readonly(): boolean {
    return this.isReview || !this.canManage;
  }

  get cautionMessages(): string[] {
    const dict = wordDictionary.v2post.caution;
    const caution = [dict.publicationDelayWarning];
    if (this.postData?.xlsxGMB === "product") {
      caution.push(dict.gbpProduct);
    }
    if (this.postData?.xlsxInstagram === "organic") {
      caution.push(dict.instagram);
    }
    if (this.postData?.xlsxFacebook === "organic") {
      caution.push(dict.facebook);
    }
    if (this.isRequestAuthorization && this.canManage) {
      caution.push(dict.reviewRequest);
    }
    return caution;
  }

  /** 承認時にダイアログに出すメッセージ */
  get approvalMessage(): string {
    return this.postRow?.judgementRequestIsDelete ? "投稿削除を承認します" : "投稿申請を承認します";
  }

  /** 承認時にダイアログに出す注意事項 */
  get approvalCautions(): string[] {
    const caution = [wordDictionary.v2post.caution.postApproval];
    if (this.postRow?.xlsxGMB === "product") {
      caution.push(wordDictionary.v2post.caution.gbpProduct);
    }
    return caution;
  }

  // グリッドに表示するフォントサイズ
  gridFontSize = 12.8;
  // グリッドに表示するフォント
  gridFont = `${this.gridFontSize}px sans-serif`;
  // 検索キーワード
  searchWord = "";

  loading: boolean = true;
  circularMessage = "";
  tab = "gbp";
  workDir: ControllersWorkDirCreateOutput = { uuid: "", baseUrl: "" };
  xlsxFile: File = null;
  imageFiles: File[] = [];
  platformType = PlatformType.Google;
  displayOnlyErrorItems: boolean = false;
  gbpAspectNoCheck: boolean = false;

  // XLSXアップロードが完了して、作業フォルダができているか（エクスポートボタンの非活性）
  isImported: boolean = false;

  // アップロード毎にカウントアップして、サムネイルのキャッシュを異なるものに変える
  uploadCounter: number = 1;
  // ヘッダの定義
  gbpHeaders: Headers = new Headers();
  fbHeaders: Headers = new Headers();
  igHeaders: Headers = new Headers();
  yahooHeaders: Headers = new Headers();
  hostingHeaders: Headers = new Headers();
  // すべての投稿データを保持するリスト
  gbpPostDataAll: any[] = [];
  fbPostDataAll: any[] = [];
  igPostDataAll: any[] = [];
  yahooPostDataAll: any[] = [];
  hostingPostDataAll: any[] = [];
  // 絞り込み後の投稿データを保持するリスト
  gbpPostData: any[] = [];
  fbPostData: any[] = [];
  igPostData: any[] = [];
  yahooPostData: any[] = [];
  hostingPostData: any[] = [];
  // エラー数
  gbpNGCount: number = 0;
  fbNGCount: number = 0;
  igNGCount: number = 0;
  yahooNGCount: number = 0;
  hostingNGCount: number = 0;

  validateResult: DomainV2BulkPostWorkDirValidate = null;
  hasError = false;

  postData: EntitiesV2PostData = {
    title: "",
    xlsxGMB: null,
    xlsxFacebook: null,
    xlsxInstagram: null,
    xlsxYahoo: null,
    xlsxHosting: null,
  };
  get gbpType(): string {
    switch (this.postData?.xlsxGMB) {
      case "info":
        return "最新情報";
      case "event":
        return "イベント";
      case "benefits":
        return "特典";
      case "product":
        return "商品";
      case "covid19":
        return "Covid19";
    }
    return "";
  }
  get fbType(): string {
    return this.postData?.xlsxFacebook === "organic" ? "Facebook" : "";
  }
  get igType(): string {
    return this.postData?.xlsxInstagram === "organic" ? "Instagram" : "";
  }
  get YahooType(): string {
    switch (this.postData?.xlsxYahoo) {
      case "NOTICE":
        return "お知らせ";
      case "DAYOFF":
        return "営業状況";
      case "CAMPAIGN":
        return "キャンペーン";
      case "ETC":
        return "その他";
    }
    return "";
  }
  get HostingType(): string {
    return this.postData?.xlsxHosting === "normal" ? "ホスティング" : "";
  }

  async backToHistories(): Promise<void> {
    this.setPostRow(null);
    await this.$router.push({ name: "V2PostHistories" });
  }

  handleResize(): void {
    const el = this.$refs.thisroot as HTMLElement;
    const width = window.innerWidth - el.offsetLeft - 18;
    const height = window.innerHeight - el.offsetTop - 18;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
  }

  // cheetah-gridのスクロールバーだけ表示したいのでbodyのを消す
  mounted(): void {
    document.body.style.overflow = "hidden";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
  // ページ離脱時bodyのスクロールバーを元に戻しとく
  unmounted(): void {
    document.body.style.overflow = "initial";
    window.removeEventListener("resize", this.handleResize);
  }

  queryToString(value: string | (string | null)[] | undefined): string | undefined {
    return Array.isArray(value) ? value[0] || "" : value;
  }

  async created(): Promise<void> {
    this.loading = true;

    // 投稿編集時は対象のfilenameをクエリパラメータから読み込み
    // 例: v2-post/5/history/43dfa894-7340-43c7-96ab-67db88fa493f/20220825_061306_2f3aa51d-7fd3-4ffb-a4df-cd4f6f5a297d.json
    this.targetPostFile = this.queryToString(this.$route.query?.filename);
    this.gbpAspectNoCheck = this.defaultAspectNoCheck.toLowerCase() === "true";
    // 空文字だったら(レビュー依頼ではなかった場合)企業設定を使う
    if (this.defaultAspectNoCheck === "") {
      this.gbpAspectNoCheck = this.company?.allowAnyAspectRatio?.googleBusinessProfile ?? false;
    }

    // 編集・レビュー時
    if (this.targetPostFile) {
      // 作業フォルダの作成(投稿指示ファイルを読み取って、作業フォルダ内に関連ファイルをまとめてくれる)
      await this.executeCreateWorkDir(this.targetPostFile);
      if (this.workDir.uuid === "") {
        return;
      }

      // 編集モード
      this.isEdit = true;
      this.isImported = true;

      // 作業フォルダの作成直後はバリデート済みなので、バリデーション処理無しで結果だけを取得する
      const result = await this.getValidate(true, this.gbpAspectNoCheck);
      this.circularMessage = "";
      this.hasError = await this.fetch(result?.validate);
    }
    this.loading = false;
  }

  snackbarOpen(message: SnackbarToast): void {
    if (message === null) {
      return;
    }

    this.addSnackbarMessages({
      ...message,
      timeout: message?.color === "danger" ? TOAST_CRITICAL_DURATION : TOAST_DURATION,
    });
  }

  // validate結果を画面表示(エラーがあればtrueを返す)
  async fetch(validate: DomainV2BulkPostWorkDirValidate = null): Promise<boolean> {
    if (!validate) {
      return false;
    }

    if (validate?.totalErrorCount > 0) {
      this.snackbarOpen({
        text: `投稿内容のうち${validate.totalErrorCount}箇所に過不足があり、修正する必要があります`,
        color: "warning",
      });
    }

    this.setHeaders(validate);

    const title = this.postData.title;
    // actionTypeに必要な情報もセット
    this.postData = {
      poiGroupID: this.company.poiGroupID,
      title: title,
      xlsxGMB: null,
      xlsxFacebook: null,
      xlsxInstagram: null,
      xlsxYahoo: null,
      xlsxHosting: null,
      xlsxMode: true,
      fileNames: this.postRow?.fileNames ?? [],
      judgementRequestIsDelete: this.postRow?.judgementRequestIsDelete ?? false,
    };
    this.postData.title = this.postData.title?.trim() !== "" ? this.postData.title : validate.title; // 投稿タイトルが未入力ならXLSXから読み込む
    this.postData.xlsxGMB = validate.xlsxGMB;
    this.postData.xlsxFacebook = validate.xlsxFacebook;
    this.postData.xlsxInstagram = validate.xlsxInstagram;
    this.postData.xlsxYahoo = validate.xlsxYahoo;
    this.postData.xlsxHosting = validate.xlsxHosting;

    const getData = (data: DomainV2BulkPostWorkDirRecord) => {
      const item: PostItem = new PostItem();
      data.values.forEach((v) => {
        item[v.field] = v.value;
      });
      item["isValid"] = data.isValid;
      return item;
    };
    this.gbpPostDataAll = validate.gmbData?.map(getData);
    this.fbPostDataAll = validate.fbData?.map(getData);
    this.igPostDataAll = validate.igData?.map(getData);
    this.yahooPostDataAll = validate.yahooData?.map(getData);
    this.hostingPostDataAll = validate.hostingData?.map(getData);

    this.gbpNGCount = this.gbpPostDataAll?.filter((item) => !item.isValid).length ?? 0;
    this.fbNGCount = this.fbPostDataAll?.filter((item) => !item.isValid).length ?? 0;
    this.igNGCount = this.igPostDataAll?.filter((item) => !item.isValid).length ?? 0;
    this.yahooNGCount = validate.yahooData?.filter((item) => !item.isValid).length ?? 0;
    this.hostingNGCount = validate.hostingData?.filter((item) => !item.isValid).length ?? 0;

    this.filterPostData();

    // 選択中のタブにデータがなければ、データのあるタブに切り替え
    if (
      (this.platformType === PlatformType.Google && this.gbpHeaders.main.length === 0) ||
      (this.platformType === PlatformType.Facebook && this.fbHeaders.main.length === 0) ||
      (this.platformType === PlatformType.Instagram && this.igHeaders.main.length === 0) ||
      (this.platformType === PlatformType.Yahoo && this.yahooHeaders.main.length === 0) ||
      (this.platformType === PlatformType.Hosting && this.hostingHeaders.main.length === 0)
    ) {
      if (this.gbpHeaders.main.length) {
        this.changeTab(PlatformType.Google);
      } else if (this.fbHeaders.main.length) {
        this.changeTab(PlatformType.Facebook);
      } else if (this.igHeaders.main.length) {
        this.changeTab(PlatformType.Instagram);
      } else if (this.yahooHeaders.main.length) {
        this.changeTab(PlatformType.Yahoo);
      } else if (this.hostingHeaders.main.length) {
        this.changeTab(PlatformType.Hosting);
      }
    }

    if (validate?.totalErrorCount > 0) {
      return true;
    } else {
      return false;
    }
  }

  @Watch("displayOnlyErrorItems")
  filterPostData(): void {
    if (this.displayOnlyErrorItems) {
      this.gbpPostData = this.gbpPostDataAll?.filter((item) => !item.isValid);
      this.fbPostData = this.fbPostDataAll?.filter((item) => !item.isValid);
      this.igPostData = this.igPostDataAll?.filter((item) => !item.isValid);
      this.yahooPostData = this.yahooPostDataAll?.filter((item) => !item.isValid);
      this.hostingPostData = this.hostingPostDataAll?.filter((item) => !item.isValid);
    } else {
      this.gbpPostData = this.gbpPostDataAll;
      this.fbPostData = this.fbPostDataAll;
      this.igPostData = this.igPostDataAll;
      this.yahooPostData = this.yahooPostDataAll;
      this.hostingPostData = this.hostingPostDataAll;
    }
  }

  private setHeaders(validate: DomainV2BulkPostWorkDirValidate = null): void {
    this.gbpHeaders = new Headers(
      validate?.gmbHeader,
      validate?.gmbData,
      this.workDir.baseUrl,
      this.uploadCounter
    );
    this.fbHeaders = new Headers(
      validate?.fbHeader,
      validate?.fbData,
      this.workDir.baseUrl,
      this.uploadCounter
    );
    this.igHeaders = new Headers(
      validate?.igHeader,
      validate?.igData,
      this.workDir.baseUrl,
      this.uploadCounter
    );
    this.yahooHeaders = new Headers(
      validate?.yahooHeader,
      validate?.yahooData,
      this.workDir.baseUrl,
      this.uploadCounter
    );
    this.hostingHeaders = new Headers(
      validate?.hostingHeader,
      validate?.hostingData,
      this.workDir.baseUrl,
      this.uploadCounter
    );
    this.uploadCounter++;
  }

  showStores(): number {
    switch (this.tab) {
      case "gbp":
        return this.gbpPostData?.length;
      case "fb":
        return this.fbPostData?.length;
      case "ig":
        return this.igPostData?.length;
      case "yahoo":
        return this.yahooPostData?.length;
      case "hosting":
        return this.hostingPostData?.length;
    }
    return 0;
  }

  // キーワードをセットして検索を実行
  searchStores(isClear: boolean): void {
    if (isClear) {
      this.searchWord = "";
    }
    if (this.searchWord !== "") {
      this.gbpPostData = this.gbpPostDataAll?.filter((item) =>
        JSON.stringify(item).includes(this.searchWord)
      );
      this.fbPostData = this.fbPostDataAll?.filter((item) =>
        JSON.stringify(item).includes(this.searchWord)
      );
      this.igPostData = this.igPostDataAll?.filter((item) =>
        JSON.stringify(item).includes(this.searchWord)
      );
      this.yahooPostData = this.yahooPostDataAll?.filter((item) =>
        JSON.stringify(item).includes(this.searchWord)
      );
      this.hostingPostData = this.hostingPostDataAll?.filter((item) =>
        JSON.stringify(item).includes(this.searchWord)
      );
    } else {
      this.gbpPostData = this.gbpPostDataAll;
      this.fbPostData = this.fbPostDataAll;
      this.igPostData = this.igPostDataAll;
      this.yahooPostData = this.yahooPostDataAll;
      this.hostingPostData = this.hostingPostDataAll;
    }
  }

  async uploadFiles(files: File[]): Promise<boolean> {
    const results = [];

    const ps = parallels();
    for (const file of files) {
      ps.add(this.uploadFile(file));
      if (ps.size() >= 100) {
        // ps.race(x) の戻り値が、実行しなかった場合のfalseと実行結果のfalseで区別できなかったので、事前にrace(x)を実行するか判定している
        results.push(await ps.race(100)); // 100件づつまとめて並列実行
      }
    }
    results.push(...(await ps.all())); // 余った分を実行

    const okCount = results.filter((r) => r === true)?.length ?? 0;
    const ngCount = results.filter((r) => r === false)?.length ?? 0;
    if (ngCount === 0) {
      this.snackbarOpen({
        text: `${okCount}個のファイルをアップロードしました`,
        color: "success",
      });
    } else {
      this.snackbarOpen({
        text: `${okCount}個のファイルアップロードに成功し、${ngCount}個のファイルアップロードに失敗しました`,
        color: "danger",
      });
    }
    return ngCount === 0;
  }
  async uploadFile(file: File): Promise<boolean> {
    // アップロード用の署名付きURLを取得
    let preSignedURL = "";
    const params: ControllersWorkDirUploadInput = { targetFileName: file.name };

    await requiredAuth<ControllersWorkDirUploadOutput>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.company.poiGroupID}/post/workdir/${
        this.workDir.uuid
      }`,
      null,
      params
    )
      .then((res: AxiosResponse) => {
        if (!res?.data?.url) {
          throw "レスポンスにファイルアップロード用の署名付きURLが含まれなかった";
        }
        preSignedURL = res.data.url;
      })
      .catch((ex) => {
        console.error(ex);

        let message = "作業用ディレクトリのURLの取得に失敗しました";
        if (ex as AxiosError) {
          message = ex.response.data.errorMessage;
        }

        this.snackbarOpen({
          text: message,
          color: "danger",
        });
      });
    if (preSignedURL === "") {
      return false;
    }

    // 署名付きURLを使ってS3に直接アップロード
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        await axios
          .put(preSignedURL, e.target.result, { headers: { "x-amz-acl": "public-read" } }) // 公開状態でアップロード
          .then((res) => {
            resolve(true);
          })
          .catch((ex) => {
            resolve(false);
          });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }
  // XLSXアップロード
  async uploadPostFile(): Promise<void> {
    if (!this.xlsxFile) {
      return;
    }
    this.loading = true;
    if (this.workDir.uuid === "") {
      await this.executeCreateWorkDir();
      if (this.workDir.uuid === "") {
        return;
      }
    }
    // 投稿指示ファイルをアップロード
    this.isImported = await this.uploadFile(this.xlsxFile);
    this.xlsxFile = null;

    // バリデーション結果を取得
    const result = await this.getValidate(false, this.gbpAspectNoCheck);
    this.circularMessage = "";
    this.hasError = await this.fetch(result?.validate);
    this.loading = false;
  }

  // 作業フォルダの作成
  async executeCreateWorkDir(targetPostFile: string = ""): Promise<void> {
    const toastMessage = await this.executeCreateWorkDirWithRetry(targetPostFile);
    this.circularMessage = "";
    if (targetPostFile) {
      // 投稿編集時のみ、投稿指示jsonからテンプレートXLSXを生成して結果をトースト表示
      this.snackbarOpen(toastMessage);
    }
  }
  async editError(errorDetail: string, canRetry: boolean): Promise<SnackbarToast> {
    const message = canRetry
      ? `エラーが発生したため、少し時間をおいてから編集ボタンをもう一度押してください（${errorDetail}）`
      : `エラーが発生したため、この投稿指示は編集できません（${errorDetail}）`;

    await this.backToHistories();
    return {
      text: message,
      color: "danger",
    };
  }
  // 作業フォルダの作成処理を実行（15分までリトライ）
  async executeCreateWorkDirWithRetry(targetPostFile: string): Promise<SnackbarToast> {
    const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
    const isCreate = !targetPostFile;

    let output: ControllersWorkDirCreateOutput;
    for (let retryCount = 0; retryCount < 15 * 60; retryCount++) {
      output = await this.createWorkDir(targetPostFile, isCreate, this.gbpAspectNoCheck);
      this.circularMessage = output.message;
      switch (output?.state) {
        case undefined:
          // 通信エラーなど
          return;
        case 0: //  0 変換処理は開始されていない
        case 1: //	1 変換処理開始(v2XlsxBulkPost lambdaの起動を行った)
          targetPostFile = "";
          this.workDir = output;
          await sleep(1000);
          continue;
        case 10: // 作業ディレクトリ作成完了
          targetPostFile = "";
          this.workDir = output;
          return null;
        case 11: // 11 変換処理開始(v2XlsxBulkPost ECS Taskの起動を行った)
          targetPostFile = "";
          this.workDir = output;
          await sleep(1000);
          continue;
        case 2: //	2 変換処理中(v2XlsxBulkPost lambda/ECS Task共通)
          targetPostFile = "";
          await sleep(1000);
          continue;
        case 3: //  3 画像・動画コピー処理中(v2XlsxBulkPost lambda/ECS Task共通)
          targetPostFile = "";
          await sleep(1000);
          continue;
        case 4: //  4 バリデーション処理中(ZIPファイル解凍中)
          targetPostFile = "";
          await sleep(1000);
          continue;
        case 5: //  5 バリデーション処理中(XLSXスキャン中)
          targetPostFile = "";
          await sleep(1000);
          continue;
        case 100: //	100 変換処理完了(v2XlsxBulkPost lambdaの処理が完了して、生成された作業フォルダ内に投稿内容が再現された)
          this.workDir = output;
          return null; // 成功するのは当たり前なのでトースト表示なし
        case 110: //  110 タイムアウト検出
          return await this.editError("タイムアウトを検出しました", true);
        case 111: // 111 validateエラー(最後に実行されたバリデーションでエラーが解消されていない このあと再実行可能)
          return {
            text: "投稿内容に修正の必要な箇所があります",
            color: "warning",
          };
        case 103: // 	103 バリデーション処理失敗(lambda XLSX アクセス失敗・XLSXを再度アップロードしてください)
          return await this.editError("XLSXファイルへのアクセス失敗", true);
        case 105: //  105 バリデーション処理失敗(lambda DynamoDB アクセス失敗・リトライしてください)
          return await this.editError("バリデーション処理失敗(DynamoDB アクセス失敗)", true);
        case 106: //  106 バリデーション処理失敗(lambda 內部エラー・リトライしてください)
          return await this.editError("バリデーション処理失敗(內部エラー)", true);
        case 107: // 	107 バリデーション処理失敗(lambda ZIP解凍失敗・リトライしてください)
          return await this.editError("バリデーション処理失敗(ZIP解凍失敗)", true);
        case 108: // 	108 バリデーション処理失敗(lambda XLSX フォーマット不正を検出・XLSXを再度アップロードしてください)
          return await this.editError("バリデーション処理失敗(XLSX フォーマット不正を検出)", true);
        case 109: // 	109 バリデーション処理失敗(lambda XLSX 投稿データがセットされていない・XLSXを再度アップロードしてください)
          return await this.editError(
            "バリデーション処理失敗(XLSX 投稿データがセットされていない)",
            true
          );
        case 114: // 	114 変換元の指示ファイル読み込みに失敗 再実行不可
          return await this.editError("変換元の指示ファイル読み込みに失敗", false);
        case 115: //  115 変換元の指示ファイル（プラットフォーム毎）読み込みに失敗 再実行不可
          return await this.editError(
            "変換元の指示ファイル（プラットフォーム毎）読み込みに失敗",
            false
          );
        case 117: //  117 処理不正(XLSXファイル操作失敗) 再実行不可
          return await this.editError("処理不正(XLSXファイル操作失敗)", false);
        case 118: //  118 処理不正(画像・動画ファイル配置失敗 再実行不可
          return await this.editError("処理不正(画像・動画ファイル配置失敗)", false);
        case 201: // 	201 バリデーション処理失敗(ECS Task 起動失敗)
          return await this.editError("バリデーション処理失敗(ECS Task 起動失敗)", true);
        case 203: // 	203 バリデーション処理失敗(ECS Task XLSX アクセス失敗・XLSXを再度アップロードしてください)
          return await this.editError("バリデーション処理失敗(XLSX アクセス失敗)", true);
        case 205: //  205 バリデーション処理失敗(ECS Task DynamoDB アクセス失敗・リトライしてください)
          return await this.editError("バリデーション処理失敗(DynamoDB アクセス失敗)", true);
        case 206: //  206 バリデーション処理失敗(ECS Task 內部エラー・リトライしてください)
          return await this.editError("バリデーション処理失敗(內部エラー)", true);
        case 207: // 	207 バリデーション処理失敗(ECS Task ZIP解凍失敗・リトライしてください)
          return await this.editError("バリデーション処理失敗(ZIP解凍失敗)", true);
        case 208: // 	208 バリデーション処理失敗(ECS Task XLSX フォーマット不正を検出・XLSXを再度アップロードしてください)
          return await this.editError("バリデーション処理失敗(XLSX フォーマット不正を検出)", true);
        case 209: // 	209 バリデーション処理失敗(ECS Task XLSX 投稿データがセットされていない・XLSXを再度アップロードしてください)
          return await this.editError(
            "バリデーション処理失敗(XLSX 投稿データがセットされていない)",
            true
          );
        case 214: // 	214 変換元の指示ファイル読み込みに失敗 再実行不可
          return await this.editError("変換元の指示ファイル読み込みに失敗", false);
        case 215: //  215 変換元の指示ファイル（プラットフォーム毎）読み込みに失敗 再実行不可
          return await this.editError(
            "変換元の指示ファイル（プラットフォーム毎）読み込みに失敗",
            false
          );
        case 217: //  217 処理不正(XLSXファイル操作失敗) 再実行不可
          return await this.editError("処理不正(XLSXファイル操作失敗)", false);
        case 218: //  218 処理不正(画像・動画ファイル配置失敗 再実行不可
          return await this.editError("処理不正(画像・動画ファイル配置失敗", false);

        default:
          await this.backToHistories();
          throw new Error(
            `作業フォルダ作成API(workdir)から想定外のステータスが返ってきた。smessage=${output?.message} state=${output?.state}`
          );
      }
    }
    // 投稿開始処理に15分以上かかった(おそらくエラーになっているので諦める)
    await this.backToHistories();
    return {
      text: `投稿編集処理の開始に時間がかかりすぎています。少し時間をおいてから編集ボタンをもう一度押してください。最後のステータス=${output?.message}（${output?.state}）`,
      color: "danger",
    };
  }
  private convertWorkDirParam(
    targetPostFile: string,
    gbpAspectNoCheck: boolean
  ): ControllersWorkDirCreateInput {
    const ret: ControllersWorkDirCreateInput = {};
    if (targetPostFile) {
      ret.target = targetPostFile;
      ret.gbpAspectNoCheck = gbpAspectNoCheck;
    }
    if (this.workDir.baseUrl) {
      ret.uuid = this.workDir.uuid;
    }
    return ret;
  }
  async createWorkDir(
    targetPostFile: string,
    isCreate: boolean,
    gbpAspectNoCheck: boolean
  ): Promise<ControllersWorkDirCreateOutput> {
    if (isCreate && this.workDir.baseUrl) {
      // 作業フォルダを取得済みならばそれを使う
      return this.workDir;
    }

    let output: ControllersWorkDirCreateOutput = {};
    const params: ControllersWorkDirCreateInput = this.convertWorkDirParam(
      targetPostFile,
      gbpAspectNoCheck
    ); // 新規投稿では空、投稿編集では
    await requiredAuth<ControllersWorkDirCreateOutput>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.company.poiGroupID}/post/workdir`,
      null,
      params
    )
      .then((res: AxiosResponse) => {
        if (!res?.data?.baseUrl) {
          throw "レスポンスに作業用ディレクトリのURLが含まれなかった";
        }
        output = res.data;
      })
      .catch((ex) => {
        console.error(ex);

        let message = "作業用ディレクトリのURLの取得に失敗しました";
        if (ex as AxiosError) {
          message = ex.response.data.errorMessage;
        }

        this.snackbarOpen({
          text: message,
          color: "danger",
        });
      });
    return output;
  }

  // 画像・動画ファイルのアップロード
  async uploadImageFiles(): Promise<void> {
    if (!this.imageFiles || this.imageFiles.length === 0) {
      return;
    }
    this.loading = true;

    // 投稿画像ファイル or zipファイルをアップロード
    this.circularMessage = "ファイルアップロード中";
    await this.uploadFiles(this.imageFiles);
    this.imageFiles.splice(0);

    // バリデーション結果を取得
    const result = await this.getValidate(false, this.gbpAspectNoCheck);
    this.circularMessage = "";
    this.hasError = await this.fetch(result?.validate);

    // 同名ファイルを再アップロードするとブラウザが勝手にキャンセルしてしまうので、再アップロード後はファイルをクリアする
    this.imageFiles = [];
    this.loading = false;
  }

  public async changeAspectNoCheckValidate(): Promise<void> {
    this.loading = true;
    // バリデーション結果を取得
    const result = await this.getValidate(false, this.gbpAspectNoCheck);
    this.circularMessage = "";
    this.hasError = await this.fetch(result?.validate);

    this.loading = false;
  }

  async validateError(errorDetail: string, canRetry: boolean): Promise<SnackbarToast> {
    const message = canRetry
      ? `エラーが発生したため、少し時間をおいてから編集ボタンをもう一度押してください（${errorDetail}）`
      : `エラーが発生したため、この投稿指示は編集できません（${errorDetail}）`;

    return {
      text: message,
      color: "danger",
    };
  }
  // バリデーション結果取得(15分までリトライ)
  async getValidate(
    noValidate: boolean = false,
    gbpAspectNoCheck: boolean = false
  ): Promise<ControllersWorkDirValidateOutput | null> {
    const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
    // APIからバリデーション結果を取得
    try {
      for (let cnt = 0; cnt < 15 * 60; cnt++) {
        const res = await requiredAuth<ControllersWorkDirValidateOutput>(
          "get",
          `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
            this.company.poiGroupID
          }/post/workdir/${this.workDir.uuid}/validate`,
          { noValidate: noValidate, gbpAspectNoCheck: gbpAspectNoCheck }
        );
        if (noValidate) {
          // バリデーション処理を行わずに結果だけを取得する場合
          return res?.data;
        }
        this.circularMessage = res?.data?.validate?.validateMessage;
        if (res?.data?.validate?.validateState < 100) {
          // バリデーション処理中
          await sleep(1000);
        } else {
          if (res?.data?.validate?.validateState == 100) {
            // 処理完了
            return res?.data;
          }
          switch (res?.data?.validate?.validateState) {
            case 101: // lambda 起動失敗
            case 201: // ECS Task 起動失敗
              this.snackbarOpen(
                await this.validateError(
                  `v2XlsxBulkPost 処理起動に失敗 ${res?.data?.validate?.validateState}`,
                  true
                )
              );
              break;
            case 102: // lambda S3アクセス失敗
            case 202: // ECS Task S3アクセス失敗
              this.snackbarOpen(
                await this.validateError(
                  `S3アクセス失敗 ${res?.data?.validate?.validateState}`,
                  true
                )
              );
              break;
            case 103: // lambda XLSX アクセス失敗
            case 203: // ECS Task XLSX アクセス失敗
              this.snackbarOpen(
                await this.validateError(
                  `Excelファイルを再度アップロードしてください ${res?.data?.validate?.validateState}`,
                  false
                )
              );
              break;
            case 104: // lambda 画像・動画アクセス失敗
            case 204: // ECS Task 画像・動画アクセス失敗
              this.snackbarOpen(
                await this.validateError(
                  `画像・動画アクセス失敗 ${res?.data?.validate?.validateState}`,
                  true
                )
              );
              break;
            case 105: // lambda DynamoDBアクセス失敗
            case 205: // ECS Task DynamoDBアクセス失敗
              this.snackbarOpen(
                await this.validateError(
                  `DynamoDBアクセス失敗 ${res?.data?.validate?.validateState}`,
                  true
                )
              );
              break;
            case 106: // lambda 內部エラー
            case 206: // ECS Task 內部エラー
              this.snackbarOpen(
                await this.validateError(`內部エラー ${res?.data?.validate?.validateState}`, true)
              );
              break;
            case 107: // lambda ZIP解凍失敗
            case 207: // ECS Task ZIP解凍失敗
              this.snackbarOpen(
                await this.validateError(
                  `ZIPファイルを再度アップロードしてください ${res?.data?.validate?.validateState}`,
                  true
                )
              );
              break;
            case 108: // lambda XLSXフォーマット不正検出
            case 208: // ECS Task XLSXフォーマット不正検出
              this.snackbarOpen(
                await this.validateError(
                  `Excelファイルフォーマット不正を検出しましたので、修正の上再度アップロードしてください ${res?.data?.validate?.validateState}`,
                  true
                )
              );
              break;
            case 109: // lambda XLSX投稿データ未設定
            case 209: // ECS Task XLSX投稿データ未設定
              this.snackbarOpen(
                await this.validateError(
                  `Excelファイルに投稿データが無かったので、修正の上再度アップロードしてください ${res?.data?.validate?.validateState}`,
                  true
                )
              );
              break;

            default:
              this.snackbarOpen(
                await this.validateError(
                  `想定外のエラー state=${res?.data?.validate?.validateState} message=${res?.data?.validate?.validateMessage}`,
                  false
                )
              );
          }
          return null;
        }
      }
    } catch (ex) {
      this.snackbarOpen({
        text: "バリデーション結果の取得に失敗 " + ex.toString(),
        color: "danger",
      });
      return null;
    }
  }

  // 承認要求の確認・承認者選択ダイアログを開く
  async confirmOpenRequest(): Promise<void> {
    try {
      this.postIsExecuted = true;
      this.loading = true;

      if (this.postData.title?.trim() === "") {
        this.snackbarOpen({ text: "投稿タイトルを入力してください", color: "warning" });
        return;
      }
      if (this.postData.title?.trim().length > 80) {
        this.snackbarOpen({ text: "投稿タイトルは80文字以内にしてください", color: "warning" });
        return;
      }
      this.openRequestConfirm = true;
    } finally {
      this.postIsExecuted = false;
      this.loading = false;
    }
  }
  // 承認要求
  async request(selectUserUUIDList: string[] = [], remark: string = ""): Promise<void> {
    this.postData.judgementWantUserNames = selectUserUUIDList;
    this.postData.judgementRequestRemark = remark;
    await this.executePost();
  }

  // 投稿/承認要求実行
  async executePost(): Promise<void> {
    try {
      this.openRequestConfirm = false;
      this.postIsExecuted = true;
      this.loading = true;

      if (this.postData.title?.trim() === "") {
        this.snackbarOpen({ text: "投稿タイトルを入力してください", color: "warning" });
        return;
      } else if (this.postData.title?.trim().length > 80) {
        this.snackbarOpen({ text: "投稿タイトルは80文字以内にしてください", color: "warning" });
        return;
      }

      const toastMessage = await this.executePostWithRetry();
      this.circularMessage = "";
      if (toastMessage) {
        this.snackbarOpen(toastMessage);
      }
    } finally {
      this.postIsExecuted = false;
      this.loading = false;
    }
  }

  // 承認実行
  async approve(approveRemark: string): Promise<void> {
    const data: ControllersApproveJudgmentInput = {
      fileName: this.targetPostFile,
      judgment: true,
      remark: approveRemark,
    };
    const actionType = await determineApproveActionType(this.postData);
    await requiredAuth<ControllersApproveJudgmentOutput>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
        this.company.poiGroupID
      }/post/approve/judgment`,
      getOperationLogParams(this.$route, actionType, "v2_post"),
      data
    )
      .then(async (res) => {
        if (res.status === 200) {
          this.addSnackbarMessages({
            text: "承認しました",
            color: "success",
          });
          await this.backToHistories();
        } else {
          this.addSnackbarMessages({
            text: "承認に失敗しました : " + res.status.toString(),
            color: "danger",
          });
        }
      })
      .catch((e) => {
        this.addSnackbarMessages({
          text: "承認に失敗しました : " + e.message,
          color: "danger",
        });
      });
  }

  // 承認却下
  async reject(rejectRemark: string): Promise<void> {
    const data: ControllersApproveJudgmentInput = {
      fileName: this.targetPostFile,
      judgment: false,
      remark: rejectRemark,
    };
    const actionType = await determineRejectActionType(this.postData);
    await requiredAuth<ControllersApproveJudgmentOutput>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
        this.company.poiGroupID
      }/post/approve/judgment`,
      getOperationLogParams(this.$route, actionType, "v2_post"),
      data
    )
      .then(async (res) => {
        if (res.status === 200) {
          this.addSnackbarMessages({
            text: "承認却下しました",
            color: "success",
          });
          await this.backToHistories();
        } else {
          this.addSnackbarMessages({
            text: "承認却下に失敗しました : " + res.status.toString(),
            color: "danger",
          });
        }
      })
      .catch((e) => {
        this.addSnackbarMessages({
          text: "承認却下に失敗しました : " + e.message,
          color: "danger",
        });
      });
  }

  // 投稿処理を実行（15分までリトライ）
  async executePostWithRetry(): Promise<SnackbarToast> {
    const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
    let output: ControllersWorkDirBulkPostOutput = null;
    for (let retryCount = 0; retryCount < 15 * 60; retryCount++) {
      output = await this.postBulkPost(this.company.poiGroupID, this.workDir.uuid);
      this.circularMessage = output?.message ?? "";
      switch (output?.state) {
        case undefined:
          // 通信エラーなど
          return;
        case 0: //  0 変換処理は開始されていない
        case 1: //	1 変換処理開始(v2XlsxToBulkPostConverter lambdaの起動を行った)
        case 2: //	2 変換処理中(v2XlsxToBulkPostConverter lambdaが起動して処理を開始した)
          await sleep(1000);
          continue;
        case 3: //	3 変換処理完了(v2XlsxToBulkPostConverter lambdaの処理が完了して、v2post ECS Taskの起動を開始した or DynamoDBへの予約登録が完了した)
          await this.backToHistories();
          return { text: "投稿開始処理に成功しました", color: "success" };
        case 11: // 11 変換処理開始(v2XlsxToBulkPostConverter ECS Taskの起動を行った)
          await sleep(1000);
          continue;
        case 100: //  100 作業ディレクトリがなかった(誤ったuuidを設定した、S3のライフサイクルルールで自動削除された 再実行不可)
          this.isImported = false;
          return {
            text: "エラーが発生したため、お手数ですがXLSXアップロードからやり直してください（作業ディレクトリが消えている）",
            color: "danger",
          };
        case 101: //	101 validateエラー(最後に実行されたバリデーションでエラーが解消されていない このあと再実行可能)
          return {
            text: "投稿内容に修正の必要な箇所があります",
            color: "warning",
          };
        case 102: //  102 v2XlsxToBulkPostConverter lambdaの起動に失敗 このあと再実行可能
          return {
            text: "エラーが発生したため、少し時間をおいてから投稿ボタンをもう一度押してください（v2XlsxToBulkPostConverter lambdaの起動に失敗）",
            color: "danger",
          };
        case 103: //	103 ECSタスク起動失敗(v2post ECS Taskの起動に失敗した このあと再実行可能)
          return {
            text: "エラーが発生したため、少し時間をおいてから投稿ボタンをもう一度押してしてください（v2post ECS Taskの起動に失敗）",
            color: "danger",
          };
        case 104: //	104 作業フォルダ内のファイルリスト取得失敗（リトライしてください）
          return {
            text: "エラーが発生したため、少し時間をおいてから投稿ボタンをもう一度押してしてください（lambda 作業フォルダのファイルリスト取得失敗）",
            color: "danger",
          };
        case 106: //  106 內部エラー(lambda內部で想定外のエラー このあと再実行可能)
          return {
            text: "想定外のエラーが発生したため、少し時間をおいてから投稿ボタンをもう一度押してしてください（lambda 內部エラー）",
            color: "danger",
          };
        case 110: //  110 タイムアウト検出
          return {
            text: "想定外のエラーが発生したため、少し時間をおいてから投稿ボタンをもう一度押してしてください（タイムアウト検出）",
            color: "danger",
          };
        case 204: //	204 作業フォルダ内のファイルリスト取得失敗（リトライしてください）
          return {
            text: "エラーが発生したため、少し時間をおいてから投稿ボタンをもう一度押してしてください（ECS Task 作業フォルダのファイルリスト取得失敗）",
            color: "danger",
          };
        case 206: //  206 內部エラー(lambda內部で想定外のエラー このあと再実行可能)
          return {
            text: "想定外のエラーが発生したため、少し時間をおいてから投稿ボタンをもう一度押してしてください（ECS Task 內部エラー）",
            color: "danger",
          };

        default:
          throw new Error(
            `V2ファイル一括投稿 投稿開始API(bulkpost)から想定外のステータスが返ってきた。smessage=${output?.message} state=${output?.state}`
          );
      }
    }
    // 投稿開始処理に30秒以上かかった(レスポンスは返ってきていて処理自体は継続中)
    await this.backToHistories();
    return {
      text: `投稿開始処理に時間がかかっています。少し待ってからリロードしてください。最後のステータス=${output?.message}（${output?.state}）`,
      color: "success",
    };
  }
  // アップロードしたテンプレートXLSX、画像、動画を使って投稿処理を開始
  async postBulkPost(poiGroupID: number, uuid: string): Promise<ControllersWorkDirBulkPostOutput> {
    const params: ControllersWorkDirBulkPostInput = {};
    params.title = this.postData.title;
    params.judgementWantUserNames = this.postData?.judgementWantUserNames;
    params.remark = this.postData?.judgementRequestRemark;
    const actionType = this.isEdit
      ? await determineEditActionType(this.postData, this.isRequestAuthorization)
      : determineNewActionType(this.postData, this.isRequestAuthorization);
    let output: ControllersWorkDirBulkPostOutput = null;
    await requiredAuth<ControllersWorkDirBulkPostOutput>(
      "post",
      `${
        import.meta.env.VITE_APP_API_BASE
      }v2/companies/${poiGroupID}/post/workdir/${uuid}/bulkpost`,
      getOperationLogParams(this.$route, actionType, "v2_post"),
      params
    )
      .then((res) => {
        output = res?.data;
      })
      .catch((ex) => {
        const errorMessage = ex.response?.data?.errorMessage ?? ex.toString();
        this.snackbarOpen({
          text: "投稿開始処理に失敗 : " + errorMessage,
          color: "danger",
        });
      });
    return output;
  }
  async changeTab(platformType: PlatformType): Promise<void> {
    this.platformType = platformType;
  }
  // エクスポート(テンプレートダウンロード)
  async exportTemplate(): Promise<void> {
    const link = document.createElement("a");
    link.href = this.workDir.baseUrl + "/file.xlsx"; // テンプレートファイルの場所と名前は固定
    link.download = "file.xlsx";
    link.click();
  }
}
export default toNative(V2PostFile);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
@use "@/components/style/button.scss" as button;

.input-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.v2-post-head {
  align-items: flex-end;

  .control-display-checkbox {
    margin-right: 0;
  }
}

.back-button.followed {
  border-radius: 5px;
  color: #404040;
  background-color: white;
  border-color: #d8d9da;
  font-size: 12px;
  height: 34px;
  background-image: url("@/assets/images/arrow-left-black.svg");
  background-position: 12px center;
  background-size: auto 1em;
  padding-top: 7px;
  padding-left: calc(12px + 1.5em);

  &:hover {
    color: #fff;
    border-color: color.$primary-hover;
    background-color: color.$primary-hover;
    background-image: url("@/assets/images/arrow-left-white.svg");
  }
}

.errorDetails {
  white-space: pre-line;
}

.small-button {
  margin-right: 12px;
  width: 120px;
}

.v2-post-params {
  margin-top: 0;
  margin-left: 20px;
  height: 65px;

  .col {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;

    .row {
      height: 35px;

      .col {
        height: 100%;
        padding-top: 0;
        margin-top: 0;

        .input-check {
          padding-top: 0;
          margin-top: 0;
        }
      }
    }

    .input-box {
      margin: 7px 10px 0 0;
      padding: 0;
      width: 320px;
    }
  }
}

.input-date .form-date-picker div div div input {
  padding-top: 1px;
  padding-bottom: 1px;
}

.input-time .v-input div div div input {
  font-size: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.search-container {
  margin-top: -7px;

  .search-button {
    margin-top: 12px;
    margin-left: 20px;
    width: 100px;
  }

  .filter-count {
    margin-left: 10px;
    align-self: flex-end;
  }

  .input-box {
    margin-bottom: 14px;
  }
}

.input-box {
  flex-wrap: wrap;
  align-items: flex-end;
}

.media-tab {
  text-transform: none !important;
}

.post-data-container {
  display: flex;
  flex-flow: column;
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.platform-tabs {
  display: flex;

  button {
    display: block;
    text-transform: none;
  }
}

.custom-grid {
  width: 100%;
  height: calc(100vh - 250px);
}

.v-input--selection-controls {
  margin-top: 5px !important;
}

button.tab {
  border-radius: 10px 10px 0 0;
}

.tab-color-selected {
  background-color: color.$base-orange !important;
  color: white !important;
}

.tab-color-normal {
  background-color: color.$base-grey !important;
  color: black !important;
}
</style>
