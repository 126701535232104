<template>
  <div class="d-flex flex-column p-0">
    <!-- 共通ヘッダ -->
    <header class="fixed-header" :style="`width: calc(100% - ${headerCalcWidth}px)`">
      <div class="fixed-header-wrapper">
        <!-- クチコミダッシュボード -->
        <div class="title-bar">
          <h2>クチコミ分析</h2>
          <div class="d-flex justify-end align-center">
            <div class="primary-bold mr-2">表示期間:</div>
            <month-range-picker v-model="range" :max="maxMonth" :max-duration="12" />
            <button class="button is-primary apply-button ml-3" @click="search">適用</button>
          </div>
        </div>
        <!-- emitでsearchが発火すると表示されるデータが古いものになることがあるので、selectパラメータを渡さない -->
        <StoreSelector v-model="selectedTarget" />
      </div>
    </header>

    <div class="d-flex mb-2" style="margin-top: 150px">
      <div class="text-h6 font-weight-bold me-auto">評価とクチコミ件数</div>
      <v-btn
        color="primary"
        density="comfortable"
        :disabled="exportLoading"
        :loading="exportLoading"
        @click="exportSummary()"
      >
        日毎平均評点推移XLSX
      </v-btn>
    </div>

    <ReviewSummary
      ref="reviewSummary"
      v-model:is-whole-period="isWholePeriod"
      :poi-group-id="company.poiGroupID"
      :selected-target="selectedTarget"
      :from="range.from"
      :to="range.to"
    />

    <div class="d-flex mt-5 mb-2">
      <div class="text-h6 font-weight-bold me-auto">キーワードのポジネガ分析</div>
      <v-btn
        color="primary"
        density="comfortable"
        :disabled="exportLoading"
        :loading="exportLoading"
        @click="exportRanking()"
      >
        キーワードのポジネガXLSX
      </v-btn>
    </div>

    <div class="bg-white p-2">
      <div class="keyword-ranking">
        <!-- ポジネガ分析 -->
        <ReviewKeywordRanking
          ref="reviewsStat"
          :is-good="true"
          :date-to="range.to"
          :date-from="range.from"
          :selected-target="selectedTarget"
          :picked-keyword="goodKeyword"
          @select-ranking-row="selectRankingRow"
        />
        <label for="">
          {{ goodKeyword ? '"' + goodKeyword + '"' : "キーワード" }}を含むクチコミ
        </label>
        <ReviewSearch
          ref="reviewSearch"
          :date-to="range.to"
          :date-from="range.from"
          :is-good="true"
          :selected-target="selectedTarget"
          :selected-keyword="goodKeyword"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";
import { requiredAuth } from "@/helpers";
import { headerCalcWidth } from "@/helpers/header";
import { captureAndThrow } from "@/helpers/error";
import { Component, Vue, toNative } from "vue-facing-decorator";
import type { StorageResponse } from "@/types/ls-api";
import DatePicker from "@/components/shared/date-picker/DatePicker.vue";
import MonthRangePicker from "@/components/shared/date-picker/MonthRangePicker.vue";
import StoreSelector, { SelectedTarget } from "@/components/shared/store-selector.vue";
import ReviewSummary from "./parts/review-summary.vue";
import ReviewKeywordRanking from "./parts/review-keyword-ranking.vue";
import ReviewSearch from "./parts/review-search.vue";
import type { KeywordRankingRow } from "./parts/review-keyword-ranking.vue";
import { useSnackbar } from "@/storepinia/snackbar";
import { getter, useIndexedDb } from "@/storepinia/idxdb";
import { useSs } from "@/storepinia/ss";
import { TOAST_CRITICAL_DURATION } from "@/const";

@Component({
  components: {
    DatePicker,
    MonthRangePicker,
    StoreSelector,
    ReviewSummary,
    ReviewKeywordRanking,
    ReviewSearch,
  },
})
class ReviewAnalysis extends Vue {
  company = getter().company;
  stores = getter().stores;

  maxMonth = dayjs().format("YYYY-MM");
  range = {
    from: useSs().aggregationStartDate.substring(0, 7),
    to: useSs().aggregationEndDate.substring(0, 7),
  };
  selectedTarget: SelectedTarget = SelectedTarget.fromSs();

  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  loading: boolean = false;
  dataExportLoading: boolean = false;
  isWholePeriod: boolean = true;
  // クチコミ キーワードランキングで選択されたキーワード
  goodKeyword = "";
  exportLoading: boolean = false;

  async mounted(): Promise<void> {
    await this.search();
    // 他の店舗セレクタ設置ページから遷移直後、
    // searchが無駄に発火してしまうのを避ける為、
    // クチコミバブルデータ読み込み完了されたタイミングでwatch開始する様にする
    this.$watch("selectedTarget", this.search, { deep: true });
  }

  // 検索の実行(表示期間・表示条件が変更された)
  async search(): Promise<void> {
    if (this.selectedTarget.isNone) {
      return;
    }
    useSs().aggregationStartDate = this.range.from;
    useSs().aggregationEndDate = this.range.to;
    this.loading = true;
    this.goodKeyword = "";
    await this.$nextTick(); // 子コンポーネントのpropsに値が渡るのを待つ
    const promises = [
      (this.$refs.reviewSummary as InstanceType<typeof ReviewSummary>)?.pageLoad(),
      (this.$refs.reviewsStat as InstanceType<typeof ReviewKeywordRanking>)?.pageLoad(),
      (this.$refs.reviewSearch as InstanceType<typeof ReviewSearch>)?.pageLoad(),
    ];
    await Promise.all(promises).finally(() => {
      this.loading = false;
    });
  }

  private getBaseParams(): Record<string, unknown> {
    const params: any = {};
    if (this.range.to) {
      params.endDate = dayjs(this.range.to, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
    }

    if (this.range.from) {
      params.startDate = dayjs(this.range.from, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
    }
    return params;
  }

  // キーワードランキングテーブル側で行選択
  selectRankingRow(item: KeywordRankingRow, isGood: boolean): void {
    this.goodKeyword = item.name;
    this.$nextTick(() =>
      (this.$refs.reviewSearch as InstanceType<typeof ReviewSearch>)?.pageLoad()
    );
  }

  getDownloadParams(): Record<string, unknown> {
    const params: any = this.getBaseParams();

    let areaID = 0;
    if (
      !this.selectedTarget.isAll &&
      this.selectedTarget.isArea &&
      this.selectedTarget.areaId > 0
    ) {
      areaID = this.selectedTarget.areaId;
    }
    params.areaID = areaID;
    let poiID = 0;
    if (
      !this.selectedTarget.isAll &&
      !this.selectedTarget.isArea &&
      this.selectedTarget.poiIds.length > 0 &&
      this.selectedTarget.poiIds[0] > 0
    ) {
      poiID = this.selectedTarget.poiIds[0];
    }
    params.poiID = poiID;

    return params;
  }

  private showSnackbar(): void {
    this.addSnackbarMessages({
      text: "XLSXファイルの作成を開始しました。<br>しばらくしてからレポートダウンロードをご確認ください",
      color: "success",
    });
  }

  private onExportRankingFailed(): void {
    this.addSnackbarMessages({
      text: "レポートファイルの作成に失敗しました",
      timeout: TOAST_CRITICAL_DURATION,
      color: "danger",
    });
  }

  async exportSummary(): Promise<void> {
    this.exportLoading = true;

    const params = this.getDownloadParams();
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
      this.company.poiGroupID
    }/reviews/export-rating-summary`;
    await requiredAuth<StorageResponse>("post", url, null, params)
      .then(async () => {
        this.showSnackbar();
      })
      .catch((e) => captureAndThrow("レポートファイル作成失敗", e));

    this.exportLoading = false;
  }

  async exportRanking(): Promise<void> {
    this.exportLoading = true;

    const params = this.getDownloadParams();
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
      this.company.poiGroupID
    }/reviews/sentiments/stat/downloads`;
    await requiredAuth<StorageResponse>("get", url, params)
      .then(async () => {
        this.showSnackbar();
      })
      .catch((e) => {
        this.onExportRankingFailed();
      });

    this.exportLoading = false;
  }

  // サイドバーの有無で幅を調整する
  get headerCalcWidth(): number {
    return headerCalcWidth(useIndexedDb().isDrawerOpened);
  }
}
export default toNative(ReviewAnalysis);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;
@use "@/components/style/font.scss" as font;

.fixed-header:not(.column.fixed-header) {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  background-color: #fff;
  z-index: calc(var(--z-index-loading) + 1);
}
.fixed-header-wrapper {
  margin-top: 80px;
  padding: 15px 0 10px 30px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}
.title-bar {
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: -20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    margin-top: 2px;
    font-size: 22px;
    font-weight: bold;
  }
  .primary-bold {
    color: var(--primary);
    font-weight: bold;
  }
}

.keyword-ranking {
  label {
    margin-top: 30px;
    display: inline-block;
    padding: 8px 10px 5px 10px;
    border-radius: 3px 3px 0px 0px;
    border-top: 3px solid color.$primary;
    color: color.$primary;
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
