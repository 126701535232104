<template>
  <div class="d-flex flex-column position-relative border-thin m-2 p-2">
    <div>
      <v-btn-toggle
        v-model="chartType"
        mandatory
        density="compact"
        color="primary"
        class="border-thin"
      >
        <v-btn value="amount">件数</v-btn>
        <v-btn value="ratio">割合</v-btn>
      </v-btn-toggle>
    </div>
    <div>
      <div id="star_trend_chart" style="height: 251px"></div>
    </div>
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import dayjs from "dayjs";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { am5FontFamily, initChart } from "@/components/shared/am5-shared";
import { starColors, type RatingSummaryDatum } from "@/components/shared/review-shared";

export default defineComponent({
  props: {
    loading: { type: Boolean, default: true },
    data: {
      type: Array as PropType<RatingSummaryDatum[]>,
      default: () => [],
    },
  },
  data() {
    return {
      root: null as am5.Root,
      starColors,
      chartType: "amount", // amount or ratio
    };
  },
  watch: {
    chartType: {
      immediate: false,
      handler(newVal, oldVal) {
        console.log("chartType", `${newVal} <- ${oldVal}`);
        this.root = draw(this.data, newVal);
      },
    },
    data: {
      immediate: false,
      handler(newVal, _oldVal) {
        console.log("data changed");
        this.root = draw(newVal, this.chartType);
      },
    },
  },
  mounted() {
    this.root = draw(this.data, this.chartType);
  },
  beforeUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  },
});

/** グラフを描画する */
function draw(ds: RatingSummaryDatum[], chartType: string): am5.Root {
  // 表示用のデータを作成する
  type Datum = {
    displayedMonth: string;
    average: number;
    star1: number;
    star2: number;
    star3: number;
    star4: number;
    star5: number;
  };
  const map: { [yyyymm: string]: Datum } = {};
  const d2 = Intl.NumberFormat("ja", { minimumIntegerDigits: 2 });
  ds.forEach((d) => {
    const sum = d.star1 + d.star2 + d.star3 + d.star4 + d.star5;
    map[`${d.year}${d2.format(d.month)}`] = {
      displayedMonth: `${d.year}/${d.month}`,
      average: d.average,
      star1: chartType === "amount" ? d.star1 : (d.star1 / sum) * 100,
      star2: chartType === "amount" ? d.star2 : (d.star2 / sum) * 100,
      star3: chartType === "amount" ? d.star3 : (d.star3 / sum) * 100,
      star4: chartType === "amount" ? d.star4 : (d.star4 / sum) * 100,
      star5: chartType === "amount" ? d.star5 : (d.star5 / sum) * 100,
    };
  });
  const data = [...Array(12)]
    .map((_, i) => dayjs().startOf("month").add(-i, "month"))
    .reverse()
    .map((d) => {
      const yyyymm = `${d.year()}${d2.format(d.month() + 1)}`;
      return (
        map[yyyymm] || {
          displayedMonth: `${d.year()}/${d.month() + 1}`,
          average: undefined,
          star1: 0,
          star2: 0,
          star3: 0,
          star4: 0,
          star5: 0,
        }
      );
    });

  // 以下はグラフの描画処理
  const root = initChart("star_trend_chart");
  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout,
    })
  );

  const legends: am5.Legend = chart.children.push(
    am5.Legend.new(root, {
      nameField: "name",
      x: am5.percent(50),
      centerX: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 6,
        fixedWidthGrid: false,
      }),
    })
  );
  legends.markers.template.setAll({
    width: 10,
    height: 10,
  });
  const defaultFontSize = 10;
  legends.labels.template.setAll({
    fontSize: defaultFontSize,
    marginLeft: 5,
    marginRight: -35,
  });

  const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(root, {
    minGridDistance: 10,
    cellStartLocation: 0.2,
    cellEndLocation: 0.8,
  });
  xRenderer.grid.template.setAll({ location: 0.5 });
  xRenderer.labels.template.setAll({ location: 0.5, multiLocation: 0.5 });

  // ---- X軸(年月) ----
  const categoryAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.2,
      renderer: xRenderer,
      startLocation: 0,
      endLocation: 1,
      dx: 0,
      categoryField: "displayedMonth",
      tooltip: am5.Tooltip.new(root, {}),
    })
  );
  categoryAxis.get("renderer").labels.template.setAll({
    maxWidth: 52,
    // ラベルのフォントサイズ自動調整
    oversizedBehavior: "fit",
    // ラベルのフォントサイズ手動調整
    fontSize: defaultFontSize,
    fontFamily: am5FontFamily,
    fill: am5.color("#333333"),
    paddingTop: 10,
  });
  // チャート下部のツールチップ消す
  categoryAxis.set(
    "tooltip",
    am5.Tooltip.new(root, {
      forceHidden: true,
    })
  );
  categoryAxis.data.setAll(data);

  // ---- Y軸(件数) ----
  const valueAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      min: 0,
    })
  );
  valueAxis.get("renderer").labels.template.setAll({
    fontSize: defaultFontSize,
    fill: am5.color("#333333"),
  });
  if (chartType === "ratio") {
    valueAxis.setAll({
      strictMinMax: true,
      min: 0,
      max: 100,
    });
  }
  const ken: am5.Label = am5.Label.new(root, {
    text: chartType === "ratio" ? "(%)" : "(件)",
    fontSize: defaultFontSize,
    fontFamily: am5FontFamily,
    position: "absolute",
    x: 0,
    y: am5.percent(100),
    fill: am5.color("#333333"),
  });
  valueAxis.children.unshift(ken);

  // ---- Y軸(平均評点) ----
  const averageAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        opposite: true,
        minGridDistance: 20,
      }),
    })
  );
  averageAxis.setAll({
    strictMinMax: true,
    min: 0,
    max: 5,
  });
  const blue = "#42a4e4";
  averageAxis.get("renderer").grid.template.setAll({
    visible: false,
  });
  // 評点の星数ラベルの色
  averageAxis.get("renderer").labels.template.setAll({
    fontSize: defaultFontSize,
    fontFamily: am5FontFamily,
    fill: am5.color(blue),
  });
  const ten: am5.Label = am5.Label.new(root, {
    text: "(点)",
    fontSize: defaultFontSize,
    fontFamily: am5FontFamily,
    position: "absolute",
    x: -5,
    y: am5.percent(100),
    fill: am5.color(blue),
  });
  averageAxis.children.unshift(ten);

  chart.plotContainer.children.push(
    am5.Container.new(root, {
      // id: "scSeriesContainer",
      x: 0,
      y: 0,
      width: am5.percent(100),
      height: am5.percent(100),
    })
  );
  // 支援係に変更届の請求をする。
  // 計画作ってくれたのでサインをしてもらう。
  // 保育園に連絡する。早めに連絡する。
  // 明日には区役所に提出する。
  // 月火にする。

  for (let i = 0; i < 5; i++) {
    const starGrade = i + 1;
    const valueY = `star${starGrade}`;
    const series: am5xy.ColumnSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: `星${starGrade}つ`,
        xAxis: categoryAxis,
        yAxis: valueAxis,
        valueYField: valueY,
        categoryXField: "displayedMonth",
        maskContent: true,
        // 棒グラフの塗り色
        fill: am5.color(starColors[i]),
        stroke: am5.color("#f7ce93"),
        maskBullets: false,
        stacked: true,
        cursorOverStyle: "pointer",
      })
    );
    // 棒グラフクリックでデータを取得する為にtooltipを設定するがtooltip自体は表示しない
    const tooltip: am5.Tooltip = am5.Tooltip.new(root, {
      forceHidden: true,
    });
    series.columns.template.setAll({
      // 棒グラフの幅
      width: am5.percent(80),
      strokeWidth: 5,
      strokeOpacity: 0,
      tooltip,
      tooltipText: "{categoryX}",
    });
    series.data.setAll(data);
    // Legendにseriesを関連付ける
    legends.data.push(series);
  }

  // 平均クチコミ評点
  const lineSeries: am5xy.LineSeries = chart.series.push(
    am5xy.LineSeries.new(root, {
      name: "平均クチコミ評点（グラフ期間）",
      minBulletDistance: 10,
      xAxis: categoryAxis,
      yAxis: averageAxis,
      valueYField: "average",
      categoryXField: "displayedMonth",
      fill: am5.color(blue),
      stroke: am5.color(blue),
    })
  );
  lineSeries.strokes.template.setAll({
    strokeWidth: 1,
  });
  lineSeries.fills.template.setAll({
    visible: true,
  });

  // Legendにseriesを関連付ける
  legends.data.push(lineSeries);
  lineSeries.data.setAll(data);

  lineSeries.bullets.push(function () {
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 2,
        fill: lineSeries.get("fill"),
      }),
    });
  });
  return root;
}
</script>
<style lang="scss" scoped>
.review-container {
  margin: 10px;
  width: 100%;
  & > h3 {
    margin-left: 10px;
  }
}
</style>
