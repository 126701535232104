<template>
  <div class="p-2">
    <h3 class="text-h5 font-weight-bold mb-2">{{ title }}</h3>
    <div class="d-flex p-2 border-thin" style="background-color: white">
      <!-- 評価平均と件数 -->
      <div
        class="d-flex flex-column justify-center align-self-center"
        style="width: 160px; height: 160px"
      >
        <div class="text-h3 font-weight-bold align-self-center mb-2">
          {{ fraction2(rating, "----") }}
        </div>
        <div
          v-if="!loading && total === 0"
          class="align-self-center align-center text-caption text-red"
        >
          クチコミがありません
        </div>
        <v-rating
          class="align-self-center mb-1"
          :model-value="rating"
          :length="5"
          :size="22"
          readonly
          half-increments
          color="primary"
          density="compact"
        />
        <div class="align-self-center align-center text-body-1">
          {{ defaultNumber(total, "-") }} 件
        </div>
      </div>

      <!-- 評価ヒストグラム -->
      <table class="rating-table text-caption w-100">
        <tr v-for="i of [4, 3, 2, 1, 0]" :key="i">
          <td :style="{ color: starColors[i], fontSize: '16px' }">■</td>
          <td class="pl-1">星{{ i + 1 }}</td>
          <td class="pl-2 w-50">
            <v-progress-linear
              :model-value="stars[i] === 0 ? 0 : stars[i] / Math.max(...stars)"
              :max="1"
              :height="12"
              rounded
              color="primary"
            />
          </td>
          <td class="pl-2">{{ fraction2((stars[i] / total) * 100, "-") }} %</td>
          <td class="pl-3">({{ loading ? "-" : stars[i] }} 件)</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { starColors } from "@/components/shared/review-shared";

export default defineComponent({
  props: {
    title: { type: String, default: "" },
    loading: { type: Boolean, default: true },
    stars: {
      type: Array as () => number[],
      default: () => {
        return [0, 0, 0, 0, 0];
      },
    },
  },
  data() {
    return { starColors };
  },
  computed: {
    total() {
      if (this.loading) {
        return NaN;
      }
      return this.stars.reduce((acc, cur) => acc + cur, 0);
    },
    rating() {
      if (this.loading || this.total === 0) {
        return NaN;
      }
      const sum = this.stars.reduce((acc, cur, i) => acc + cur * (i + 1), 0);
      return sum / this.total;
    },
  },
  methods: {
    defaultNumber(value: number, defaultValue: string): string {
      if (isNaN(value)) {
        return defaultValue;
      }
      return Intl.NumberFormat("ja-JP").format(value);
    },
    fraction2(value: number, defaultValue: string): string {
      if (isNaN(value)) {
        return defaultValue;
      }
      return Intl.NumberFormat("ja-JP", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
  },
});
</script>

<style lang="scss" scoped>
.rating-table td {
  text-align: right;
  vertical-align: middle;
}
</style>
