import * as cg from "cheetah-grid";
import { currentTheme } from "@/components/shared/theme";
import type { Theme } from "cheetah-grid/themes/theme";
import type { MultilineTextStyleOption } from "cheetah-grid/columns/style";

export type CheetahGridIcon = {
    src: string;
    width: number;
};

export type CheetahGridColumnStyle = {
    autoWrapText: boolean;
    lineHeight: string;
    textAlign: string;
    textBaseline: string;
    visibility?: string;
};

// cheetah-gridのソート状態を記録するための定義
export type CustomSortState = {
    col: number;
    order: "asc" | "desc" | null;
};

/**
 * cheetah-gridのテーマ定義を生成する
 * @returns {Theme} データ更新時にグリッドの背景色を変更するテーマ
 */
export function createDataUpdateGridTheme(): Theme {
    return new cg.themes.theme.Theme(
        {
            // primaryカラー指定をする
            checkbox: {
                borderColor: currentTheme().vuetifyTheme.colors.primary,
                uncheckBgColor: "#fff",
                checkBgColor: currentTheme().vuetifyTheme.colors.primary,
            },
            defaultBgColor({ col, row, grid }: { col: number; row: number; grid: any }): string {
                if (col < grid.frozenColCount || row < grid.frozenRowCount) {
                    return "#f0f0f0";
                }
                const rec = grid.dataSource.source[row - 1];
                const hasError = "#ff8888";
                const isDirty = "#aff";
                if (rec?.isError) {
                    return hasError;
                } else if (rec?.dirtyFlag) {
                    return isDirty;
                }
            },
        },
        cg.themes.MATERIAL_DESIGN
    );
}

/**
 * cheetah-grid で情報更新するタイプの列スタイル定義
 */
export const dataUpdateColumnStyle: MultilineTextStyleOption = {
    autoWrapText: false,
    lineHeight: "1.3em",
    textAlign: "left",
    textBaseline: "middle",
    textOverflow: "ellipsis",
};
