<template>
  <div v-if="hasSelectedFiles" class="bg-white">
    <v-tabs v-model="selectedPlatform" slider-color="primary">
      <v-tab value="google"><v-icon color="google" icon="$google" /></v-tab>
      <v-tab value="yahoo"><v-icon color="yahoo" icon="$yahoo" size="28" /></v-tab>
      <v-tab value="instagram">
        <v-icon color="instagram" icon="$instagram" />
      </v-tab>
      <v-tab value="facebook">
        <v-icon color="facebook" icon="$facebook" />
      </v-tab>
      <v-tab value="hosting">
        <v-icon color="hosting" icon="$hosting" />
      </v-tab>
    </v-tabs>
    <v-window v-model="selectedPlatform">
      <v-window-item v-for="p in platforms" :key="p" :value="p">
        <draggable
          v-model="selectedFiles[p]"
          item-key="imageUrl"
          class="v-row my-1"
          :disabled="readonly"
          @end="updateItemOrder"
        >
          <template #item="{ element: file }">
            <v-col class="pa-1" style="min-width: 20%; max-width: 20%">
              <v-hover v-slot="{ isHovering, props }">
                <v-img
                  v-bind="props"
                  :src="file.imageUrl"
                  :lazy-src="file.imageUrl"
                  contain
                  aspect-ratio="1"
                  class="thumbnail"
                >
                  <!-- video以外はhoverすると編集ボタンが表示される -->
                  <span v-show="isHovering && !isVideo(file.file) && !readonly">
                    <v-btn
                      prepend-icon="mdi-settings"
                      class="edit-button"
                      depressed
                      rounded
                      @click="editImage(file.state)"
                    >
                      編集
                    </v-btn>
                  </span>
                  <!-- videoはアイコンを表示 -->
                  <v-scroll-y-transition>
                    <v-row
                      v-if="isVideo(file.file)"
                      class="fill-height fill-width disabled-background"
                    >
                      <v-icon color="white" size="60" icon="fas fa-video" />
                    </v-row>
                  </v-scroll-y-transition>
                </v-img>
              </v-hover>
            </v-col>
          </template>
        </draggable>
      </v-window-item>
    </v-window>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import draggable from "vuedraggable";
import type { SortableEvent } from "sortablejs";
import type {
  PlatformName,
  FileSelectionItem,
  FileSelectionState,
} from "@/models/v2-file-selection";
import { sortFileSelectionItemList } from "@/models/v2-file-selection";
import { platformList, isVideo } from "@/models/v2-file-selection";

export default defineComponent({
  components: { draggable },
  props: {
    googleFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    yahooFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    instagramFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    facebookFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    hostingFileSelection: { type: Array as () => FileSelectionItem[], required: true }, // 親コンポーネントに変更を伝える
    readonly: { type: Boolean, default: false },
  },
  emits: ["edit-image"],
  data() {
    return {
      platforms: platformList,
      selectedPlatform: "google" as PlatformName,
      isVideo: isVideo,
      // 各投稿先の選択＆ソート済みのファイルのリスト、タブ内表示に使う
      selectedGoogleFiles: [] as FileSelectionItem[],
      selectedYahooFiles: [] as FileSelectionItem[],
      selectedInstagramFiles: [] as FileSelectionItem[],
      selectedFacebookFiles: [] as FileSelectionItem[],
      selectedHostingFiles: [] as FileSelectionItem[],
      selectedFiles: {
        google: [],
        yahoo: [],
        instagram: [],
        facebook: [],
        hosting: [],
      } as {
        google: FileSelectionItem[];
        yahoo: FileSelectionItem[];
        instagram: FileSelectionItem[];
        facebook: FileSelectionItem[];
        hosting: FileSelectionItem[];
      },
    };
  },
  computed: {
    hasSelectedFiles(): boolean {
      return (
        this.selectedGoogleFiles.length +
          this.selectedYahooFiles.length +
          this.selectedInstagramFiles.length +
          this.selectedFacebookFiles.length +
          this.selectedHostingFiles.length >
        0
      );
    },
  },
  watch: {
    googleFileSelection: {
      deep: true,
      handler() {
        this.selectedGoogleFiles = sortFileSelectionItemList(this.googleFileSelection);
        this.selectedFiles["google"] = sortFileSelectionItemList(this.googleFileSelection);
      },
    },
    yahooFileSelection: {
      deep: true,
      handler() {
        this.selectedYahooFiles = sortFileSelectionItemList(this.yahooFileSelection);
        this.selectedFiles["yahoo"] = sortFileSelectionItemList(this.yahooFileSelection);
      },
    },
    instagramFileSelection: {
      deep: true,
      handler() {
        this.selectedInstagramFiles = sortFileSelectionItemList(this.instagramFileSelection);
        this.selectedFiles["instagram"] = sortFileSelectionItemList(this.instagramFileSelection);
      },
    },
    facebookFileSelection: {
      deep: true,
      handler() {
        this.selectedFacebookFiles = sortFileSelectionItemList(this.facebookFileSelection);
        this.selectedFiles["facebook"] = sortFileSelectionItemList(this.facebookFileSelection);
      },
    },
    hostingFileSelection: {
      deep: true,
      handler() {
        this.selectedHostingFiles = sortFileSelectionItemList(this.hostingFileSelection);
        this.selectedFiles["hosting"] = sortFileSelectionItemList(this.hostingFileSelection);
      },
    },
  },
  methods: {
    getSourceItems(): FileSelectionItem[] {
      switch (this.selectedPlatform) {
        case "google":
          return this.googleFileSelection;
        case "yahoo":
          return this.yahooFileSelection;
        case "instagram":
          return this.instagramFileSelection;
        case "facebook":
          return this.facebookFileSelection;
        case "hosting":
          return this.hostingFileSelection;
        default:
          return [];
      }
    },
    // 入れ替えた順番をセットする処理
    updateItemOrder(event: SortableEvent): void {
      // note: eventからは配列のindexしか取得できなかった
      const newList: FileSelectionItem[] = [];
      const oldList = this.getSourceItems();
      oldList.map((o) => {
        // ファイルの順序は保ちつつ、stateの数字を入れ替える(stateは1始まりなので+1する)
        if (o.state == event.oldIndex + 1) {
          newList.push({
            ...o,
            state: event.newIndex + 1,
          });
        } else if (o.state == event.newIndex + 1) {
          newList.push({
            ...o,
            state: event.oldIndex + 1,
          });
        } else {
          newList.push(o);
        }
      });
      oldList.splice(0, oldList.length);
      oldList.push(...newList);
    },

    // レイアウト編集画面を開く
    editImage(order: FileSelectionState): void {
      this.$emit("edit-image", { platform: this.selectedPlatform, order: order });
    },
  },
});
</script>

<style lang="scss" scoped>
// 一列に5個表示するための調整
.col-3 {
  max-width: 20%;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.thumbnail {
  background: #4a4a4a;
  cursor: grab;
}

.edit-button {
  background-color: rgba(0, 0, 0, 0.54) !important;
  color: white !important;
  border: none;
  position: absolute;
  right: 0;
  bottom: 0;
}

.disabled-background {
  background: #707070;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
